import React, { useEffect, useRef, useState } from "react";
import {
  closeIcon,
  head,
  logos,
  product,
  profilePic,
  tshirt1,
} from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useLazyEstimateDetailQuery } from "../redux/api/api";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ImageGallery from "react-image-gallery";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const EstimationPageA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [serachParams, setSearchParams] = useSearchParams();
  const [presentionlist, setPresentationList] = useState([]);
  const [billing, setBilling] = useState({});
  const [artwork, setArtwork] = useState([]);
  const [artworkdetails, setArtworkdetails] = useState([]);
  const [estimateViewApi] = useLazyEstimateDetailQuery();
  const [userPopup, setUserPopup] = useState(false);
  const [image, setImages] = useState([]);
  const [decorative, setDecorative] = useState([]);

  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  };

  const getView = () => {
    dispatch(saveLoader(true));
    estimateViewApi(
      location?.state?.data?.id || serachParams?.get("project_id")
    )
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          const { billing_address, project, shipping_address } = res;
          setBilling({
            ...billing_address,
            project,
            shipping_address: shipping_address,
          });

          let project_details_with_sizes = [];

          res?.project_details_with_sizes?.map((item, ind) => {
            project_details_with_sizes.push(item);
          });

          let projectPresentationOrder = [];

          res?.project?.projectPresentationOrder?.map((item, ind) => {
            let obj = {
              ...item,
            };

            let finder = project_details_with_sizes?.find(
              (i) => i?.detail?.id == item?.project_detail_id?.id
            );

            if (finder?.detail?.id) {
              obj.project_detail = finder;
            } else {
              obj.project_detail = null;
            }

            projectPresentationOrder.push(obj);
          });

          projectPresentationOrder.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });

          // console.log("projectPresentationOrder", projectPresentationOrder);

          setPresentationList(projectPresentationOrder);
          const projectDetails = res?.project_details_with_sizes || [];
          const projectdetail = res?.project?.projectDetail;
          const allArtworks = projectdetail?.map(
            (detail) => detail.artworks || []
          );
          const detailsArray = projectDetails.map((item) => item.detail);
          setArtwork(detailsArray);
          const art = detailsArray.map((item) => item.project_artworks);
          setArtworkdetails(art);
          const imagess = detailsArray.map((item) => item.product);
          setImages(imagess);
        }
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("Error fetching project details:", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id || serachParams?.get("project_id")) {
      getView();
    }
  }, []);
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={() => handleUserPopup()}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mb-5">
              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    <label className="w-100 position-relative">
                      <div className="rej-img">
                        <img src={profilePic} alt="No Image" />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Location
                    </p>
                    <input
                      type="text"
                      placeholder="Specify the placement of your artwork"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Dimensions
                    </p>
                    <input
                      type="text"
                      placeholder="W 2.3 X H 4.5"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                </div>
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
                  Additional Notes
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                />
              </div>
              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => handleUserPopup()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2  banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 ms-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                ESTIMATION #{billing?.project?.estimation_code} for{" "}
                {billing?.project?.project_request?.user?.first_name}
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project {billing?.project?.project_code}
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap  bg-primary2  w-100  mt-4  me-3 p-4"
          style={{
            // backgroundColor: "#ecfaff",
            borderRadius: "20px",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.shipping_address?.address1}
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                BILLING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.address1}
              </p>
            </div>
          </div>

          <div
            className="col-md-2 ps-4 mt-sm-2 p-2 left-line"
            style={{
              borderLeft: "1px",
              borderStyle: "solid",
              borderColor: "#cacaca",
              borderRight: "0",
              borderBottom: "0",
              borderTop: "0",
            }}
          >
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.project_code}
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TERMS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.terms_condition}
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              ESTIMATE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.estimation_code}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.customer_po}
            </p>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              IN HAND DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.in_hand_date}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CURRENCY
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              INR
            </p>
          </div>
        </div>
        {presentionlist?.map((item, index) => {
          // if (item?.status === 2 || item?.status === 1) {
          // if (item?.pricing_status === 1) {
          // console.log(billing?.project?.projectTags, "projectdetail");
          const size = item?.project_detail?.product_skus_with_matched_sizes;
          const match = size?.map((item) => item?.matched_sizes);
          const detail = item?.project_detail?.detail?.project_artworks;
          //  console.log(detail,"detail")
          //  const artworked=detail.map(item=>item.project_artworks)
          //  console.log(artworked,"artworked")
          // const detailsArray = item.map(item => item.detail);
          // console.log(detailsArray,"detailsArray")
          return (
            <div>
              {item?.title ? (
                <div className=" d-flex mt-3">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2 px-3 py-2 bg-primary2">
                    {item?.title}
                  </p>
                </div>
              ) : (
                <div
                  className="d-flex flex-wrap  bg-primary2  w-100  mt-4  me-3 p-4"
                  style={{
                    // backgroundColor: "#ecfaff",
                    borderRadius: "20px",
                    lineHeight: "35px",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="d-flex flex-wrap  align-items-start">
                    <div
                      className="col-md-6 mt-4 rounded bg-primary2"
                      style={{
                        // backgroundColor: "#ecfaff",
                        borderRadius: "32px",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {/* {item?.image_urls > 0 && ( */}

                      <ImageGallery
                        key={index}
                        items={(item?.project_detail?.image_urls || []).map(
                          (img) => ({
                            // items={item?.image_url.map((img) => ({
                            original: img,
                            thumbnail: img,
                          })
                        )}
                      />
                      {/* )} */}
                      {/* )})} */}
                    </div>
                    <div className="col-md-6 p-4">
                      <div className="w-100 ps-4 ">
                        <div style={{}}>
                          <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                            {item?.project_detail?.detail?.product?.name}
                          </h2>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            {item?.project_detail?.detail?.product?.description}
                          </p>
                        </div>
                        <div class=" tab mt-4 overflow-scroll">
                          <table className=" ">
                            <thead>
                              <tr>
                                <th className="f3 col-md-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                                  ITEM
                                </th>
                                <th className="f3 col-md-6  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap primary"></th>
                                <th>
                                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-5 font-blue2">
                                    QTY
                                  </p>
                                </th>
                                <th>
                                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-5">
                                    PRICE
                                  </p>
                                </th>
                                <th>
                                  <p className=" col-md-1 f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-5">
                                    AMOUNT
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ lineHeight: "30px" }}>
                              <tr>
                                <td className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                                  {/* {match.map((valArray, index) => {
  return valArray.map((val, innerIndex) => (
    <p key={innerIndex} className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
      Size: {val.value} 
    </p>
  ));
})} */}
                                  {
                                    item?.project_detail?.detail?.product_sku
                                      ?.variant_name
                                  }
                                </td>
                                <td>
                                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap "></p>
                                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                                </td>
                                <td>
                                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                    {item?.project_detail?.detail?.qty}
                                  </p>
                                </td>
                                <td>
                                  {item?.project_detail?.detail
                                    ?.extra_charges &&
                                  item?.project_detail?.detail?.extra_charges
                                    ?.length > 0 ? (
                                    (() => {
                                      const applicableCharges =
                                        item?.project_detail?.detail?.extra_charges?.filter(
                                          (charge) =>
                                            charge.client_price === "1"
                                        );
                                      const totalNetPrice =
                                        applicableCharges.reduce(
                                          (sum, charge) =>
                                            sum + Number(charge?.net_price),
                                          0
                                        );
                                      const retailPrice = Number(
                                        item?.project_detail?.detail
                                          ?.retail_price
                                      );
                                      const totalPrice =
                                        totalNetPrice + retailPrice;

                                      return (
                                        <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                          {totalPrice?.toFixed(2)}
                                        </p>
                                      );
                                    })()
                                  ) : (
                                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                      {
                                        item?.project_detail?.detail
                                          ?.retail_price
                                      }
                                    </p>
                                  )}
                                </td>

                                <td>
                                  {item?.project_detail?.detail
                                    ?.extra_charges &&
                                  item?.project_detail?.detail?.extra_charges
                                    ?.length > 0 ? (
                                    (() => {
                                      const applicableCharges =
                                        item?.project_detail?.detail?.extra_charges?.filter(
                                          (charge) =>
                                            charge?.client_price === "1"
                                        );
                                      const totalNetPrice =
                                        applicableCharges.reduce(
                                          (sum, charge) =>
                                            sum + Number(charge?.final_amount),
                                          0
                                        );
                                      const finalPrice = Number(
                                        item?.project_detail?.detail
                                          ?.final_amount
                                      );
                                      const totalPrice =
                                        totalNetPrice + finalPrice;

                                      return (
                                        <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                          {totalPrice.toFixed(2)}
                                        </p>
                                      );
                                    })()
                                  ) : (
                                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                      {
                                        item?.project_detail?.detail
                                          ?.final_amount
                                      }
                                    </p>
                                  )}
                                </td>
                              </tr>

                              {item?.project_detail?.detail?.extra_charges &&
                              item?.project_detail?.detail?.extra_charges
                                ?.length > 0 ? (
                                item?.project_detail?.detail?.extra_charges?.map(
                                  (charge, index) => {
                                    return (
                                      <tr>
                                        <td>
                                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap">
                                            {charge.client_price === "2"
                                              ? charge?.name
                                              : ""}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap"></p>
                                        </td>
                                        <td>
                                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center mx-3"></p>
                                        </td>
                                        <td>
                                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                            {charge.client_price === "2"
                                              ? charge.net_price
                                              : ""}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                            {charge.client_price === "2"
                                              ? charge.final_amount
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No extra charges available.
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-100 text-nowrap font-blue2">
                                  Total tax
                                </td>
                                <td colSpan="2">
                                  <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 text-nowrap text-center">
                                    {item?.project_detail?.detail?.tax_percent
                                      ? `${item?.project_detail?.detail?.tax_percent}%`
                                      : ""}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex flex-wrap ">
                          <div className="col-md-4">
                            <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                              Colors:
                            </p>
                            <div className="d-flex">
                              <div className="lt-Blue">
                                <FiberManualRecordIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="w-100">
                <textarea
                  className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  style={{ height: "100px" }}
                  placeholder="Comment on this item..."
                ></textarea>
              </div>
              <div className="d-flex ac-jb">
                <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                  Add Comment <PlaylistAddCheckIcon />
                </button>

              </div> */}
                      </div>
                    </div>
                    {detail?.map((item, index) => {
                      // console.log(item,"artworkdetails")
                      return (
                        <div className="col-md-5">
                          <div className="d-flex flex-wrap w-100 ac-jb mb-4">
                            {/* <div className="bg-primary2  rounded p-3 mt-3 w-50"> */}
                            {/* <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                  TYPE : 1
                </p> */}
                            {/* </div> */}

                            <div>
                              {/* {item && item.map((artwork, artworkIndex) => ( */}
                              <table
                                className="ms-3 mt-2 w-100"
                                style={{ lineHeight: "30px" }}
                              >
                                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                                  ARTWORK DETAILS
                                </p>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN NAME
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_name
                                        ? item?.logo_name
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      IMPRINT TYPE
                                    </p>
                                  </td>

                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.decorative_method?.name}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN LOCATION
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.uom === "1"
                                        ? "Inches"
                                        : item?.uom === "2"
                                        ? "Stitch"
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN SIZE
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_length}*{item?.logo_breadth}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN COLOR
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_color}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                              {/* ))} */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                Questions About this estimate?
              </p>
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <button
                class=" d-flex ac-jc ps-3 mt-sm-2"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4 d-flex ae-je mt-3">
              <div class="d-flex white mt-2  justify-content-around">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimationPageA;
