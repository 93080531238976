import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SideBarComp2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const splitLocation = pathname.split("/");
  // console.log("Split________", splitLocation);
  const [enableDown, setEnableDown] = useState(false);
  const [prodManagement, setProdManagement] = useState(false);
  const [customerDown, setCustomerDown] = useState(false);
  const [helpDeskDown, setHelpDeskDown] = useState(false);
  const [siteManagement, setSiteManagement] = useState(false);

  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);

  return (
    <div className="">
      <div className="">
        <div
          onClick={() => {
            navigate(`/overview${search}`);
          }}
          className={`${
            splitLocation[1] === "overview" ? "bg-soft-gray1" : ""
          } hover_eff cp d-flex w-100 flex-md-row flex-column py-3 px-3 `}
        >
          <p
            className={`wordsDes ${
              splitLocation[1] === "overview" ? "black" : "gray"
            } text-nowrap mt-2 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            OverView
          </p>
        </div>
        <div
          onClick={() => {
            navigate(`/collaborate${search}`);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "collaborate" ||
            splitLocation[1] === "add-profile" ||
            splitLocation[1] === "view-profile" ||
            splitLocation[1] === "edit-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "collaborate" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Collaborate
          </p>
        </div>
        <div
          onClick={() => navigate(`/presentation${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "presentation" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "presentation" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Presentation
          </p>
        </div>

        <div
          onClick={() => navigate(`/estimate${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "estimate" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "estimate" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Estimate
          </p>
        </div>
        <div
          onClick={() => navigate(`/prodection-artwork${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "prodection-artwork" ||
            splitLocation[1] === "upload-artwork-inprocess" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "prodection-artwork" ||
              splitLocation[1] === "upload-artwork-inprocess" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Production Artwork
          </p>
        </div>
        <div
          onClick={() => navigate(`/sales-order${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "sales-order" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "sales-order" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Sales Order
          </p>
        </div>
        <div
          onClick={() => navigate(`/shipping${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "shipping" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "shipping" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Shipping
          </p>
        </div>
        <div
          onClick={() => navigate(`/Po${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "Po" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "Po" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            POs
          </p>
        </div>
        <div
          onClick={() => navigate(`/invoice-page${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === " " ||
            splitLocation[1] === "invoice-page" ||
            splitLocation[1] === "invoice-page" ||
            splitLocation[1] === "invoice-page"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === " " ||
              splitLocation[1] === "invoice-page" ||
              splitLocation[1] === "invoice-page" ||
              splitLocation[1] === "invoice-page"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Invoice
          </p>
        </div>
        <div
          onClick={() => navigate(`/bills-page${search}`)}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "" ||
            splitLocation[1] === "bills-page" ||
            splitLocation[1] === "bills-page" ||
            splitLocation[1] === "bills-page"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === " " ||
              splitLocation[1] === "bills-page" ||
              splitLocation[1] === "bills-page" ||
              splitLocation[1] === "bills-page"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Bills
          </p>
        </div>
        <div
          // onClick={() => navigate("/sales-order")}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === " " ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === " " ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Commissions
          </p>
        </div>
        <div
          // onClick={() => navigate("/sales-order")}
          className={`hover_eff cp d-flex px-3   flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === " " ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === " " ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
          >
            Feedback
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideBarComp2;
