// @ts-nocheck
import React from "react";
import { USER_DETAILS } from "../api/constants";

export const saveUser = (userDetails) => {
  localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
};

export const getuser = () => {
  const userString = JSON.parse(localStorage.getItem(USER_DETAILS));
  return userString;
};
