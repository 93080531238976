import {
  Edit,
  ViewAgenda,
  ViewArraySharp,
  ViewList,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { product, viewIcon } from "../../assets/img";
import { collaborateList } from "../../redux/api/DummyJson";
import AddFilesPopup from "../Popup/AddFilesOverview";
import AddArtWorker from "../Projects/AddArtWorker";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { width } from "@mui/system";
import { useRef } from "react";
import SalesOrderFileView from "./Poppup/SalesOrderFileView";
import moment from "moment/moment";
import { useProjectTransactionProofMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { Modal, Row } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const SalesOrderTrancDetails = ({
  deleteToggle,
  setAddTitle,
  transactionList,
  getProjectTransactionList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addClient, setAddClient] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const fileRef = useRef();

  const [transactionProofApi] = useProjectTransactionProofMutation();

  // Image
  const [file, setFile] = useState("");
  const [viewfile, setViewFile] = useState(false);

  const fileOnChange = (e) => {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    console.log(file, "file");
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };
  const artWork = () => {
    toggleShowPopup();
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const fileViewToggle = (file_url) => {
    setFile(file_url);
    setViewFile(!viewfile);
  };

  const projectTransactionProof = (file, transaction_id) => {
    let formdata = new FormData();
    formdata.append("transaction_id", transaction_id);
    formdata.append("file", file);
    dispatch(saveLoader(true));
    transactionProofApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        getProjectTransactionList();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const toDataURL = async (url) => {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  };
  const downloadImage = async (url) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = `transaction_proof_${moment(new Date()).format(
      "DD-MM-YYYY"
    )}.png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  console.log("file", file, file?.includes(".pdf"));

  return (
    <div className="presentaion_pages overflow-scroll mt-4">
      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowPopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}

      <Modal
        show={viewfile}
        onHide={() => {
          fileViewToggle("");
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
            Transaction Proof
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100">
            {!file?.includes(".pdf") && (
              <div className="d-flex ac-je mb-3 ">
                <button
                  className="cust-btn"
                  onClick={() => downloadImage(file)}
                >
                  <FileDownloadIcon />
                </button>
              </div>
            )}
            <iframe
              src={file}
              style={{ height: "500px", width: "1200px" }}
            ></iframe>
          </Row>
        </Modal.Body>
      </Modal>

      {/* {viewfile && (
        <SalesOrderFileView  file={file} fileViewToggle={fileViewToggle} />
      )} */}
      <div className="w-100 overflow-scroll table_border menu-table">
        <table>
          <thead className="">
            <tr className="bg-soft-gray1">
              <th className="px-5 text-nowrap py-4">S.NO</th>
              <th className="px-5 text-nowrap py-4">Payment Type</th>
              <th className="px-5 text-nowrap py-4">Transaction ID</th>
              <th className="px-5 text-nowrap py-4">Transaction Date</th>
              <th className="px-5 text-nowrap py-4">Status</th>
              <th className="px-5 text-nowrap py-4">File</th>
              <th className="px-5 text-nowrap py-4">Amount</th>
            </tr>
          </thead>
          {transactionList?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td className="py-4">{ind + 1}</td>
                <td className="py-4">{item?.payment_type}</td>
                <td className="py-4">{item?.payment_id}</td>
                <td className="py-4">
                  {moment(item?.transaction_date).format("DD-MM-YYYY")}
                </td>
                <td
                  className={`py-4 ${
                    item?.status == 1
                      ? "text-primary"
                      : item?.status == 2
                      ? "text-success"
                      : item?.status == 3
                      ? "text-danger"
                      : "text-warning"
                  }`}
                >
                  {item?.status == 1
                    ? "Created"
                    : item?.status == 2
                    ? "Success"
                    : item?.status == 3
                    ? "Failed"
                    : "In Process"}
                </td>
                <td className="py-4 hover_eff2 flex-column d-flex ac-jc gap-2">
                  <input
                    onChange={(e) =>
                      projectTransactionProof(
                        e.target.files[0],
                        item?.transaction_id
                      )
                    }
                    className=" sm-btn w-60"
                    type="file"
                    accept=".pdf,image/*"
                    // ref={fileRef}
                  />
                  {item?.file && (
                    <div>
                      <button
                        className="sm-btn invisible"
                        onClick={() => fileViewToggle(item?.file)}
                      >
                        View File
                      </button>
                    </div>
                  )}
                </td>
                <td className="py-4">{item?.amount}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default SalesOrderTrancDetails;
