import React, { useEffect, useState, useRef } from "react";
import {
  closeIcon,
  profilePic,
  cloudUpload,
  noLogo,
  viewIcon,
} from "../assets/img";
import { head, logos, tshirt1, tshirt2, tshirt3 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Carousel from "react-bootstrap/Carousel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ErrorIcon } from "react-hot-toast";

// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { saveLoader } from "../redux/slice/loaderNoti";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  useLazyPresentationProViewQuery,
  useApproveandrejectMutation,
} from "../redux/api/api";

const PresentationGalleryPage3A = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location?.state?.user;
  const [imgitem, setimgitem] = useState([]);
  const [product, setProduct] = useState();
  const [productqty, setProductQty] = useState();
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState();
  const [fixedcharges, setFixedCharges] = useState();
  const [data, setData] = useState([]);
  const [userPopup, setUserPopup] = useState(false);
  const [address, setAddress] = useState("");
  const [dimension, setDimension] = useState("");
  const [user_comment, setUser_comment] = useState("");
  const [serachParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [closing_date, setclosing_date] = useState([]);
  const [users, setUser] = useState();

  const [projectdetailViewApi] = useLazyPresentationProViewQuery();
  const [approveApi] = useApproveandrejectMutation();
  const [productPrice, setproductPrice] = useState({});
  const [numbers, setNumbers] = useState([]);
  const proImgRef = useRef();
  const [includeLogo, setIncludeLogo] = useState(false);
  const [artworkInd, setArtworkInd] = useState(1);
  const [artwrk, setArtWork] = useState({});
  const [artwork, setArtwork] = useState("");
  const [notes, setNotes] = useState("");

  const imageSelector = (item) => {
    setUploadImage(item);
  };

  const [qty, setQty] = useState({
    25: 25,
    50: 50,
    100: 100,
    150: 150,
    250: 250,
    500: 500,
    1000: 1000,
  });

  const calculation = (value, for_price) => {
    let percen = (+value / 100) * +for_price;
    let sum = Number(+percen + +for_price).toFixed();
    return +sum;
  };
  const getView = () => {
    projectdetailViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status === "success" || res?.data?.status === "success") {
          setData(res);
          const productDetail = res?.project_detail;
          setUser(res);

          const requestimage = res?.project_request_images;

          setclosing_date(requestimage);

          if (Array.isArray(productDetail)) {
            setProduct(productDetail);
          } else if (productDetail) {
            setProduct([productDetail]);
          } else {
            setProduct([]);
          }

          // setDescription(res?.project_detail?.description);
          const projectPresentationPrice =
            res?.project_detail?.projectPresentationPrice;
          // setProductQty(res?.project_detail?.projectPresentationPrice);
          const productprice = res?.productPrice;
          let prices = {
            ...productprice,
            total: {
              for_25: +productprice?.for_25,
              for_50: +productprice?.for_50,
              for_100: +productprice?.for_100,
              for_150: +productprice?.for_150,
              for_250: +productprice?.for_250,
              for_500: +productprice?.for_500,
              for_1000: +productprice?.for_1000,
            },
          };
          let final_prices = {
            ...prices,
          };
          let projectPresentationPrices = [];
          projectPresentationPrice?.map((item, ind) => {
            if (item?.qty >= 1 && item?.qty <= 25) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 25,
              });
              final_prices["total"]["for_25"] = +item?.total;
            } else if (item?.qty >= 26 && item?.qty <= 50) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 50,
              });
              final_prices["total"]["for_50"] = +item?.total;
            } else if (item?.qty >= 51 && item?.qty <= 100) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 100,
              });
              final_prices["total"]["for_100"] = +item?.total;
            } else if (item?.qty >= 101 && item?.qty <= 150) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 150,
              });
              final_prices["total"]["for_150"] = +item?.total;
            } else if (item?.qty >= 151 && item?.qty <= 250) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 250,
              });
              final_prices["total"]["for_250"] = +item?.total;
            } else if (item?.qty >= 251 && item?.qty <= 500) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 500,
              });
              final_prices["total"]["for_500"] = +item?.total;
            } else if (item?.qty >= 501 && item?.qty <= 1000) {
              projectPresentationPrices.push({
                ...item,
                actual_qty: 1000,
              });
              final_prices["total"]["for_1000"] = +item?.total;
            }
          });
          let tempEnable = [];

          projectPresentationPrices?.map((item, ind) => {
            let arr = [25, 50, 100, 150, 250, 500, 1000];
            arr.forEach((ar, aind) => {
              if (item?.actual_qty == ar) {
                setQty((val) => ({ ...val, [ar]: item?.qty }));
                tempEnable.push(`for_${ar}`);
              }
            });
          });
          let default_price = {
            for_25: +productprice?.productDistributorPrice?.for_25,
            for_50: +productprice?.productDistributorPrice?.for_50,
            for_100: +productprice?.productDistributorPrice?.for_100,
            for_150: +productprice?.productDistributorPrice?.for_150,
            for_250: +productprice?.productDistributorPrice?.for_250,
            for_500: +productprice?.productDistributorPrice?.for_500,
            for_1000: +productprice?.productDistributorPrice?.for_1000,
          };
          let margin = projectPresentationPrices?.[0]?.margin;
          final_prices["for_25"] = calculation(margin, default_price?.for_25);
          final_prices["for_50"] = calculation(margin, default_price?.for_50);
          final_prices["for_100"] = calculation(margin, default_price?.for_100);
          final_prices["for_150"] = calculation(margin, default_price?.for_150);
          final_prices["for_250"] = calculation(margin, default_price?.for_250);
          final_prices["for_500"] = calculation(margin, default_price?.for_500);
          final_prices["for_1000"] = calculation(
            margin,
            default_price?.for_1000
          );
          final_prices["scp_percentage"] = margin;

          setproductPrice(final_prices);

          setNumbers(tempEnable);
          const projectPresentationimage = res?.project_detail?.projectArtwork;
          const imageGalleryItems = projectPresentationimage.map((artwork) => ({
            original: artwork?.image_url || "",
            thumbnail: artwork?.image_url || "",
          }));
          setImages(imageGalleryItems);
        }
      })
      .catch((err) => {
        console.error("Error fetching project details:", err);
      });
  };
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, [location?.state?.data?.id]);

  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  };
  const submitHandler = (status, projectId) => {
    let formdata = new FormData();
    formdata.append("project_detail_id", projectId);
    formdata.append("artwork_status", status);
    if (artwork) {
      formdata.append("project_artwork_id", artwork);
    }
    if (notes) {
      formdata.append("user_comment", notes);
    }
    if (uploadImage?.name) {
      formdata.append("user_logo", uploadImage);
    }
    dispatch(saveLoader(true));
    approveApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        // setBtns(false);
        setAddress("");
        setDimension("");
        setUser_comment("");
        getView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
    // }
  };
  const fileChange = (e) => {
    setUploadImage(e.target.files[0]);
  };

  useEffect(() => {
    if (selectedItem) {
    }
  }, [selectedItem]);
  useEffect(() => {
    if (selectedItem?.projectArtwork?.length > 0) {
      const firstArtwork = selectedItem.projectArtwork[0];
      setArtwork(firstArtwork?.id);
      setArtWork(firstArtwork);
      setUploadImage(firstArtwork);
      setArtworkInd(1);
    }
  }, [selectedItem]);

  const artworkSelector = (ids) => {
    const artwork = selectedItem?.projectArtwork;
    const finder = artwork?.find((item) => item.id === ids);

    if (finder) {
      setArtwork(ids);
      setArtWork(finder);
      setUploadImage(finder);

      const findIndex = artwork.findIndex((item) => item.id === ids);
      setArtworkInd(findIndex + 1);
    }
  };
  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={() => handleUserPopup()}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mt-4">
              <h6 className="mb-2 f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
                Artwork {artworkInd}
              </h6>

              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    <p className="parag mb-2">
                      Artwork Allmade unisex Organic cotton Tee
                    </p>
                    <label className="w-100 position-relative">
                      <input
                        ref={proImgRef}
                        className="d-none"
                        type="file"
                        onChange={(e) => setUploadImage(e.target.files[0])}
                      />
                      <div>
                        {uploadImage ? (
                          <div
                            className="prev-img"
                            onClick={() => proImgRef?.current?.click()}
                            role="button"
                          >
                            <img
                              src={
                                uploadImage?.name
                                  ? URL.createObjectURL(uploadImage)
                                  : uploadImage?.image_url
                              }
                              alt="Uploaded preview"
                            />
                          </div>
                        ) : (
                          <div
                            className="log-upload-box position-relative d-flex ac-jc mt-3 mb-3"
                            onClick={() => proImgRef?.current?.click()}
                            role="button"
                          >
                            <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                              Primary Logo
                            </p>
                            <img src={cloudUpload} alt="Upload" />
                            <p className="text-dark text-center fs-13 fs-md-14">
                              Drag and drop to upload or Click <br />
                              here to upload
                            </p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <div className="d-flex ac-jc gap-3 w-100">
                    <div
                      className="d-flex ac  mt-4 mob-carous-pop"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      {selectedItem?.projectArtwork?.map((item, ind) => {
                        return (
                          <div
                            className="down_imgs m-1"
                            key={ind}
                            role={"button"}
                            onClick={() => artworkSelector(item?.id)}
                            style={
                              artwrk?.id == item?.id
                                ? { border: "1px solid black" }
                                : { border: "none" }
                            }
                          >
                            <img
                              src={item.image_url ? item.image_url : noLogo}
                              style={{
                                padding: "10px",
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                                borderRadius: "15px",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* {selectedArtworkId?.map((item, ind) => (
                 */}
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f3 primary1 fs-16 fs-md-18 fs-lg-19 mb-0 mt-3">
                      Artworks
                    </p>
                    <select
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      onChange={(e) => artworkSelector(e.target.value)}
                      value={artwork}
                    >
                      {selectedItem?.projectArtwork?.length > 0 ? (
                        selectedItem?.projectArtwork?.map((item, ind) => {
                          return (
                            <option
                              style={{ textAlign: "center" }}
                              value={item?.id}
                            >{`Artwork ${ind + 1}`}</option>
                          );
                        })
                      ) : (
                        <option style={{ textAlign: "center" }} value={""}>
                          Select Artwork
                        </option>
                      )}
                    </select>
                  </div>

                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Decorative Method
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={`${
                        artwrk?.logo_length ? artwrk?.logo_length : ""
                      }*${artwrk?.logo_breadth ? artwrk?.logo_breadth : "-"}`}
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Location Name
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={artwrk?.logo_name ? artwrk?.logo_name : "-"}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3 mt-lg-4 pop-input">
                <div>
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Dimensions
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    placeholder="L*B"
                    value={`${artwrk?.logo_length ? artwrk?.logo_length : ""}*${
                      artwrk?.logo_breadth ? artwrk?.logo_breadth : "-"
                    }`}
                  />
                </div>
                <div>
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Logo Colour
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    value={artwrk?.logo_color ? artwrk?.logo_color : "-"}
                    readOnly
                  />
                </div>
              </div>

              <div className="ac-jb mt-2 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Why am I Rejecting?
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} id="button-tooltip">
                        <p className="text-capitalize text-start f4 fs-15">
                          I would like to change the logo. I want the logo to be
                          centered. Can you show me a different option?
                        </p>
                      </Tooltip>
                    )}
                  >
                    <span className="d-flex align-items-center">
                      <img
                        src={viewIcon}
                        className="viewDes"
                        alt="View Details"
                      />
                      <ErrorIcon />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 mt-1"
                />
              </div>

              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => {
                  handleUserPopup();
                  submitHandler(4, selectedItem?.id);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {product?.map((item, index) => {
        const presentation = item?.projectPresentationPrice;
        const products = item?.productPrice;
        const qtyList = presentation.map((p) => p.qty);
        const rsList = presentation.map((p) => p.total);
        const fixedCharges = {};
        const detail = item?.projectArtwork;
        presentation.forEach((presentationPrice) => {
          if (Array.isArray(presentationPrice?.run_charge)) {
            presentationPrice.run_charge.forEach((charge) => {
              if (!fixedCharges[charge.name]) {
                fixedCharges[charge.name] = [];
              }
              fixedCharges[charge.name].push(charge.value);
            });
          }
        });

        const fixedChargesArray = Object.entries(fixedCharges);
        const totalSums = fixedChargesArray.map(([chargeName, values]) => {
          const rsList = presentation.map((p) => p.total);

          const maxLength = Math.max(values.length, rsList.length);

          const indexSums = [];

          for (let i = 0; i < maxLength; i++) {
            const value = Number(values[i]) || 0;
            const qty = Number(rsList[i]) || 0;
            const sum = value + qty;
            indexSums.push(sum);
          }

          return {
            chargeName,
            indexSums,
          };
        });

        return (
          <div className=" " style={{ width: "90%", margin: "auto" }}>
            <div className="d-flex flex-wrap">
              <div
                className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
                style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
              >
                <div className="">
                  <img
                    alt="img"
                    src={logos}
                    className=" ps-3"
                    style={{ width: "150px", objectFit: "cover" }}
                  />
                </div>

                <div className="px-2 font-blue2">
                  <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                    merchHQ Pvt.Ltd.
                  </h2>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                    PRESENTATION #{users?.project?.presentation_code} for{" "}
                    {users?.project?.project_request?.user?.first_name}
                  </p>
                  <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Project {users?.project?.project_code}
                  </p>
                </div>
              </div>
              <div className="col-md-7 mt-3">
                <img alt="img" src={head} className="ps-lg-3 w-100" />
              </div>
            </div>
            {product?.map((item, index) => {
              const presentation = item?.projectPresentationPrice;
              const qtyList = presentation.map((p) => p.qty);
              const rsList = presentation.map((p) => p.total);
              const fixedCharges = {};
              const detail = item?.projectArtwork;
              presentation.forEach((presentationPrice) => {
                if (Array.isArray(presentationPrice?.run_charge)) {
                  presentationPrice.run_charge.forEach((charge) => {
                    if (!fixedCharges[charge.name]) {
                      fixedCharges[charge.name] = [];
                    }
                    fixedCharges[charge.name].push(charge.value);
                  });
                }
              });

              const fixedChargesArray = Object.entries(fixedCharges);
              const totalSums = fixedChargesArray.map(
                ([chargeName, values]) => {
                  const rsList = presentation.map((p) => p.total);

                  const maxLength = Math.max(values.length, rsList.length);

                  const indexSums = [];

                  for (let i = 0; i < maxLength; i++) {
                    const value = Number(values[i]) || 0;
                    const qty = Number(rsList[i]) || 0;
                    const sum = value + qty;
                    indexSums.push(sum);
                  }

                  return {
                    chargeName,
                    indexSums,
                  };
                }
              );

              return (
                <div className="d-flex flex-wrap mt-4" key={index}>
                  <div className="col-md-6 mt-4 rounded">
                    {images?.length > 0 && (
                      <ImageGallery
                        items={images.map((image) => ({
                          original: image.original,
                          thumbnail: image.thumbnail,
                          logo: includeLogo ? image.logo : undefined,
                        }))}
                        style={{ width: "300px", height: "300px" }}
                      />
                    )}
                  </div>

                  <div className="p-3 col-md-6 my-3">
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary  mt-3">
                      {item?.product?.name}{" "}
                    </h2>
                    <p className="f3 fs-xs-9 fs-sm-10 fs-md-12 fs-lg-12 fs-xl-12 fs-xxl-13">
                      {item?.product?.description}
                    </p>
                    {/* <div className="my-3">
                        <button className="border-0 bg-gray p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                          Product Description
                        </button>
                      </div> */}
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 my-4 ">
                      {/* Price label:  Rs.650.00 */}
                    </p>
                    {/* {users?.project?.pricing_status === 1 && ( */}

                    <div
                      className="my-3 bg-lt-blue3 p-3"
                      style={{ lineHeight: "40px" }}
                    >
                      {users?.project?.pricing_status === 1 && (
                        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                          Pricing
                        </p>
                      )}
                      <table className="w-100 overflow-scroll">
                        <tbody>
                          {users?.project?.pricing_status === 1 && (
                            <tr className="text-center">
                              <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                QTY
                              </td>
                              {qtyList?.map((qty, index) => (
                                <td
                                  key={index}
                                  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                >
                                  {qty}
                                </td>
                              ))}
                            </tr>
                          )}
                          {users?.project?.pricing_status === 1 && (
                            <tr className="text-center">
                              <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                RS
                              </td>
                              {qtyList?.map((qty, index) => (
                                <td
                                  key={index}
                                  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                >
                                  {productPrice?.[`for_${qty}`]}
                                </td>
                              ))}
                            </tr>
                          )}
                          {users?.project?.pricing_status === 1 && (
                            <tr>
                              <td
                                colSpan={presentation?.length + 1}
                                className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                                style={{ textAlign: "start" }}
                              >
                                Fixed Charges
                              </td>
                            </tr>
                          )}
                          {users?.project?.pricing_status === 1 && (
                            <>
                              {fixedChargesArray?.map(
                                ([name, values], index) => (
                                  <tr key={index} className="text-center">
                                    <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      {name}
                                    </td>
                                    {values.map((value, valueIndex) => (
                                      <td
                                        key={valueIndex}
                                        className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                      >
                                        {value}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              )}
                            </>
                          )}{" "}
                          {users?.project?.pricing_status === 1 && (
                            <tr>
                              <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-nowrap font-blue2">
                                Total Price
                              </td>
                              {qtyList?.map((qty, index) => (
                                <td
                                  key={index}
                                  className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                >
                                  {productPrice?.total?.[`for_${qty}`]}
                                </td>
                              ))}
                            </tr>
                          )}
                          {/* <td className="f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap text-center font-blue2">
                          Total Price
                        </td>
                        {fixedChargesArray?.map(([name, values], index) => (
                        <tr key={index} className="text-center">
                          <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            {name}
                          </td>
                          {values.map((value, valueIndex) => (
                            <td
                              key={valueIndex}
                              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            >
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))} */}
                          <div>
                            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                              Colors:
                            </p>
                            <div className="d-flex">
                              <div className="primary">
                                <FiberManualRecordIcon
                                  style={{
                                    color:
                                      item?.product_sku_id?.color?.hex_code ||
                                      item?.product_sku?.color?.hex_code,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </tbody>
                      </table>
                    </div>
                    {/* )} */}

                    {/* <div className="lt-Blue">
                              <FiberManualRecordIcon />
                            </div>
                            <div className="gray">
                              <FiberManualRecordIcon />
                            </div>
                            <div className="gray1">
                              <FiberManualRecordIcon />
                            </div> */}
                    {/* <div className="">
                  <button
                    onClick={() => {
                      navigate("/presentation-gallery-comment-a", {
                        state: { data: data, user: user },
                      });
                    }}
                    className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4"
                  >
                    Comments{" "}
                    <span className="bg-blue1 rounded px-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      12
                    </span>
                  </button>

                 
                </div> */}
                  </div>
                </div>
              );
            })}
            <div className="col-md-5 d-flex flex-wrap w-100 ac gap-3 mb-4">
              {detail.map((artwork, index) => {
                return (
                  <div className="">
                    <table
                      className="ms-3 mt-2 w-100"
                      style={{ lineHeight: "30px" }}
                    >
                      <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                        ARTWORK DETAILS
                      </p>
                      <tr>
                        <td>
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            DESIGN NAME
                          </p>
                        </td>
                        <td>
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                            {artwork.logo_name ? artwork.logo_name : "N/A"}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            IMPRINT TYPE
                          </p>
                        </td>
                        <td>
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                            {artwork?.decorative_method_id?.name}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            DESIGN LOCATION
                          </p>
                        </td>
                        <td>
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                            {artwork?.uom === "1"
                              ? "Inches"
                              : artwork?.uom === "2"
                              ? "Stitch"
                              : "N/A"}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            DESIGN SIZE
                          </p>
                        </td>
                        <td>
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                            {artwork?.logo_length}*{artwork?.logo_breadth}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            DESIGN COLOR
                          </p>
                        </td>
                        <td>
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                            {artwork?.logo_color}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-end">
              <div>
                {item?.artwork_status !== 3 && item?.artwork_status !== 4 ? (
                  <button
                    style={{
                      backgroundColor: "#f82a2a",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                    }}
                    onClick={() => {
                      handleUserPopup();
                      setSelectedItem(item);
                    }}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      Request Change
                    </p>
                  </button>
                ) : (
                  <button
                    disabled
                    style={{
                      backgroundColor: "#f82a2a",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                      opacity: 0.5,
                    }}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      Request Change
                    </p>
                  </button>
                )}
              </div>
              <div>
                {item?.artwork_status !== 3 && item?.artwork_status !== 4 ? (
                  <button
                    style={{
                      backgroundColor: "#0082ca",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setSelectedItem(item);
                      submitHandler(3, item?.id);
                      // navigate("/poproduct-request-a", { state: { item } });
                    }}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      Approve Design
                    </p>
                  </button>
                ) : (
                  <button
                    disabled
                    style={{
                      backgroundColor: "#0082ca",
                      borderRadius: "10px",
                      padding: "10px",
                      border: "none",
                      marginLeft: "5px",
                      opacity: 0.5,
                    }}
                  >
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                      Approve Design
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <button
        class=" d-flex ac-jc px-3 mt-2 ms-4 py-2"
        style={{
          backgroundColor: "#0082ca",
          border: "none",
          borderRadius: "7px",
          padding: "5px",
          color: "#fff",
        }}
      >
        <UnsubscribeIcon />
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-2 py-2">
          Contact us
        </p>
      </button>
    </div>
  );
};

export default PresentationGalleryPage3A;
