import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PaymentDetails from "./Poppup/PaymentDetails";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const SalesOrderHeadComp = ({
  setAddContComp,
  poppupAddressToggle,
  showAddress,
  collaboratClick,
  onCollaborat,
  PaymenDetTogg,
  paymentDetail,
  setPaymentDeta,
  setBoxRadioBtn,
  boxRadioBtn,
  type,
  setCheckBox,
  checkBox,
  poppupPoToggle,
  poppupViewToggle,
  poppupEmailToggle,
  poppupEmailViewToggle,
  handleProductInformation,
  productInformationErr,
  productInformation,
  dynamicAttr,
  listBillingAddress,
  setListBillingAddress,
  AddressBillingList,
  listShippingAddress,
  setListShippingAddress,
  AddressShippingList,
  searchProductInfoFun,
  searchProInfo,
  allSelectProductInfo,
  listAttributes,
  onDynamicAttributeSelect,
  otherProInfo,
  otherProInfoErr,
  setIntroduction,
  introduction,
  introductionErr,
  setTerms_and_conditions,
  terms_and_conditions,
  terms_and_conditionsErr,
  setin_hand_date,
  in_hand_date,
  in_hand_dateErr,
  setclosing_date,
  closing_dateErr,
  closing_date,
  setestimate_date,
  estimate_date,
  estimate_dateErr,
  setDefaultTax,
  defaultTax,
  defaultTaxs,
  defaultTaxErr,
  setcustomer_po,
  customer_po,
  setBillingAddress,
  billingAddress,
  mainShipAddress,
  setMainShipAddress,
  paymentStatus,
  setPaymentStatus,
  setGSTType,
  gstType,
  gstTypeErr,
  setDiscountType,
  discountType,
  discountTypeErr,
}) => {
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(undefined);

  const [status, setStatus] = useState("");

  const option = ["Client Paid", "Payment Pending"];

  // input states

  const [billingAddress2, setBillingAddress2] = useState("");
  const [mainShipAddress2, setMainShipAddress2] = useState("");
  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [otherText, setOtherText] = useState([]);

  const onOptionChangeHandler = (event) => {
    setPaymentDeta(true);
    setStatus(event.target.value);
    setPaymentStatus(event.target.value);
    console.log("User Selected Value - ", event.target.value);
  };

  const addOtherInfo = (text) => {
    if (+text !== 0) {
      let temp = [...otherText];
      temp.push(text);
      setOtherText(temp);
    }
  };

  const [searchBilling, setSearchBilling] = useState("");
  const [searchShipping, setSearchShipping] = useState("");
  const searchBillingFun = (event) => {
    let temp = [...AddressBillingList];
    let search = temp.filter((value) =>
      value?.address1?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListBillingAddress(search);
    } else {
      setListBillingAddress(AddressBillingList);
    }

    setSearchBilling(event);
  };

  const searchShippingFun = (event) => {
    let temp = [...AddressShippingList];
    let search = temp.filter((value) =>
      value?.address1?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListShippingAddress(search);
    } else {
      setListShippingAddress(AddressShippingList);
    }

    setSearchShipping(event);
  };

  const taxTypeHandler = (event) => {
    dispatch(saveAlertMessage("one time only change this type"));
    setDefaultTax(event);
  };

  return (
    <div className="w-100">
      <div className="w-100 d-flex ac-je">
        <button
          onClick={onCollaborat}
          className="d-flex me-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
        >
          - {collaboratClick ? "Collapse Info" : "Expand Info"}
        </button>
      </div>

      {status === "Client Paid" && paymentDetail && (
        <PaymentDetails
          PaymenDetTogg={PaymenDetTogg}
          setBoxRadioBtn={setBoxRadioBtn}
          boxRadioBtn={boxRadioBtn}
        />
      )}
      <div className="d-flex flex-column flex-md-row flex-wrap">
        <Col sm={12} md={12} lg={3} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Payment Status
          </p>

          <select
            value={paymentStatus}
            onChange={onOptionChangeHandler}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              select option
            </option>
            {option.map((option, index) => {
              return <option key={index}>{option}</option>;
            })}
          </select>
        </Col>
        <Col sm={12} md={12} lg={3} className="">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            {type == "invoice" ? "Invoice" : "Sales"} Order Date
          </p>

          <input
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            type="date"
            onChange={(e) => setestimate_date(e.target.value)}
            value={estimate_date}
          />
          {estimate_date?.length == 0 && estimate_dateErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Estimate Date</p>
            </div>
          )}
        </Col>
      </div>
      {collaboratClick && (
        <>
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              {" "}
              Introduction
            </p>
            <textarea
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setIntroduction(e.target.value)}
              value={introduction}
              readOnly
            />
            {introduction?.length == 0 && introductionErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Introduction</p>
              </div>
            )}
          </div>
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            {" "}
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Terms and conditions
            </p>
            <textarea
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder=""
              rows={2}
              onChange={(e) => setTerms_and_conditions(e.target.value)}
              value={terms_and_conditions}
              readOnly
            />
            {terms_and_conditions?.length == 0 && terms_and_conditionsErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Terms and Conditions</p>
              </div>
            )}
          </div>
        </>
      )}
      {collaboratClick && (
        <>
          <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
              style={{ marginLeft: "0px" }}
            >
              Other Information
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={
                  dynamicAttr?.length > 0
                    ? dynamicAttr
                    : "Select Other Information"
                }
                onClick={(e) => setDropDown(77)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(77);
                }}
                disabled
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {dropDown == 77 && (
              <div
                className="invisible-cont2"
                onClick={() => setDropDown(null)}
              />
            )}
            <div
              className={`${
                dropDown == 77 && "submenu_1 "
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchProductInfoFun(e.target.value)}
                  value={searchProInfo}
                />
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    allSelectProductInfo();
                  }}
                >
                  All
                </button>
              </div>
              {listAttributes?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                        setDropDown(null);
                      }}
                    >
                      {item?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                      }}
                    >
                      {dynamicAttr?.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}

                      {dynamicAttr?.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {otherProInfo?.length == 0 && otherProInfoErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Select Other Product Information
                </p>
              </div>
            )}
          </div>
          <div className="d-flex gap-3 flex-wrap">
            {productInformation?.map((item, ind) => {
              return (
                <Col lg={5} md={5} sm={5} xs={12} key={ind}>
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    {item?.title}
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) =>
                      handleProductInformation(e.target.value, ind)
                    }
                    value={item?.value}
                    readOnly
                  />

                  {item?.value?.length == 0 && productInformationErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">fill Information</p>
                    </div>
                  )}
                </Col>
              );
            })}
          </div>
        </>
      )}
      {collaboratClick && (
        <div className="w-100 d-flex as-jb gap-3 flex-column flex-md-row">
          <div className="d-flex as-jb gap-3 w-100 w-md-50 res-flex">
            {/* <div className=''> */}
            <div className="w-100">
              <div className="w-100 drop-dow position-relative ">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Billing Address
                </p>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={billingAddress?.address1}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(1)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>

                  <input
                    onClick={() => showAddress(2)}
                    type="button"
                    value={"+"}
                    disabled={listBillingAddress?.length <= 0 ? false : true}
                    placeholder="Required Field"
                    className={`editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0
                     ${listBillingAddress?.length > 0 ? "opacity-50" : ""} `}
                  />

                  {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                </div>
                {dropDown === 1 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 1 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchBillingFun(e.target.value)}
                      value={searchBilling}
                    />
                  </div>
                  {listBillingAddress?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setBillingAddress(item);
                            setDropDown(undefined);
                            setSearchBilling("");
                          }}
                        >
                          {item?.address1}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              {billingAddress?.address1 && (
                <>
                  {" "}
                  <div className="w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.address1}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.address2}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.landmark}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.city}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.state}{" "}
                      {billingAddress?.country
                        ? " , " + billingAddress?.country
                        : ""}
                    </p>
                  </div>
                  <div className="w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {billingAddress?.email_id}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      W: {billingAddress?.phone_no}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="w-100">
              <div className="w-100 drop-dow position-relative ">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Main Shipping Address
                </p>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={mainShipAddress?.address1}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(2)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  <input
                    onClick={() => showAddress(1)}
                    type="button"
                    value={"+"}
                    placeholder="Required Field"
                    className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                  />
                </div>
                {dropDown === 2 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchShippingFun(e.target.value)}
                      value={searchShipping}
                    />
                  </div>
                  {listShippingAddress?.map((item, ind) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setMainShipAddress(item);
                            setDropDown(undefined);
                            setSearchShipping("");
                          }}
                        >
                          {item?.address1}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              {mainShipAddress?.address1 && (
                <>
                  {" "}
                  <div className="w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.address1}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.address2}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.landmark}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.city}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.state}{" "}
                      {mainShipAddress?.country
                        ? " , " + mainShipAddress?.country
                        : ""}
                    </p>
                  </div>
                  <div className="w-100 drop-dow mt-3">
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {mainShipAddress?.email_id}
                    </p>
                    <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      W: {mainShipAddress?.phone_no}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex as-jb gap-3 flex-column flex-md-row w-100 w-md-50 res-flex">
            <div className="w-100">
              <div className="d-flex flex-wrap ac-jb flex-m-r">
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Terms
                  </p>
                  <div className="position-relative">
                    <input
                      value={terms}
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      onClick={() => setDropDown(5)}
                      className="drop_down cust-btn"
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {dropDown === 5 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 5 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setTerms(item?.list);
                              setDropDown(undefined);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Currency
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={currency}
                      placeholder="Required Field"
                      disabled
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => setDropDown(6)}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {dropDown === 6 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 6 && "submenu_1 h-auto"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    {currency_list?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setCurrency(item?.list);
                              setDropDown(undefined);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    In Hands Date
                  </p>
                  <div className="position-relative">
                    <input
                      type="date"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setin_hand_date(e.target.value)}
                      value={in_hand_date}
                      readOnly
                    />
                    {in_hand_date?.length == 0 && in_hand_dateErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Select In Hands Date
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Expiry Date
                  </p>
                  <div className="position-relative">
                    <input
                      type="date"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setclosing_date(e.target.value)}
                      value={closing_date}
                      readOnly
                    />
                    {closing_date?.length == 0 && closing_dateErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Expiry Date</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Default Margin
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Tax Type
                  </p>
                  <select
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => taxTypeHandler(e.target.value)}
                    value={defaultTax}
                    disabled
                  >
                    <option hidden>Select Tax Type</option>
                    <option value={2}>Highest tax</option>
                    <option value={1}>Individual tax</option>
                  </select>
                  {defaultTax?.length == 0 && defaultTaxErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Tax Type</p>
                    </div>
                  )}
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                    GST Type
                  </p>
                  <div className="mt-2 d-flex gap-3">
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={gstType == 1 ? true : false}
                        onClick={() => setGSTType(1)}
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Intercity
                      </span>
                    </div>
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={gstType == 2 ? true : false}
                        onClick={() => setGSTType(2)}
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Intracity
                      </span>
                    </div>
                  </div>
                  {gstType == 0 && gstTypeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select GST Type</p>
                    </div>
                  )}
                </div>

                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Customer PO
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setcustomer_po(e.target.value)}
                      value={customer_po}
                      readOnly
                    />
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                    Discount Type
                  </p>
                  <div className="mt-2 d-flex gap-3">
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={discountType == 2 ? true : false}
                        // onClick={() => setDiscountType(2)}
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Overall
                      </span>
                    </div>
                    <div className="d-flex">
                      <input
                        type={"radio"}
                        className="mx-1"
                        checked={discountType == 1 ? true : false}
                        // onClick={() => setDiscountType(1)}
                      />
                      <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                        Individual
                      </span>
                    </div>
                  </div>
                  {discountType == 0 && discountTypeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Discount Type</p>
                    </div>
                  )}
                </div>

                {/* <div className="d-flex ae-je w-100 py-3">
                                <div className="d-flex gap-2">
                                    <input type="checkbox" className='cp' />
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">This is a sample order</p>
                                </div>
                            </div> */}

                {type == "invoice" && (
                  <div className="d-flex ae-je w-100 py-3">
                    <div>
                      <p
                        className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                        style={{ marginLeft: "0px" }}
                      >
                        Invoice View
                      </p>
                      <div className="d-flex gap-3 mt-1">
                        <div className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="cp"
                            checked={checkBox == 0}
                            onClick={() => setCheckBox(0)}
                          />
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Detail
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="cp"
                            checked={checkBox == 1}
                            onClick={() => setCheckBox(1)}
                          />
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Summary
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {boxRadioBtn && (
                <div className="w-xl-100 mt-4 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Payment Type
                  </p>
                  <div
                    onClick={() => setBoxRadioBtn(1)}
                    className={`${
                      boxRadioBtn === 1 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-jb cust-btn w-100">
                      <div className="d-flex ac-jc gap-3">
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              boxRadioBtn === 1 ? "sm_radio" : "sm_radio1"
                            } `}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          PO
                        </p>
                      </div>
                      {boxRadioBtn == 1 && (
                        <input
                          type="text"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                    </button>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => poppupPoToggle()}
                      className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      Upload <FileUploadIcon />
                    </button>
                    <button
                      onClick={() => poppupViewToggle(true)}
                      className="primary editBtn  rounded-3 mt-sm-3 ms-3 mt-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      view <VisibilityIcon />
                    </button>
                  </div>
                  <div
                    onClick={() => setBoxRadioBtn(2)}
                    className={`${
                      boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-jb cust-btn w-100">
                      <div className="d-flex ac-jc gap-3">
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              boxRadioBtn === 2 ? "sm_radio" : "sm_radio1"
                            } `}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Email
                        </p>
                      </div>
                      {boxRadioBtn == 2 && (
                        <input
                          type="date"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                      {boxRadioBtn == 1 && (
                        <input
                          type="date"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                    </button>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => poppupEmailToggle()}
                      className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      Upload <FileUploadIcon />
                    </button>
                    <button
                      onClick={() => poppupEmailViewToggle(true)}
                      className="primary editBtn  rounded-3 mt-sm-3 ms-3 mt-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      view <VisibilityIcon />
                    </button>
                  </div>
                  <div
                    onClick={() => setBoxRadioBtn(3)}
                    className={`${
                      boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-js cust-btn">
                      <div className="radio_btn d-flex ac-jc">
                        <div
                          className={`${
                            boxRadioBtn === 3 ? "sm_radio" : "sm_radio1"
                          }`}
                        />
                      </div>
                      <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        Payment gateway
                      </p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default SalesOrderHeadComp;
