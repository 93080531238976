import React, { useEffect, useState } from "react";
import { closeIcon, noLogo, profilePic } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ProductsViewPopup = ({ handleUserPopup, userCommend, projectData }) => {
  const [artworkInd, setArtworkInd] = useState(1);
  useEffect(() => {
    let findIndex = userCommend?.artworks?.findIndex(
      (item) => item.id == userCommend?.reject_artwork_details?.id
    );
    setArtworkInd(findIndex + 1);
  }, []);
  return (
    <>
      <div className="modal-popup">
        <div className="back-close" />
        <div
          style={{ width: "60%" }}
          className="center-content d-flex flex-column flex-lg-row p-0 m-0"
        >
          <button
            onClick={() => handleUserPopup()}
            className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
          >
            <img src={closeIcon} alt="" />
          </button>
          <div className="mb-3">
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mt-4">
              <h6 className="mb-2  f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
                Artwork {artworkInd}
              </h6>
              <div className="w-100 d-flex  flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-2 mt-lg-2">
                    <p className="parag mb-2">
                      {userCommend?.product?.name
                        ? userCommend?.product?.name
                        : "-"}
                    </p>
                    <div className="">
                      <div className="prev-img" role={"button"}>
                        <img
                          src={
                            userCommend?.project_request_image?.image_url
                              ? userCommend?.project_request_image?.image_url
                              : noLogo
                          }
                          alt=""
                        />
                      </div>

                      <div
                        className="d-flex ac  mt-4 mob-carous-pop"
                        style={{ width: "300px", overflow: "scroll" }}
                      >
                        {userCommend?.artworks?.map((item, ind) => {
                          return (
                            <div
                              className="down_imgs m-1"
                              key={ind}
                              role={"button"}
                            >
                              <img
                                src={item.image_url ? item.image_url : noLogo}
                                style={{
                                  padding: "10px",
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                  borderRadius: "15px",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f3 primary1 fs-16 fs-md-18 fs-lg-19 mb-0 mt-3">
                      Artworks
                    </p>
                    <select
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={userCommend?.reject_artwork_details?.id}
                    >
                      {userCommend?.artworks?.map((item, ind) => {
                        return (
                          <option
                            style={{ textAlign: "center" }}
                            value={item?.id}
                          >{`Artwork ${ind + 1}`}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Decorative Method
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={
                        userCommend?.reject_artwork_details
                          ?.decorative_method_id?.name
                          ? userCommend?.reject_artwork_details
                              ?.decorative_method_id?.name
                          : "-"
                      }
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Location Name
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={
                        userCommend?.reject_artwork_details?.logo_name
                          ? userCommend?.reject_artwork_details?.logo_name
                          : "-"
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-3 mt-lg-4 pop-input">
                    <div>
                      <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                        Dimensions
                      </p>
                      <input
                        className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                        placeholder="L*B"
                        value={`${
                          userCommend?.reject_artwork_details?.logo_length
                            ? userCommend?.reject_artwork_details?.logo_length
                            : ""
                        }*${
                          userCommend?.reject_artwork_details?.logo_breadth
                            ? userCommend?.reject_artwork_details?.logo_breadth
                            : "-"
                        }`}
                      />
                    </div>
                    <div>
                      <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                        Logo Colour
                      </p>
                      <input
                        className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                        value={
                          userCommend?.reject_artwork_details?.logo_color
                            ? userCommend?.reject_artwork_details?.logo_color
                            : "-"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
                  Why am I Rejecting ?{" "}
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <p className="text-capitalize text-start f4 fs-15">
                          I would like to change the logo I want the logo to be
                          centered Can you show me a different option
                        </p>
                      </Tooltip>
                    )}
                    placement="top"
                  >
                    <span>
                      <ErrorIcon className="" />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                  value={userCommend?.user_comment}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsViewPopup;
