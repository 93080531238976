import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { Search } from "@mui/icons-material";
import { customerList } from "../redux/api/DummyJson";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisteredCustomerList from "../components/Customers/RegisteredCustomerList";
import NonRegisteredCustomerList from "../components/Customers/NonregisteredCustomerList";
import { Col } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomerDetails from "./CustomerDetails";
import CustomerAddress from "./CustomerAddress";
import CustomerProjects from "./CustomerProjects";
import CustomerFiles from "./CustomerFiles";
import CustomerContact from "./CustomerContact";
import CustomerNote from "./CustomerNote";
import {
  useCustomerListViewMutation,
  useLazyProjectUserAddressQuery,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";

const CustomerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slide, setSlide] = useState(false);
  const [tabBtn, setTabBtn] = useState(4);
  const [list, setList] = useState({});
  const [actionBtn, setActionBtn] = useState(false);
  const [detailEdit, setDetailsEdit] = useState(true);
  const [listAddress, setListAddress] = useState([]);
  const [listProject, setListProject] = useState([]);

  const [search, setsearch] = useState("");
  const [date, setdate] = useState("");
  const [status, setstatus] = useState("");

  // RTK Query
  const [customerDetailsApi] = useCustomerListViewMutation();
  const [projectUserAddressListNoPageListApi] =
    useLazyProjectUserAddressQuery();

  const tabData = (item, search_value, date_value, status_value) => {
    // CUSTOMER VIEW
    let formdata = new FormData();

    if (search_value) {
      formdata.append("search", search_value);
    }

    if (date_value) {
      formdata.append("date", date_value);
    }

    if (status_value) {
      formdata.append("status", status_value);
    }

    setsearch(search_value);
    setdate(date_value);
    setstatus(status_value);

    dispatch(saveLoader(true));
    customerDetailsApi({ id: item?.id, payload: formdata })
      .unwrap()
      .then((res) => {
        // console.log(res)
        dispatch(saveLoader(false));
        getAddressList(item?.id);
        setListProject(res?.project);
        setList(res?.user);
        setSlide(true);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // GET ADDRESS
  const getAddressList = (id) => {
    projectUserAddressListNoPageListApi(id)
      .unwrap()
      .then((res) => {
        setListAddress(res?.user_address);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setList((values) => ({ ...values, [name]: value }));
  };
  //  console.log(list,"list")

  const handleClose = () => {
    setSlide(false);
    setListAddress([]);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="animate_img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          E-Commerce/ Customer List
        </p>
      </div>

      <RegisteredCustomerList tabData={tabData} />
      {/* {nonRegList && <NonRegisteredCustomerList />} */}
      <div
        style={{
          // width: slide ? "50%" : "0px",
          width: slide ? "60%" : "0px",
          transition: "0.2s all linear",
          position: "fixed",
          right: "0",
          top: 0,
          zIndex: 1111,
          background: "#fff",
        }}
      >
        <Col className="customer_list_slide px-2">
          <div className="d-flex justify-content-end">
            <div className="mx-2 mt-3">
              <div className="position-relative f3">
                <button
                  className="slide_btn"
                  onClick={() => setActionBtn(!actionBtn)}
                >
                  Actions
                  <KeyboardArrowDownIcon />
                </button>
                {actionBtn && (
                  <div className="action_btn">
                    <button
                      className="border-0 bg-white text-center"
                      onClick={() => {
                        setDetailsEdit(!detailEdit);
                        setActionBtn(!actionBtn);
                      }}
                    >
                      Edit Details
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mx-2 ">
              <button
                // className="slide_btns"
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                style={{ backgroundColor: "#01a0b6" }}
                onClick={() => handleClose()}
              >
                Close
              </button>
            </div>
          </div>
          <div className="mt-3 mx-2 slide_head">
            <h5 className="f4 primary">{list?.name}</h5>
            <div className="d-flex">
              <p className="mt-2 f3">Last Activity: </p>
              <p className="mt-2 mx-1 f1">2023-10-11</p>
              <p className="mt-2 mx-2 primary1">CID #{list?.CustomerId}</p>
            </div>
            <div className="slide_tabs f2">
              <button
                className={
                  tabBtn == 4
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(4)}
              >
                Details
              </button>
              {/* <button
                className={
                  tabBtn == 1
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(1)}>
                Contacts
              </button> */}
              <button
                className={
                  tabBtn == 2
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(2)}
              >
                Addresses
              </button>
              <button
                className={
                  tabBtn == 3
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(3)}
              >
                Projects
              </button>
              {/* <button
                className={
                  tabBtn == 7
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(7)}>
                Order Request ID
              </button> */}
              <button
                className={
                  tabBtn == 8
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(8)}
              >
                Order Request ID
              </button>
              <button
                className={
                  tabBtn == 5
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(5)}
              >
                Files
              </button>
              <button
                className={
                  tabBtn == 6
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line"
                    : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab"
                }
                onClick={() => setTabBtn(6)}
              >
                Notes
              </button>
            </div>
            {tabBtn == 1 && <CustomerContact list={list} />}
            {tabBtn == 2 && (
              <CustomerAddress list={list} listAddress={listAddress} />
            )}
            {tabBtn == 3 && (
              <CustomerProjects
                list={list}
                listProject={listProject}
                tabData={tabData}
                search={search}
                status={status}
                date={date}
              />
            )}
            {tabBtn == 4 && (
              <CustomerDetails
                setList={setList}
                setSlide={setSlide}
                list={list}
                detailEdit={detailEdit}
                setDetailsEdit={setDetailsEdit}
                handleChange={handleChange}
              />
            )}
            {tabBtn == 5 && <CustomerFiles list={list} />}
            {tabBtn == 6 && <CustomerNote list={list} />}
            {tabBtn == 8 && (
              <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
                <table className="table-design-new" style={{ width: "100%" }}>
                  <thead>
                    <tr className="border-bottom w-100">
                      <th>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                          S.No
                        </p>
                      </th>
                      <th>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                          Order Request ID
                        </p>
                      </th>
                      <th>
                        <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                          Order Request Type
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.project_request?.map((item, index) => {
                      return (
                        <tr className="w-100 text-center" key={index}>
                          <td>
                            <p className="w-100 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
                              {index + 1}
                            </p>
                          </td>
                          <td>
                            <Link
                              to={"#"}
                              className="w-100 d-flex ac-jc f3 fs-15 fs-xs-16  text-nowrap fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16  "
                            >
                              <p>{item?.project_request_id}</p>
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={"#"}
                              className="w-100 f3 d-flex ac-jc text-nowrap fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 primary1 fs-xl-16 fs-xxl-16"
                            >
                              {item?.request_type == 1
                                ? "Individual"
                                : item?.request_type == 2
                                ? "Bulk"
                                : "Ecommerce"}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Col>
      </div>
    </div>
  );
};

export default CustomerList;
