import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Steper = ({ stepsConfig = [], projectData }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef([]);

  useEffect(() => {
    if (projectData?.status == 4) {
      setCurrentStep(3);
    } else if (projectData?.status == 5) {
      setCurrentStep(4);
    } else if (projectData?.status == 6) {
      setCurrentStep(5);
    } else if (projectData?.status == 7) {
      setCurrentStep(6);
    } else if (projectData?.status == 8) {
      setCurrentStep(7);
    } else {
      setCurrentStep(projectData?.status);
    }
  }, [projectData?.status]);

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth / 2,
      marginRight: stepRef.current[stepsConfig.length - 1].offsetWidth / 2,
    });
  }, [stepRef, stepsConfig.length]);

  if (!stepsConfig.length) {
    return <></>;
  }

  const handleNext = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === stepsConfig.length) {
        setIsComplete(true);
        return prevStep;
      } else {
        return prevStep + 1;
      }
    });
  };

  const calculateProgressBarWidth = () => {
    return ((currentStep - 1) / (stepsConfig.length - 1)) * 100;
  };

  const ActiveComponent = stepsConfig[currentStep - 1]?.Component;

  return (
    <div className="custom-stepper mt-3">
      <div className="stepper">
        {stepsConfig.map((step, index) => {
          return (
            <div
              key={step.name}
              ref={(el) => (stepRef.current[index] = el)}
              className={`step ${
                currentStep > index + 1 || isComplete ? "complete" : ""
              } ${currentStep === index + 1 ? "active" : ""} `}
            >
              <div className="step-number">
                {currentStep > index + 1 || isComplete ? (
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <p className="text-capitalize text-start f4 fs-15">
                          {/* {new Date().toLocaleDateString()} */}
                        </p>
                      </Tooltip>
                    )}
                    placement="top"
                  >
                    <span>&#128077;</span>
                  </OverlayTrigger>
                ) : currentStep === index + 1 ? (
                  <span>&#9995;</span>
                ) : (
                  <span>&#128078;</span>
                )}
              </div>
              <div className="step-name">{step.name}</div>
            </div>
          );
        })}

        <div
          className="progress-bar"
          style={{
            width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight,
          }}
        >
          <div
            className="progress"
            style={{ width: `${calculateProgressBarWidth()}%` }}
            // style={{ width: `${40}%` }}
          ></div>
        </div>
      </div>

      {/* <ActiveComponent /> */}
      {/* 
      {!isComplete && (
        <button className="btn" onClick={handleNext}>
          {currentStep === stepsConfig.length ? "Finish" : "Next"}
        </button>
      )} */}
    </div>
  );
};

export default Steper;
