import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";

const CustomerProjects = ({
  list,
  listProject,
  tabData,
  search,
  date,
  status,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="d-flex flex-wrap mt-4 mx-3 details_tab link_projects mb-3">
      <div className="w-100">
        <div className="d-flex ac-jb w-100 mt-xs-5 mb-3 position-relative px-2 flex-wrap">
          <input
            className="cust-btn searchFill w-45 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
            onChange={(e) => tabData(list, e.target.value, date, status)}
            value={search}
          />
          <input
            type="date"
            id="from"
            name="from"
            onChange={(e) => tabData(list, search, e.target.value, status)}
            value={date}
            className="cust-btn searchFill w-45 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <select
            className="mt-3 w-45 cust-btn searchFill fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            onChange={(e) => tabData(list, search, date, e.target.value)}
            value={status}
          >
            <option disabled selected value="">
              Status
            </option>
            <option value={0}>Rejected</option>
            <option value={1}>Overview</option>
            <option value={2}>Collaborate</option>
            <option value={3}>Presentation</option>
            <option value={4}>Show to Customer</option>
            <option value={5}>Estimates</option>
            <option value={6}>Production Artwork</option>
            <option value={7}>Sales Order</option>
          </select>
          <div>
            <button
              className="cust-btn addbtn1 mt-3 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              onClick={() => tabData(list, "", "", "")}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                  Project
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                  Project Request ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                  Stage
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc text-nowrap">
                  In Hands Date
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {listProject?.map((item, index) => {
              return (
                <tr className="w-100" key={index}>
                  <td>
                    <p className="w-100 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="w-100 f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc ">
                      {item?.project_code}
                    </p>
                  </td>
                  <td>
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                      {item?.project_request?.project_request_id}
                    </p>
                  </td>
                  <td>
                    <p className="w-100 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray text-nowrap">
                      {item?.status == 0
                        ? "Rejected"
                        : item?.status == 1
                        ? "Overview"
                        : item?.status == 2
                        ? "Collaborate"
                        : item?.status == 3
                        ? "Presentation"
                        : item?.status == 4
                        ? "Show to Customer"
                        : item?.status == 5
                        ? "Estimates"
                        : item?.status == 6
                        ? "Production Artwork"
                        : item?.status == 7
                        ? "Estimates Show to Customer"
                        : item?.status == 8
                        ? "Sales Order"
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="w-100 f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc gray">
                      {item?.in_hand_date}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerProjects;
