import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "react-bootstrap/Modal";

const AlertPopUp = ({ alertMessage, togglePopup }) => {
  return (
    <Modal
      show={alertMessage ? true : false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="customPopupDesign_model">
        <div className="customPopupDesign_model_pop">
          <h6 className="text-center">{alertMessage}</h6>
          <div className="d-flex justify-content-center pt-2 mt-3">
            <button
              className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3 "
              onClick={togglePopup}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AlertPopUp;

// <div className="alert-popup">
//   <div className="customPopupDesign">
//     <h6 className="text-center">{alertMessage}</h6>
//     <div className="d-flex justify-content-center pt-2">
//       <button
//         className="cust-btn bg-primar text-white rounded-3 p-2 d-grid m-3"
//         onClick={togglePopup}
//       >
//         Close
//       </button>
//     </div>
//   </div>
// </div>
