import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { product } from "../assets/img";
import { useCustomerNotesMutation } from "../redux/api/api";

const CustomerNote = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [data, setData] = useState([]);
  const [add, setAdd] = useState(false);
  const [notes, setNotes] = useState("");
  const [notesObj, setNotesObj] = useState({});
  //RTK
  const [noteApi] = useCustomerNotesMutation();

  useEffect(() => {
    if (list?.project_request?.length > 0) {
      setData(list?.project_request);
    }
  }, [list]);

  const handleChangeNotes = (event, ind, type) => {
    let temp = [...data];

    temp[ind] = {
      ...temp[ind],
      [type]: event,
    };
    console.log(temp);
    setData(temp);
  };

  const getNote = (id, notes) => {
    const formdata = new FormData();
    formdata.append("project_requests[0][id]", id);
    formdata.append("project_requests[0][notes]", notes);
    noteApi(formdata)
      .unwrap()
      .then()
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log('data', data);

  return (
    <>
      <div className="mt-4 mx-3 details_tab">
        <div className="d-flex justify-content-between">
          <Col md={12} lg={8}>
            {add && (
              <textarea
                placeholder="text here..."
                className="w-100 rounded-3 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
              />
            )}
          </Col>
          {/* <Col md={12} lg={4}>
            {!add ? (
              <button
                className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => setAdd(true)}
              >
                Add Notes
              </button>
            ) : (
              <button
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => setAdd(false)}
              >
                Submit
              </button>
            )}
          </Col> */}
        </div>
        {data?.map((item, ind) => (
          <div
            className="editBtn rounded-3 p-2 w-100 text mt-3 notes_bg "
            style={{ height: "100%" }}
          >
            <div className="d-flex jb-ac mt-1">
              <p className="pe-3 ">{item?.project_request_id}</p>
              <p>{item?.when_needed}</p>
            </div>
            <textarea
              rows="4"
              cols="50"
              className="f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 w-100 mt-3 ps-2"
              value={item?.admin_notes}
              onChange={(e) =>
                handleChangeNotes(e.target.value, ind, "admin_notes")
              }
            ></textarea>

            <div className="edit_btn">
              <button
                className="mx-2 "
                onClick={() => getNote(item?.id, item?.admin_notes)}
              >
                <p className="mb-0">Edit</p>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomerNote;
