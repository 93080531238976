import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Col } from "react-bootstrap";
import {
  DocIcon,
  editIcon,
  fileDocIcon,
  product,
  viewIcon,
  xlsDocIcon,
} from "../../assets/img";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { saveImage } from "../../redux/slice/imageSave";
import { CloseOutlined } from "@mui/icons-material";
import { color } from "@mui/system";
// import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import {
  useFileAddMutation,
  useFileDeleteMutation,
  useFileDownloadMutation,
  useFileListMutation,
  useFilesClientMutation,
  useFilesUpdateMutation,
  useFileViewMutation,
  useFilecommentAddMutation,
  useFileclientcommandAddMutation,
  useActivityListMutation,

} from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";
import moment from "moment";
const AddFilesPopupCopy = ({
  toggleShowPopup,
  popupType,
  setImageUploadData,
  imageUploadData,
  btnChange,
  add,
  OnpostList
}) => {
  const dispatch = useDispatch();
  const [btn, setBtn] = useState(0);
  const [tab, setTab] = useState(0);
  const [upload, setUpload] = useState(false);
  const [progress_Bar, setProgress_Bar] = useState(false);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState(false);
  const [btnFiles, setBtnFiles] = useState(0);
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [types, setTypes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [croper, setCroper] = useState(false);
  const [typeerr, settypeErr] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [createdat, setCreatedAt] = useState("");
  const [client, setClient] = useState([]);
  const downloadLinkRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProduct] = useState([]);
  const [documents, setDocument] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [material, setMaterial] = useState([]);
  const [artWork, setArtwork] = useState([]);
  const [other, setOther] = useState([]);
  const [notes, setNotes] = useState([]);
  const [editId, setEditId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedClientFiles, setSelectedClientFiles] = useState({});

  const [project_file_ids, setProject_file_ids] = useState([]);
  const [description, setDescription] = useState();
  const [clientdescription, setClientDescription] = useState();
  const [activityData, setActivityData] = useState([]);

  const [descriptionErr, setDescriptionErr] = useState(false);
  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  // RTK QUERY
  const [addfile] = useFileAddMutation();
  const [clientFile] = useFilesClientMutation();
  const [listfile] = useFileListMutation();
  const [updatefile] = useFilesUpdateMutation();
  const [viewfile] = useFileViewMutation();
  const [deletefile] = useFileDeleteMutation();
  const [dounloadfile] = useFileDownloadMutation();
  const [addcomments] = useFilecommentAddMutation();
  const [addclientcomments] = useFileclientcommandAddMutation();
  const [activityList] = useActivityListMutation();

  // PROGRESS BAR
  const [countOfProgess, setCountOfProgess] = useState(0);
  console.log(searchParams, "searchParams");

  useEffect(() => {
    if (progress_Bar) {
      const timer = setInterval(() => {
        setCountOfProgess((oldProgress) => {
          if (100 == oldProgress) {
            setProgress_Bar(false);
            setImageUrl(true);
            return 0;
          }
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 499);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress_Bar]);

  const handleImage = (file) => {
    setImage(file);
    if (file?.name) {
      dispatch(saveImage(URL.createObjectURL(file)));
    } else {
      dispatch(saveImage(file));
    }
  };
  const handleSelectChange = (event) => {
    setType(event.target.value);
  };
  const urlToObject = async () => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });

    setImage(file);
  };
  const getView = (id) => {
    viewfile(id)
      .unwrap()
      .then((res) => {
        console.log(res?.data, "view");
        setType(res?.data?.type);
        setImage(res?.data?.file_full_url);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDownload = async (id) => {
    try {
      const res = await dounloadfile(id).unwrap();
      console.log(res, "download");

      if (res?.file_url) {
        const response = await fetch(res.file_url);

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        const blob = await response.blob();

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;

        let fileName = "downloaded-file";
        const contentType = response.headers.get("Content-Type");
        if (contentType.includes("application/pdf")) {
          fileName += ".pdf";
        } else if (contentType.includes("image")) {
          fileName += ".jpg";
        } else if (
          contentType.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          fileName += ".xlsx";
        } else if (contentType.includes("application/msword")) {
          fileName += ".doc";
        } else {
          fileName += ".bin";
        }

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.error("Error downloading the file:", err);
    }
  };

  // const getDownload = async(id) => {
  //   dounloadfile(id)
  //     .unwrap()
  //     .then((res) => {
  //       console.log(res, "dounload");
  //       if (res?.file_url) {

  //         // const link = document.createElement("a");
  //         // link.href = URL.createObjectURL(new Blob([res?.file_url]));
  //         // link.setAttribute("download", "your-file.pdf"); // Replace with desired file name
  //         // document.body.appendChild(link);
  //         // link.click();
  //         try{

  //           const response = await fetch(fileUrl);
  //       const blob = await response.blob(); // Get the content as Blob

  //       // Create a link element, use URL.createObjectURL to force download
  //       const link = document.createElement("a");
  //       const url = URL.createObjectURL(blob);  // Create object URL for the Blob
  //       link.href = url;
  //       link.setAttribute("download", "your-file.pdf");  // Desired file name
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);  // Clean up after downloading
  //       URL.revokeObjectURL(url);
  //         }.cat

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getDownload = (d) => {
  //   const link = document.createElement("a"); // Create a hidden <a> element
  //   link.href = d; // URL of the image/file to download
  //   link.download = "downloaded-image.jpg"; // Set the download filename
  //   document.body.appendChild(link); // Append link to body to make it clickable
  //   link.click(); // Programmatically click the link
  //   document.body.removeChild(link); // Remove the link after downloading
  // };
  const getDelete = (id) => {
    deletefile(id)
      .unwrap()
      .then((res) => {
        console.log(res, "delete");
        toast.success(res?.message);
        List();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const List = () => {
    var urlencoded = new URLSearchParams();
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      urlencoded.append("project_id", split[1]);
      console.log("list");
      listfile(urlencoded)
        .unwrap()
        .then((res) => {
          let data = res?.data;
          console.log(data, "listdaat");
          setProduct(data?.project_deck);
          setDocument(data?.accounting_documents);
          setCustomer(data?.customer_po);
          setMaterial(data?.material_information);
          setArtwork(data?.artwork);
          setOther(data?.others);
          setNotes(data?.notes_attachement);
          // let files = res?.data?.project_deck;
          // const formatTime = (dateString) => {
          //   if (typeof dateString !== "string") {
          //     dateString =
          //       dateString instanceof Date
          //         ? dateString.toISOString()
          //         : String(dateString);
          //   }
          //   const date = new Date(dateString);
          //   if (isNaN(date.getTime())) {
          //     return "";
          //   }
          //   const hours = date.getUTCHours();
          //   const minutes = date.getUTCMinutes();
          //   const ampm = hours >= 12 ? "PM" : "AM";
          //   const formattedHours = hours % 12 || 12;
          //   const formattedMinutes = minutes.toString().padStart(2, "0");
          //   const result = `${formattedHours}:${formattedMinutes} ${ampm}`;
          //   return result;
          // };
          // setCreatedAt(formatTime(files.created_at));
          // console.log(createdat, "createdat");
          // setImageUrl(files.file_full_url);
          // console.log(files, "files");
          // console.log(data, "datass");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const ListClient = () => {
    var urlencoded = new URLSearchParams();
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      urlencoded.append("project_id", split[1]);
      console.log("list");
      clientFile(urlencoded)
        .unwrap()
        .then((res) => {
          let data = res?.data;
          console.log(data, "listclentsdaat");
          setClient(data);
          // let files = res?.data?.project_deck;
          // const formatTime = (dateString) => {
          //   if (typeof dateString !== "string") {
          //     dateString =
          //       dateString instanceof Date
          //         ? dateString.toISOString()
          //         : String(dateString);
          //   }
          //   const date = new Date(dateString);
          //   if (isNaN(date.getTime())) {
          //     return "";
          //   }
          //   const hours = date.getUTCHours();
          //   const minutes = date.getUTCMinutes();
          //   const ampm = hours >= 12 ? "PM" : "AM";
          //   const formattedHours = hours % 12 || 12;
          //   const formattedMinutes = minutes.toString().padStart(2, "0");
          //   const result = `${formattedHours}:${formattedMinutes} ${ampm}`;
          //   return result;
          // };
          // setCreatedAt(formatTime(files.created_at));
          // console.log(createdat, "createdat");
          // setImageUrl(files.file_full_url);
          // console.log(files, "files");
          // console.log(data, "datass");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    List();
    ListClient();
  }, []);
  const submitHandler = () => {
    let formdata = new FormData();
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");

      formdata.append("type", type);
      if (image?.name) {
        formdata.append("file", image);
      }

      // setBtn(true);
      // dispatch(saveLoader(true));
      if (types == "edit") {
        formdata.append("project_id", split[1]);
        updatefile({ id: editId, payload: formdata })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message);
              // navigate("/profile");
              List();
            }
            setBtn(0);
            setShow(false);
            // setBtn(false);
            // dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            // dispatch(saveLoader(false));
            setBtn(false);
          });
      } else {
        formdata.append("project_id", split[1]);
        addfile(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message);
              // navigate("/overview");
            }
            setShow(false);
            // setBtn(false);
            List();
            setBtn(0);
            // dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            // dispatch(saveLoader(false));
            setBtn(false);
          });
      }
    }
  };
  const userdetails = localStorage.getItem("userDetails");
  let userStr = JSON.parse(userdetails);
 

  const submitComment = () => {
    if (
      (!description || description.trim().length === 0) &&
      (!clientdescription || clientdescription.trim().length === 0)
    ) {
      setDescriptionErr(true);
    } else if (errorMessage?.length === 0) {
      let formdata = new FormData();
      let project_code = searchParams?.get("project");

      if (project_code) {
        let split = project_code.split("_");

        formdata.append("project_id", split[1]);
        formdata.append(
          "description",
          btn === 0 ? description : clientdescription
        );
        formdata.append("admin_id", userStr?.id);

        // Handle file selection
        let allSelectedFileIds = [];
        const filesToUse = btn === 0 ? selectedFiles : selectedClientFiles;

        Object.keys(filesToUse).forEach((btnFilesIndex) => {
          const fileIds = filesToUse[btnFilesIndex] || [];
          allSelectedFileIds = allSelectedFileIds.concat(fileIds);
        });

        allSelectedFileIds.forEach((fileId) => {
          if (btn === 0) {
            formdata.append("project_file_ids[]", fileId);
          } else {
            formdata.append("project_request_image_ids[]", fileId);
          }
        });

        // Dispatch loader if needed (uncomment if using)
        dispatch(saveLoader(true));

        // Conditionally call the appropriate API based on the btn value
        const apiCall = btn === 0 ? addcomments : addclientcomments;

        apiCall(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status === "success") {
              toast.success(res?.data?.message || res?.message);
              setDescription("");
              setSelectedFiles("");
              setSelectedClientFiles("");
              setShow(false);
              toggleShowPopup()

              if(OnpostList){
                OnpostList()
              }
              
            }
            dispatch(saveLoader(false)); // Stop the loader after API call
            
          })
          .catch((err) => {
            console.log("Error submitting comment:", err);
            dispatch(saveLoader(false)); // Stop the loader in case of error
          });
      }
    }
  };

  const handleCheckboxClick = (itemId) => {
    const currentCategory = btnFiles; // Assuming btnFiles is the current category index
    const currentSelected = selectedFiles[currentCategory] || [];

    if (currentSelected.includes(itemId)) {
      setSelectedFiles({
        ...selectedFiles,
        [currentCategory]: currentSelected.filter((id) => id !== itemId),
      });
    } else {
      setSelectedFiles({
        ...selectedFiles,
        [currentCategory]: [...currentSelected, itemId],
      });
    }
  };

  const handleCheckboxClicks = (itemId) => {
    const currentCategory = btn;
    const currentSelected = selectedClientFiles[currentCategory] || [];

    if (currentSelected.includes(itemId)) {
      setSelectedClientFiles({
        ...selectedClientFiles,
        [currentCategory]: currentSelected.filter((id) => id !== itemId),
      });
    } else {
      setSelectedClientFiles({
        ...selectedClientFiles,
        [currentCategory]: [...currentSelected, itemId],
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div>
            <span className="d-flex justify-content-end">
              <button onClick={() => setShow(false)} className="cust-btn">
                <CloseOutlined class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" />
              </button>
            </span>
            <div>
              <div className="w-100">
                <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                  Type
                </p>
                <select
                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  value={type}
                  onChange={handleSelectChange}
                >
                  <option hidden>Select</option>
                  <option value={1}>Product Deck</option>
                  <option value={2}>Accounting Documents</option>
                  <option value={3}>Customer PO</option>
                  <option value={4}>Material Information</option>
                  <option value={5}>Artwork</option>
                  <option value={6}>Notes and Attachment</option>
                  <option value={7}>Others</option>
                </select>
              </div>
              <div className="w-100">
                <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                  Image
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type={"file"}
                  onChange={(e) => setImage(e.target.files[0])}
                />
                {/* {(types == "view" || types == "edit") && (
                  <div className="col-md-6 col-12 px-md-3 px-0 position-relative mb-3">
                    {image && (
                      <img
                        src={image.name ? URL.createObjectURL(image) : image}
                        alt="image"
                        style={{ width: "100px" }}
                      />
                    )}
                  </div>
                )} */}
              </div>
              <div className="d-flex mt-3 justify-content-center m-2">
                <button
                  onClick={() => {
                    submitHandler(); // Wait for submission to complete
                    setShow(false); // Hide the component/modal
                  }}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {show == false && (
        <div className="add-files-overiew-popup">
          <div style={{ overflow: "auto" }}>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Select File
              <span>
                <button
                  onClick={() => toggleShowPopup()}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  {/* <HighlightOffIcon class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" /> */}
                  {/* <CloseOutlined class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" /> */}
                  Cancel
                </button>
              </span>
            </p>
            <div class="w-100 my-3 d-flex ac-jb w-100 flex-column flex-md-row">
              <div className="d-flex ac-jb w-100 align-items-center justify-content-sm-start">
                <button
                  onClick={() => setBtn(0)}
                  class={
                    btn == 0
                      ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                      : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3"
                  }
                >
                  Project Files
                </button>
                <button
                  onClick={() => setBtn(1)}
                  class={
                    btn == 1
                      ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                      : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3"
                  }
                >
                  Client Files
                </button>
              </div>
              {btn == 1 && (
                <select className="editBtn mt-4 mt-md-0 w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  <option>All Files</option>
                </select>
              )}
            </div>
            <div className="d-flex">
              {/* <Col lg={8} md={8}>
              <div className="d-flex over_files_pop">
                <button
                  className={btn == 0 && "btn_overview_line"}
                  onClick={() => setBtn(0)}
                >
                  Project Files
                </button>
                <button
                  className={btn == 1 && "btn_overview_line"}
                  onClick={() => setBtn(1)}
                >
                  Client Files
                </button>
              </div>
            </Col> */}
              <div>
                {/* {btn == 1 && (
                <select className="w-40 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2">
                  <option>All Files</option>
                </select>
              )} */}

                {popupType == "addClient" && (
                  <button
                    onClick={() => setUpload(!upload)}
                    className="w-100 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
            {/* <div className="layer_scroll"> */}
            {btn == 0 && add == 0 && (
              <div className="d-flex mt-1 justify-content-start m-2">
                <button
                  onClick={() => setShow(true)}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              </div>
            )}

            {btn == 0 && (
              <>
                <div className="d-flex ae-jb w-100">
                  <button
                    className={`${
                      btnFiles == 0 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(0)}
                  >
                    Product Deck
                  </button>

                  <button
                    className={`${
                      btnFiles == 1 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(1)}
                  >
                    Accounting Documents
                  </button>
                  <button
                    className={`${
                      btnFiles == 2 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(2)}
                  >
                    Customer PO
                  </button>
                  <button
                    className={`${
                      btnFiles == 3 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(3)}
                  >
                    Material Information
                  </button>
                  <button
                    className={`${
                      btnFiles == 4 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(4)}
                  >
                    Artwork
                  </button>
                  <button
                    className={`${
                      btnFiles == 5 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(5)}
                  >
                    Notes and Attachment
                  </button>
                  <button
                    className={`${
                      btnFiles == 6 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(6)}
                  >
                    Others
                  </button>
                </div>
                {btnFiles == 0 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {products?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;
                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />

                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>

                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 "
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                                className="overview_check"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                          {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                          {description?.length == 0 && descriptionErr && (
                            <div className="d-flex gap-1 mt-2">
                              {/* <ErrorIcon className="svg_log" /> */}
                              <p className="err-input_log mt-1">
                                Enter Description
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 1 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {documents?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;

                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 2 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {customer?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;

                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 3 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {material?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;

                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 4 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {artWork?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;

                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 5 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {notes?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;

                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {btnFiles == 6 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {other?.map((item, ind) => {
                      const isChecked =
                        selectedFiles[btnFiles]?.includes(item?.id) || false;
                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img
                              src={
                                item?.file_full_url.includes(".pdf")
                                  ? fileDocIcon
                                  : item?.file_full_url.includes(".xls")
                                  ? xlsDocIcon
                                  : item?.file_full_url.includes(".doc")
                                  ? DocIcon
                                  : item?.file_full_url
                              }
                              // className="w-100"
                            />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => {
                                  setTypes("edit");
                                  setEditId(item?.id);
                                  getView(item?.id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="mx-2"
                                onClick={() => {
                                  getDelete(item?.id);
                                }}
                              >
                                Delete
                              </button>
                              <button
                                className="px-2 px-md-3 py-0 mx-1"
                                onClick={() => {
                                  getDownload(item?.id);
                                }}
                              >
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                              <input
                                className="overview_check"
                                checked={isChecked}
                                onChange={() => handleCheckboxClick(item?.id)}
                                type="checkbox"
                              />
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      );
                    })}
                    {selectedFiles[btnFiles] &&
                      selectedFiles[btnFiles].length > 0 && (
                        <div className="w-md-50 w-100 position-relative">
                          <textarea
                            className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            placeholder="Write comment"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ margin: "40px" }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {/* {btnFiles >= 0 && btnFiles <= 5 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {[0, 1]?.map((item, ind) => {
                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img src={imageUrl} className="w-100" />
                            <div className="files_btnn d-flex justify-content-between">
                            <button
  className=""
  onClick={() => setShow(true) || setType({ type: "edit"})}
>
  Edit
</button>
                              <button className="">Delete</button>
                              <button className="px-2 px-md-3 py-0 mx-1">
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {imageUrl}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {createdat}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )} */}
                {/* {btnFiles == 6 && (
                  <div className="d-flex layer_scroll">
                    <div className="files_pop_pro me-3">
                      <button
                        className="over_img bg-transparent mx-3"
                        onClick={() => setImageUploadData(!imageUploadData)}
                      >
                        <img src={product} className="w-100" />
                        <div className="files_btnn d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="files_view_icon me-1 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 ">
                              <a>
                                <RemoveRedEyeIcon className="primary" />
                              </a>
                            </div>
                            <div className="files_view_icon me-1 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 ">
                              <a>
                                <DriveFileRenameOutlineIcon className="primary" />
                              </a>
                            </div>
                          </div>
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </button>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    {image && (
                      <div className="files_pop_pro mx-2">
                        <div className="over_img">
                          <img
                            src={
                              image?.name ? URL.createObjectURL(image) : image
                            }
                            className="w-100 object-fit-contain"
                          />
                          <div className="files_btn d-flex-ac-jc">
                            <button className="px-2 px-md-3 py-0 mx-1">
                              <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                            </button>
                          </div>
                        </div>
                        <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                          Jobs1.jpeg
                        </p>
                        <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                          Updated 14 October 2023
                        </p>
                      </div>
                    )}
                  </div>
                )} */}
              </>
            )}
            {/* </div> */}
            <div className="layer_cont">
              {btn == 1 && (
                <div className="d-flex justify-content-start ms-2 align-items-center gap-4">
                  {client?.map((item, ind) => {
                    const isChecked =
                      selectedClientFiles[btn]?.includes(item?.id) || false;

                    return (
                      <div className="files_pop_pro d-flex flex-column ac-jb">
                        <div className="over_img">
                          <img
                            src={
                              item?.image_url ? item?.image_url : fileDocIcon
                            }
                            className="w-100"
                          />
                          <div className="files_btn d-flex-ac-jc">
                            <button
                              className="px-2 px-md-3 py-0 mx-1"
                              onClick={() => {
                                getDownload(item?.id);
                              }}
                            >
                              <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                            </button>
                            <input
                              checked={isChecked}
                              onChange={() => handleCheckboxClicks(item?.id)}
                              type="checkbox"
                              className="overview_check"
                            />
                          </div>
                        </div>
                        <div className="w-100">
                        <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            {/* {item?.file_name.split("/",7)} */}
                            {item?.file_original_name}
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            {moment(item?.created_at).format("MMMM Do YYYY")}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="files_pop_pro d-flex flex-column ac-jb">
                    <div className="over_img">
                      <img src={product} className="w-100" />
                      <div className="files_btn d-flex-ac-jc">
                        <button className="px-2 px-md-3 py-0 mx-1">
                          <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                        </button>
                      </div>
                    </div>
                    <div className="w-100">
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                  </div>
                  <div className="files_pop_pro d-flex flex-column ac-jb">
                    <div className="over_img">
                      <img src={product} className="w-100" />
                      <div className="files_btn d-flex-ac-jc">
                        <button className="px-2 px-md-3 py-0 mx-1">
                          <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                        </button>
                      </div>
                    </div>
                    <div className="w-100 text-md-start text-center">
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                  </div> */}
                </div>
              )}
              {selectedClientFiles[btn] &&
                selectedClientFiles[btn].length > 0 && (
                  <div className="w-md-50 w-100 position-relative">
                    <textarea
                      className="editBtn w-80 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      placeholder="Write comment"
                      onChange={(e) => setClientDescription(e.target.value)}
                      value={clientdescription}
                      style={{ margin: "40px" }}

                    />
                  </div>
                )}
            </div>
            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={() => {
                  // toggleShowPopup();
                  submitComment();
                }}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
              <button
                onClick={() => toggleShowPopup()}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                {/* <HighlightOffIcon class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" /> */}
                {/* <CloseOutlined class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" /> */}
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {upload && (
        <div className="upload-file-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Upload File
              <span>
                <button onClick={() => setUpload(!upload)} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex over_files_pop">
              <button
                className={tab == 0 && "btn_overview_line"}
                onClick={() => setTab(0)}
              >
                From Computer
              </button>
              <button
                className={tab == 1 && "btn_overview_line"}
                onClick={() => setTab(1)}
              >
                From Web
              </button>
            </div>
            {tab == 1 ? (
              <div className="mt-3">
                <div className="d-flex">
                  <input
                    type={"url"}
                    className="w-50"
                    placeholder="http://www..."
                    onChange={(e) => handleImage(e.target.value)}
                  />
                  <button
                    className={
                      image?.length > 0
                        ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        : "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded opacity-50"
                    }
                    onClick={() => setProgress_Bar(true)}
                    disabled={image?.length > 0 ? false : true}
                  >
                    Select
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-center fileUp-load-box  mt-3">
                  <label className="mt-3 text-info">
                    Drag or click to upload file
                    <input
                      type={"file"}
                      className="d-none"
                      accept="image/png, image/jpeg"
                      onChange={(e) => handleImage(e.target.files[0])}
                    />
                  </label>
                </div>
                <div className="mt-2 text-end">
                  {image?.name && (
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => setProgress_Bar(true)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </>
            )}

            {progress_Bar && (
              <div className="d-block w-50 mt-2">
                <ProgressBar now={countOfProgess} />
                <div className="text-end">
                  <span>{parseInt(countOfProgess)} %</span>
                </div>
              </div>
            )}
            {imageUrl && (
              <div onClick={() => setUpload(false)}>
                <img
                  src={image?.name ? URL.createObjectURL(image) : image}
                  className="mt-2 w-25"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddFilesPopupCopy;
