import React, { useEffect, useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateEditComp from "../EstimateComp/EstimateEdit/EstimateEditComp";
import EstimateEditTableComp from "../EstimateComp/EstimateEdit/EstimateEditTableComp";
import { useLocation, useNavigate } from "react-router-dom";
import CheckingPricePoppup from "../EstimateComp/Poppup/CheckingPricePoppup";
import ImageUpload from "../EstimateComp/Poppup/ImageUpload";
import { Close } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageView from "../EstimateComp/Poppup/ImageView";
import ImageCroper from "../Popup/ImageCroper";
import AddFilesPopup from "../Popup/AddFilesOverview";
import AddFilesPopupCopy from "../Popup/AddFiles";
import AddArtWorker from "./AddArtWorker";
import ArtWorkerData from "./ArtWorkerData";
import {
  useAddProductInfoMutation,
  useEstimateEditMutation,
  useEstimateQtyAddMutation,
  useEstimateSizeMutation,
  useLazyEstimateTaxQuery,
  useLazyPresentationProViewQuery,
  usePresentationArtworkStatusMutation,
  useEstimateMarginReqMutation,
  useHsnSearchListMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { noLogo } from "../../assets/img";

const EstimateEditItemScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationNavi = useLocation();
  const projectData = locationNavi?.state?.list;
  const [checkPricePoppup, setCheckPricePoppup] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [imageUploadData, setImageUploadData] = useState(false);
  const [dropDown, setDropDown] = useState(undefined);
  const [imageViewPoppup, setImageViewPoppup] = useState(undefined);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [product_skuVarient, setproduct_skuVarient] = useState("");
  const [product_skuVarientID, setproduct_skuVarientID] = useState("");
  const [price_per_pack, setprice_per_pack] = useState("");
  const [reqBtn, setReqBtn] = useState(2);
  const [discountType, setDiscountType] = useState(0);

  const [production_lead_time, setproduction_lead_time] = useState(
    "10-12 Business Days"
  );
  const [sample_lead_time, setsample_lead_time] = useState("2-5 Business Days");
  const [product_note, setproduct_note] = useState("");
  const [description, setdescription] = useState("");
  const [productData, setProductData] = useState({});
  const [sideImg, setSideImg] = useState(noLogo);
  const [viewData, setViewData] = useState({});
  const [warnings, setWarnings] = useState([]);
  const [location, setLocation] = useState([]);

  const [viewSupplier, setViewSupplier] = useState([]);
  const [decorative_methods, setdecorative_methods] = useState([]);
  const [skus, setskus] = useState([]);

  const [production_lead_timeErr, setproduction_lead_timeErr] = useState(false);
  const [sample_lead_timeErr, setsample_lead_timeErr] = useState(false);
  const [product_noteErr, setproduct_noteErr] = useState(false);
  const [descriptionErr, setdescriptionErr] = useState(false);
  const [btns, setBtn] = useState(false);
  const [taxAdd, setTaxAdd] = useState([""]);

  const [artworkData, setArtWorkData] = useState({});
  const [artworkDatail, setArtWorkDatail] = useState({});
  const [decorative_methodList, setdecorative_methodList] = useState([]);
  const [decorativeMethodList, setDecorativeMethodList] = useState([]);
  const [decorative_method_id, setdecorative_method_id] = useState("");
  const [decorative_method_name, setdecorative_method_name] = useState("");
  const [proof_required, setproof_required] = useState("");
  const [logo_name, setlogo_name] = useState("");
  const [uom, setuom] = useState(0);
  const [logo_length, setlogo_length] = useState("");
  const [logo_breadth, setlogo_breadth] = useState("");
  const [logo_color, setlogo_color] = useState("");
  const [repeat_logo, setrepeat_logo] = useState("");
  const [supplier_note, setsupplier_note] = useState("");
  const [location2, setlocation2] = useState("");
  const [file, setfile] = useState("");
  const [design_status, setdesign_status] = useState("");
  const [sale_status, setsale_status] = useState("");
  const [productPriceData, setproductPriceData] = useState({});

  const [decorative_method_idErr, setdecorative_method_idErr] = useState(false);
  const [proof_requiredErr, setproof_requiredErr] = useState(false);
  const [logo_nameErr, setlogo_nameErr] = useState(false);
  const [uomErr, setuomErr] = useState(false);
  const [logo_lengthErr, setlogo_lengthErr] = useState(false);
  const [logo_breadthErr, setlogo_breadthErr] = useState(false);
  const [logo_colorErr, setlogo_colorErr] = useState(false);
  const [repeat_logoErr, setrepeat_logoErr] = useState(false);
  const [supplier_noteErr, setsupplier_noteErr] = useState(false);
  const [locationErr, setlocationErr] = useState(false);
  const [fileErr, setfileErr] = useState(false);

  const [discount_percent, setdiscount_percent] = useState("");
  const [discount_value, setdiscount_value] = useState("");
  const [net_price, setnet_price] = useState("");
  const [retail_price, setretail_price] = useState("");
  const [total, settotal] = useState("");
  const [sub_total, setsub_total] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [hsnCodes, setHsnCodes] = useState("");
  const [margin_percent, setmargin_percent] = useState("");
  const [margin_percents, setmargin_percents] = useState("");
  const [tax_value, settax_value] = useState("");
  const [tax_sum_value, settax_sum_value] = useState("");
  const [quantity, setquantity] = useState("");
  const [selectedSku, setSelectedSku] = useState("View SKU");
  const [gst_type, setgst_type] = useState("");

  const [AddCharges, setAddCharges] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsID, setColorsID] = useState([]);
  const [colorAndSize, setColorAndSize] = useState([]);

  //   Croper
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [taxList, setList] = useState();
  const [igst, setigst] = useState("");
  const [sgst, setsgst] = useState("");
  const [cgst, setcgst] = useState("");

  // RTK QUERY
  const [presentationProViewApi] = useLazyPresentationProViewQuery();
  const [presentationInfoAddApi] = useAddProductInfoMutation();
  const [artworkStatusApi] = usePresentationArtworkStatusMutation();
  const [estimateTaxApi] = useLazyEstimateTaxQuery();
  const [estimateEditApi] = useEstimateEditMutation();
  const [estimateQtyApi] = useEstimateSizeMutation();
  const [estimateQtyUpdateApi] = useEstimateQtyAddMutation();
  const [estimateMarginReqApi] = useEstimateMarginReqMutation();
  const [hsnSearchListApi] = useHsnSearchListMutation();

  const getView = (tax_data) => {
    presentationProViewApi(locationNavi?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let responce = res?.project_detail;
          setProductData(res?.project_detail);
          getList(res);
          setLocation(res?.project_detail?.projectArtwork);
          setViewData(res);
          setproductPriceData(res?.productPrice);
          setViewSupplier(res?.vendors);
          setdecorative_methods(res?.decorative_methods);
          setskus(res?.skus);
          if (res?.project_detail?.hsn_code) {
            setHsnCode(res?.project_detail?.hsn_code);
            setHsnCodes(res?.project_detail?.hsn_code);
          }

          if (res?.project?.discount_type) {
            setDiscountType(res?.project?.discount_type);
          }

          setproduct_skuVarient(
            responce?.product_sku?.product_parent_sku?.variant_sku
              ? responce?.product_sku?.product_parent_sku?.variant_sku
              : responce?.product_sku?.variant_sku
              ? responce?.product_sku?.variant_sku
              : // : res?.skus?.[0]?.variant_name
                // ? res?.skus?.[0]?.variant_name
                // : res?.skus?.[0]?.variant_sku
                // ? res?.skus?.[0]?.variant_sku
                "-"
          );
          setproduct_skuVarientID(
            responce?.product_sku?.product_parent_sku?.id
              ? responce?.product_sku?.product_parent_sku?.id
              : responce?.product_sku?.id
              ? responce?.product_sku?.id
              : // : res?.skus?.[0]?.id
                // ? res?.skus?.[0]?.id
                "-"
          );

          let projectPresentationPrice =
            res?.project_detail?.projectPresentationPrice;

          let pack = +res?.project_detail?.qty;
          let value = 25;
          if (pack >= 1 && pack <= 25) {
            value = 25;
          } else if (pack >= 26 && pack <= 50) {
            value = 50;
          } else if (pack >= 51 && pack <= 100) {
            value = 100;
          } else if (pack >= 101 && pack <= 150) {
            value = 150;
          } else if (pack >= 151 && pack <= 250) {
            value = 250;
          } else if (pack >= 251 && pack <= 500) {
            value = 500;
          } else if (pack >= 501 && pack <= 1000) {
            value = 1000;
          }

          let final_amount;

          if (
            res?.project_detail?.net_price > 0 &&
            res?.project_detail?.retail_price > 0 &&
            res?.project_detail?.final_amount > 0
          ) {
            // final_amount = res?.project_detail?.productPrice?.[`for_${value}`];
            final_amount = res?.project_detail?.final_amount;
            setprice_per_pack(
              +res?.project_detail?.net_price / +res?.project_detail?.qty
            );

            let data_tax = {
              ...tax_data,
            };

            if (
              res?.project_detail?.tax_percent &&
              res?.project_detail?.tax1_percent
            ) {
              data_tax.cgst = res?.project_detail?.tax_percent;
              data_tax.sgst = res?.project_detail?.tax1_percent;
              setcgst(res?.project_detail?.tax_percent);
              setsgst(res?.project_detail?.tax1_percent);
            } else if (res?.project_detail?.tax_percent) {
              data_tax.igst = res?.project_detail?.tax_percent;
              setigst(res?.project_detail?.tax_percent);
            }

            projectCustamize(
              res?.no_of_packs,
              res?.project_detail?.qty,
              res?.project_detail?.margin_percent,
              res?.productPrice?.msp_percentage,
              res?.project_detail?.net_price,
              res?.project_detail?.retail_price,
              final_amount,
              res?.project_detail?.discount_percent,
              res?.project_detail?.extra_charges,
              data_tax
            );
          } else {
            final_amount = res?.project_detail?.final_amount;
          }

          if (res?.project_detail?.margin_percent <= 0) {
            setmargin_percent(res?.productPrice?.scp_percentage);
          } else {
            setmargin_percent(res?.project_detail?.margin_percent);
          }

          setmargin_percents(res?.productPrice?.msp_percentage);

          // -----------------------------------------------------//
          if (res?.project_detail?.extra_charges?.length > 0) {
            setAddCharges(res?.project_detail?.extra_charges);
          }

          if (res?.project_detail?.projectDetailSize?.length > 0) {
            let temp = [];
            let ids = [];
            res?.project_detail?.projectDetailSize?.map((item, ind) => {
              temp.push({
                id: item?.productSku?.id,
                project_detail_id: item?.project_detail_id,
                qty: item?.qty,
                color_id: item?.productSku?.color_id,
                status: item?.productSku?.status,
                variant_name: item?.productSku?.variant_name,
                variant_sku: item?.productSku?.variant_sku,
                project_detail_sku_id: item?.productSku?.id,
                project_detail_size_id: item?.id,
              });

              ids.push(item?.productSku?.id);
            });
            setColorsName(temp);
            setColorsID(ids);
            setColorAndSize(temp);
          }

          if (responce?.production_lead_time) {
            setproduction_lead_time(responce?.production_lead_time);
          }
          if (responce?.sample_lead_time) {
            setsample_lead_time(responce?.sample_lead_time);
          }
          if (responce?.product_note) {
            setproduct_note(responce?.product_note);
          }
          if (responce?.description) {
            setdescription(responce?.description);
          }
          if (responce?.warnings?.length > 0) {
            let warnings = [];
            responce?.warnings?.map((item, ind) => {
              warnings.push({ warn: item });
            });
            setWarnings(warnings);
          }

          if (res?.project_detail?.product_sku?.id) {
            let finderImg = res?.color_images?.find(
              (i) => i?.color_id == res?.project_detail?.product_sku?.color_id
            );
            if (finderImg?.id) {
              setSideImg(finderImg?.image_url);
              let name_variant = `${
                res?.project_detail?.product_sku?.variant_name
                  ? res?.project_detail?.product_sku?.variant_name + "-"
                  : ""
              }
              ${res?.project_detail?.product_sku?.variant_sku}`;
              setSelectedSku(name_variant);
            } else {
              setSideImg(res?.project_detail?.product?.defaultImage);
            }
          } else {
            setSideImg(res?.project_detail?.product?.defaultImage);
          }

          if (res?.project_detail?.price_status) {
            setReqBtn(res?.project_detail?.price_status);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const projectCustamize = (
    no_of_packs,
    qty,
    margin_percent,
    msp_percentage,
    net_price,
    retail_price,
    final_amount,
    discount_percent,
    extra_charges,
    tax_data
  ) => {
    let pack = +no_of_packs * +qty;
    let value = 25;
    if (pack >= 1 && pack <= 25) {
      value = 25;
    } else if (pack >= 26 && pack <= 50) {
      value = 50;
    } else if (pack >= 51 && pack <= 100) {
      value = 100;
    } else if (pack >= 101 && pack <= 150) {
      value = 150;
    } else if (pack >= 151 && pack <= 250) {
      value = 250;
    } else if (pack >= 251 && pack <= 500) {
      value = 500;
    } else if (pack >= 501 && pack <= 1000) {
      value = 1000;
    }

    let net_price_value;
    let retail_price_value;
    let final_price_value;
    let price_per_pack_value;

    if (net_price > 0) {
      net_price_value = net_price;
    } else {
      net_price_value = +value * +final_amount;
    }

    if (discount_percent > 0) {
      let dis_count = Math.round((+discount_percent * +net_price_value) / 100);
      retail_price_value = net_price_value - dis_count;
      setdiscount_percent(discount_percent);
      setdiscount_value(dis_count);
    } else {
      retail_price_value = net_price_value;
    }

    let return_values = {
      sub_total: +retail_price_value,
      total: +retail_price_value,
    };

    if (extra_charges?.length > 0) {
      extra_charges?.map((item, ind) => {
        if (item?.client_price == 1) {
          return_values.sub_total += +item?.final_amount;
          return_values.total += +item?.final_amount;
        } else if (item?.client_price == 2) {
          return_values.sub_total += +item?.final_amount;
        }
      });
    }

    if (tax_data?.product_id) {
      let sum;
      if (tax_data?.igst) {
        sum = (return_values?.sub_total * tax_data?.igst) / 100;
      } else {
        let gsts = tax_data?.cgst + tax_data?.sgst;
        sum = (return_values?.sub_total * gsts) / 100;
      }

      settax_value(sum);
      settax_sum_value(return_values?.sub_total + sum);
    }

    setquantity(qty);
    setnet_price(+net_price_value);
    settotal(return_values?.total);
    setretail_price(retail_price_value);
    setsub_total(return_values?.sub_total);
  };

  const getTax = () => {
    let params = `${locationNavi?.state?.data?.project_id}?project_detail_id=${locationNavi?.state?.data?.id}`;
    estimateTaxApi(params)
      .unwrap()
      .then((res) => {
        let temp = [];

        if (res?.taxes?.length > 0) {
          let gst_type = res?.taxes?.[0]?.gst_type;
          setgst_type(gst_type);
          res?.taxes?.map((item, ind) => {
            if (gst_type == 2) {
              if (item?.igst) {
                temp.push({
                  ...item,
                  cgst: item?.igst / 2,
                  sgst: item?.igst / 2,
                  igst: null,
                  hsn_code: item?.hsn_code,
                });
              } else {
                temp.push(item);
              }
            } else {
              if (item?.igst) {
                temp.push(item);
              } else {
                temp.push({
                  ...item,
                  cgst: null,
                  sgst: null,
                  igst: item?.cgst + item?.sgst,
                  hsn_code: item?.hsn_code,
                });
              }
            }
          });

          let finder = temp?.find(
            (i) => i?.product_id == locationNavi?.state?.data?.product?.id
          );

          if (finder?.product_id) {
            setHsnCode(finder?.hsn_code);
            if (finder?.igst) {
              setigst(finder?.igst);
            } else {
              setcgst(finder?.cgst);
              setsgst(finder?.sgst);
            }
          }
          getView(finder);
        } else {
          let finder = {};
          let gst_type = res?.gst_type;
          setgst_type(gst_type);
          if (gst_type == 2) {
            if (res?.max_igst) {
              finder.product_id = locationNavi?.state?.data?.product?.id;
              finder.cgst = res?.max_igst / 2;
              finder.sgst = res?.max_igst / 2;
              finder.igst = null;
              finder.hsn_code = res?.hsn_code;
            } else {
              finder.product_id = locationNavi?.state?.data?.product?.id;
              finder.cgst = null;
              finder.sgst = null;
              finder.igst = res?.max_cgst + res?.max_sgst;
              finder.hsn_code = res?.hsn_code;
            }
          } else {
            if (res?.max_igst) {
              finder.product_id = locationNavi?.state?.data?.product?.id;
              finder.cgst = null;
              finder.sgst = null;
              finder.igst = res?.max_igst;
              finder.hsn_code = res?.hsn_code;
            } else {
              finder.product_id = locationNavi?.state?.data?.product?.id;
              finder.cgst = null;
              finder.sgst = null;
              finder.igst = res?.max_cgst + res?.max_sgst;
              finder.hsn_code = res?.hsn_code;
            }
          }

          if (finder?.product_id) {
            setHsnCode(finder?.hsn_code);
            if (finder?.igst) {
              setigst(finder?.igst);
            } else {
              setcgst(finder?.cgst);
              setsgst(finder?.sgst);
            }
          }
          getView(finder);
        }
      })
      .catch((err) => {
        getView();
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (locationNavi?.state?.data?.id) {
      getTax();
    }
  }, []);

  const getList = (data) => {
    let temp = [];
    let tempId = [];

    if (data?.project_detail?.product_sku?.product_child_skus?.length > 0) {
      temp = [...data?.project_detail?.product_sku?.product_child_skus];
    } else if (data?.project_detail?.product_sku?.id) {
      temp.push({
        ...data?.project_detail?.product_sku,
        type: "child",
        qty: data?.project_detail?.qty,
      });
      tempId.push(data?.project_detail?.product_sku?.id);
      setColorsID(tempId);
      setColorsName(temp);
    }
    // else {
    //   let skuChild = data?.skus?.filter((i) => i?.parent_id);
    //   let skuParen = data?.skus?.find((i) => !i?.parent_id);
    //   setproduct_skuVarient(skuParen?.variant_sku);
    //   setproduct_skuVarientID(skuParen?.id);
    //   skuChild?.map((item, ind) => {
    //     temp.push(item);
    //   });
    // }
    setColorAndSize(temp);

    let obj = {
      ...data?.project_detail,
      total:
        data?.project_detail?.net_price > 0
          ? data?.project_detail?.net_price
          : data?.project_detail?.final_amount,
      subtotal:
        data?.project_detail?.net_price > 0
          ? data?.project_detail?.net_price
          : data?.project_detail?.final_amount,
    };

    setProductData(obj);
  };

  const skuSelector = (event) => {
    // TEXT FOR VALUE
    let sku_text = event?.variant_name ? event?.variant_name + "-" : "";
    sku_text = sku_text + event?.variant_sku;
    setSelectedSku(sku_text);

    // FOR PRICING TABLE
    let temp = [];
    let tempId = [];

    if (event?.parent_id) {
      temp.push({
        ...event,
        type: "child",
        qty: "",
      });
      tempId.push(event?.id);
      setColorsID(tempId);
      setColorsName(temp);
    } else {
      let finder = skus?.filter((i) => i?.parent_id == event?.id);

      finder?.map((item, ind) => {
        temp.push(item);
      });
      setColorsID([]);
      setColorsName([]);
    }

    let finder = viewData?.color_images?.find(
      (i) => i?.color_id == event?.color_id
    );

    if (finder?.id) {
      setSideImg(finder?.image_url);
    }
    setColorAndSize(temp);
    setproduct_skuVarient(event?.variant_sku);
    setproduct_skuVarientID(event?.id);
    estimateUpdateSkuSelect(event?.id);
  };

  const submitHandleInfo = () => {
    if (
      production_lead_time?.length == 0 ||
      sample_lead_time?.length == 0
      // ||
      // product_note?.length == 0 ||
      // description?.length == 0
    ) {
      setproduct_noteErr(true);
      setproduction_lead_timeErr(true);
      // setsample_lead_timeErr(true);
      // setdescriptionErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_detail_id", locationNavi?.state?.data?.id);
      formdata.append("production_lead_time", production_lead_time);
      formdata.append("sample_lead_time", sample_lead_time);

      if (product_note) {
        formdata.append("product_note", product_note);
      }

      if (description) {
        formdata.append("description", description);
      }

      if (warnings?.length > 0) {
        warnings?.map((item, ind) => {
          formdata.append(`warnings[${ind}]`, item?.warn);
        });
      }

      dispatch(saveLoader(true));
      setBtn(true);
      presentationInfoAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            toast.success(res?.message);
            getView();
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const artWorkHandler = (art, item, index, aind) => {
    if (art?.decorative_method_id?.id) {
      setdecorative_method_id(art?.decorative_method_id?.id);
      setdecorative_method_name(art?.decorative_method_id?.name);
    }
    if (art?.proof_required) {
      setproof_required(art?.proof_required);
    }

    if (art?.logo_name) {
      setlogo_name(art?.logo_name);
    }

    if (art?.uom) {
      setuom(+art?.uom);
    }

    if (art?.logo_length) {
      setlogo_length(art?.logo_length);
    }

    if (art?.logo_breadth) {
      setlogo_breadth(art?.logo_breadth);
    }

    if (art?.logo_color) {
      setlogo_color(art?.logo_color);
    }

    if (art?.repeat_logo) {
      setrepeat_logo(art?.repeat_logo);
    }

    if (art?.supplier_note) {
      setsupplier_note(art?.supplier_note);
    }

    if (art?.location) {
      setlocation2(art?.location);
    } else {
      setlocation2(aind + 1);
    }

    if (art?.image_url) {
      setfile(art?.image_url);
    }

    if (art?.design_status) {
      setdesign_status(art?.design_status);
    }

    if (art?.sale_status) {
      setsale_status(art?.sale_status);
    }

    if (art?.id) {
      setArtWorkDatail(art);
    }

    setdecorative_methodList(item?.product?.productDecorations);
    setDecorativeMethodList(item?.product?.productDecorations);
    setArtWorkData(item);
    setImageUpload(true);
  };

  const toggleShowClosePopup = () => {
    setdecorative_method_id("");
    setdecorative_method_name("");
    setproof_required("");
    setlogo_name("");
    setuom("");
    setlogo_length("");
    setlogo_breadth("");
    setlogo_color("");
    setrepeat_logo("");
    setsupplier_note("");
    setlocation2("");
    setfile("");
    setdecorative_method_idErr(false);
    setproof_requiredErr(false);
    setlogo_nameErr(false);
    setuomErr(false);
    setlogo_lengthErr(false);
    setlogo_breadthErr(false);
    setlogo_colorErr(false);
    setrepeat_logoErr(false);
    setsupplier_noteErr(false);
    setlocationErr(false);
    setfileErr(false);
    setImageUpload(false);
    setArtWorkData({});
    setArtWorkDatail({});
    setdesign_status("");
    setsale_status("");
  };

  const handleStatusArt = (status, type) => {
    dispatch(saveLoader(true));
    let formdata = new FormData();

    formdata.append("artwork_id", artworkDatail?.id);

    if (type == 1) {
      formdata.append("sale_status", status);
      setsale_status(status);
    }

    if (type == 2) {
      formdata.append("design_status", status);
      setdesign_status(status);
    }

    artworkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        getView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const estimateUpdate = () => {
    let formdata = new FormData();
    formdata.append("project_id", projectData?.id);
    formdata.append("project_detail_id", locationNavi?.state?.data?.id);
    formdata.append("margin_percent", margin_percent);
    formdata.append("net_price", net_price);
    if (discount_percent) {
      formdata.append("discount_percent", discount_percent);
      formdata.append("discount_amount", discount_value);
    } else {
      formdata.append("discount_percent", 0);
      formdata.append("discount_amount", 0);
    }
    formdata.append("retail_price", sub_total);
    formdata.append("qty", quantity);
    formdata.append("msp_percent", productData?.msp_percent);
    formdata.append("product_sku_id", product_skuVarientID);

    // if (projectData?.tax_type == 1) {
    let tex_percen;
    if (igst) {
      tex_percen = igst;
      formdata.append("tax_percent", igst);
      formdata.append("tax1_percent", 0);
    } else {
      tex_percen = +cgst + +sgst;
      formdata.append("tax_percent", +cgst);
      formdata.append("tax1_percent", +sgst);
    }

    if (hsnCode) {
      formdata.append("hsn_code", hsnCode);
    }

    if (tex_percen) {
      let tex = Number((+sub_total * +tex_percen) / 100).toFixed(2);
      let final = Number(+tex + +sub_total).toFixed(2);
      formdata.append("final_amount", +final);
      formdata.append("tax_amount", +tex);
    }

    // formdata.append("final_amount", tax_sum_value);
    // formdata.append("tax_amount", tax_value);

    AddCharges?.map((item, ind) => {
      formdata.append(`extra_charges[${ind}][name]`, item?.name);
      formdata.append(
        `extra_charges[${ind}][client_price]`,
        item?.client_price
      );
      formdata.append(`extra_charges[${ind}][margin]`, item?.margin);
      formdata.append(`extra_charges[${ind}][net_price]`, item?.net_price);
      formdata.append(
        `extra_charges[${ind}][final_amount]`,
        item?.final_amount
      );
    });

    formdata.append("price_status", reqBtn);

    dispatch(saveLoader(true));
    setBtn(true);
    estimateEditApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setBtn(false);
        getView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
  };

  const estimateUpdateSkuSelect = (sku_id) => {
    let formdata = new FormData();
    formdata.append("project_id", projectData?.id);
    formdata.append("project_detail_id", locationNavi?.state?.data?.id);
    formdata.append("margin_percent", margin_percent);
    formdata.append("net_price", net_price);
    formdata.append("qty", quantity);
    formdata.append("msp_percent", productData?.msp_percent);
    formdata.append("product_sku_id", sku_id);
    if (hsnCode) {
      formdata.append("hsn_code", hsnCode);
    }

    dispatch(saveLoader(true));
    setBtn(true);
    estimateEditApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setBtn(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
  };

  const qtyUpdate = (item) => {
    if (item?.qty) {
      let formdata = new FormData();
      formdata.append("project_detail_id", locationNavi?.state?.data?.id);
      formdata.append("qty", item?.qty);
      formdata.append("total_qty", quantity);
      dispatch(saveLoader(true));
      setBtn(true);
      if (item?.project_detail_size_id) {
        setproduct_skuVarientID(item?.project_detail_sku_id);
        formdata.append("product_sku_id", item?.project_detail_sku_id);
        estimateQtyUpdateApi({
          payload: formdata,
          id: item?.project_detail_size_id,
        })
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            // getView();
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        formdata.append("product_sku_id", item?.id);
        estimateQtyApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            // getView();
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const taxAddClick = () => {
    let temp = [...taxAdd];
    temp.push({ warn: "" });
    setTaxAdd(temp);
  };

  const taxRemove = (e, ind) => {
    let temp = [...taxAdd];
    temp.splice(ind, 1);
    setTaxAdd(temp);
  };

  const poppupAddressToggle = () => {
    setCheckPricePoppup(!checkPricePoppup);
  };
  const poppupImageToggle = () => {
    setImageUpload(!imageUpload);
  };
  const poppupImageView = () => {
    setImageViewPoppup(!imageViewPoppup);
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };
  const poppupDataToggle = () => {
    setImageUploadData(!imageUploadData);
  };

  const searchHSN = (event) => {
    let formdata = new FormData();
    formdata.append("search", event);
    if (event?.length > 0) {
      hsnSearchListApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            let hsn = res?.hsn;
            if (hsn?.id) {
              if (gst_type == 1) {
                if (hsn?.igst) {
                  setigst(hsn?.igst);
                } else {
                  setigst(hsn?.sgst + hsn?.cgst);
                }
              } else if (gst_type == 2) {
                if (hsn?.igst) {
                  setsgst(hsn?.igst / 2);
                  setcgst(hsn?.igst / 2);
                } else {
                  setsgst(hsn?.sgst);
                  setcgst(hsn?.cgst);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    setHsnCode(event);
  };

  const gstCalculation = (igst_value, cgst_value, sgst_value) => {
    let tex_percen;

    if (gst_type == 1) {
      tex_percen = igst_value;
      setigst(igst_value);
    } else if (gst_type == 2) {
      tex_percen = +cgst_value + +sgst_value;
      setcgst(cgst_value);
      setsgst(sgst_value);
    }

    if (tex_percen) {
      let tex = Number((+sub_total * +tex_percen) / 100).toFixed(2);
      let final = Number(+tex + +sub_total).toFixed(2);
      settax_sum_value(+final);
      settax_value(+tex);
    } else {
      settax_sum_value(+sub_total);
    }
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="dashRightView overview_pages edit_page_cont overflow-scroll ">
      {/* <NavbarHead /> */}
      {checkPricePoppup && (
        <CheckingPricePoppup poppupAddressToggle={poppupAddressToggle} />
      )}
      {/* {imageUpload && (
        <ImageUpload
          toggleImagePopup={toggleImagePopup}
          poppupImageToggle={poppupImageToggle}
        />
      )} */}

      {imageUpload && (
        <AddArtWorker
          toggleShowPopup={toggleShowClosePopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
          statusOption={true}
          decorative_method_id={decorative_method_id}
          setdecorative_method_id={setdecorative_method_id}
          decorative_method_name={decorative_method_name}
          setdecorative_method_name={setdecorative_method_name}
          proof_required={proof_required}
          setproof_required={setproof_required}
          logo_name={logo_name}
          setlogo_name={setlogo_name}
          uom={uom}
          setuom={setuom}
          logo_length={logo_length}
          setlogo_length={setlogo_length}
          logo_breadth={logo_breadth}
          setlogo_breadth={setlogo_breadth}
          logo_color={logo_color}
          setlogo_color={setlogo_color}
          repeat_logo={repeat_logo}
          setrepeat_logo={setrepeat_logo}
          supplier_note={supplier_note}
          setsupplier_note={setsupplier_note}
          location={location2}
          setlocation={setlocation2}
          file={file}
          setfile={setfile}
          decorative_method_idErr={decorative_method_idErr}
          proof_requiredErr={proof_requiredErr}
          logo_nameErr={logo_nameErr}
          uomErr={uomErr}
          logo_lengthErr={logo_lengthErr}
          logo_breadthErr={logo_breadthErr}
          logo_colorErr={logo_colorErr}
          repeat_logoErr={repeat_logoErr}
          supplier_noteErr={supplier_noteErr}
          locationErr={locationErr}
          fileErr={fileErr}
          decorative_methodList={decorative_methodList}
          decorativeMethodList={decorativeMethodList}
          setDecorativeMethodList={setDecorativeMethodList}
          artworkDatail={artworkDatail}
          design_status={design_status}
          sale_status={sale_status}
          handleStatusArt={handleStatusArt}
          btn_hide={"hide"}
          hide_content={true}
        />
      )}
      {imageUploadData && (
        <ArtWorkerData
          poppupDataToggle={poppupDataToggle}
          poppupImageToggle={poppupImageToggle}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          setImageUploadData={setImageUploadData}
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
          imageUploadData={imageUploadData}
        />
      )}
      {/* {imageViewPoppup && <ImageUpload poppupImageView={poppupImageView} />} */}
      {/* {imageViewPoppup && (
        <AddFilesPopupCopy
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )} */}
      {/* {AddFilesOverview && (
        <AddFilesPopupCopy
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
          toggleImagePopup={toggleImagePopup}
        />
      )} */}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}

      <div className="w-100 d-flex ac-jb">
        <div>
          <p className="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23">
            Edit Item
          </p>
        </div>
        <div className="position-fixed close_position z-3">
          <button
            onClick={() => navigate(-1)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Close
          </button>
        </div>
      </div>
      <EstimateEditComp
        toggleShowPopups={toggleShowPopups}
        // poppupImageView={poppupImageView}
        productData={productData}
        viewSupplier={viewSupplier}
        skus={skus}
        decorative_methods={decorative_methods}
        setproduction_lead_time={setproduction_lead_time}
        submitHandleInfo={submitHandleInfo}
        btns={btns}
        descriptionErr={descriptionErr}
        description={description}
        setdescription={setdescription}
        product_noteErr={product_noteErr}
        production_lead_time={production_lead_time}
        production_lead_timeErr={production_lead_timeErr}
        setsample_lead_time={setsample_lead_time}
        sample_lead_time={sample_lead_time}
        setproduct_note={setproduct_note}
        product_note={product_note}
        sample_lead_timeErr={sample_lead_timeErr}
        setWarnings={setWarnings}
        warnings={warnings}
        skuSelector={skuSelector}
        selectedSku={selectedSku}
        sideImg={sideImg}
        setSideImg={setSideImg}
      />
      <EstimateEditTableComp
        poppupAddressToggle={poppupAddressToggle}
        poppupImageToggle={poppupImageToggle}
        location={location}
        setLocation={setLocation}
        setProductData={setProductData}
        productData={productData}
        artWorkHandler={artWorkHandler}
        viewData={viewData}
        discount_percent={discount_percent}
        setdiscount_percent={setdiscount_percent}
        discount_value={discount_value}
        setdiscount_value={setdiscount_value}
        net_price={net_price}
        setnet_price={setnet_price}
        sub_total={sub_total}
        setsub_total={setsub_total}
        total={total}
        settotal={settotal}
        tax_value={tax_value}
        settax_value={settax_value}
        tax_sum_value={tax_sum_value}
        settax_sum_value={settax_sum_value}
        margin_percent={margin_percent}
        setmargin_percent={setmargin_percent}
        margin_percents={margin_percents}
        igst={igst}
        cgst={cgst}
        sgst={sgst}
        quantity={quantity}
        setquantity={setquantity}
        estimateUpdate={estimateUpdate}
        btns={btns}
        retail_price={retail_price}
        setretail_price={setretail_price}
        AddCharges={AddCharges}
        setAddCharges={setAddCharges}
        qtyUpdate={qtyUpdate}
        colorsName={colorsName}
        setColorsName={setColorsName}
        colorsID={colorsID}
        setColorsID={setColorsID}
        colorAndSize={colorAndSize}
        setColorAndSize={setColorAndSize}
        productPriceData={productPriceData}
        product_skuVarient={product_skuVarient}
        price_per_pack={price_per_pack}
        setprice_per_pack={setprice_per_pack}
        reqBtn={reqBtn}
        setReqBtn={setReqBtn}
        discountType={discountType}
      />
      <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100 mt-4">
        <div className="summary bg-soft-gray1 w-35 md_t_W">
          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <p className="">Margin</p>
            <p className="">{margin_percent ? "₹" + margin_percent : "-"}</p>
          </div>
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Margin Amount</p>
            <p className="">{net_price ? "₹" + commaValue(net_price) : "-"}</p>
          </div>
        </div>
        <div className="summary bg-soft-gray1 w-45 md_t_W ">
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">
              Discount {discount_percent ? `(${discount_percent})` : ""}%
            </p>
            <p className="">
              {discount_value ? "₹" + commaValue(discount_value) : "-"}
            </p>
          </div>
          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <p className="">
              {/* {projectData?.tax_type == 1 ? "Subtotal" : "Total"} */}
              Subtotal
            </p>
            <p className="">{sub_total ? "₹" + commaValue(sub_total) : "-"}</p>
          </div>

          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <div>
              <p className="">HSN Code</p>
              <input
                value={hsnCode}
                onChange={(e) => searchHSN(e.target.value)}
                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>

            <p className=""></p>
          </div>

          {/* {projectData?.tax_type == 1 && ( */}
          <>
            {" "}
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Tax</p>
            </div>
            <div className="px-1 px-md-3">
              {igst ? (
                <div className="d-flex ac-jb px-1 ">
                  <div>
                    <p className="">IGST %</p>
                    <input
                      type={"number"}
                      value={igst}
                      onChange={(e) => gstCalculation(e.target.value, "", "")}
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                  </div>
                  <p className="">
                    {tax_value ? "₹" + commaValue(tax_value) : "0"}
                  </p>
                </div>
              ) : (
                <>
                  <div className="d-flex ac-jb">
                    <div>
                      <p className="">CGST % </p>
                      <input
                        type={"number"}
                        value={cgst}
                        onChange={(e) =>
                          gstCalculation("", e.target.value, sgst)
                        }
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                    <p className="">
                      {tax_value ? "₹" + commaValue(tax_value) / 2 : "0"}
                    </p>
                  </div>
                  <div className="d-flex ac-jb mt-2">
                    <div>
                      <p className="">SGST % </p>
                      <input
                        type={"number"}
                        value={sgst}
                        onChange={(e) =>
                          gstCalculation("", cgst, e.target.value)
                        }
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                    </div>
                    <p className="">
                      {tax_value ? "₹" + commaValue(tax_value) / 2 : "0"}
                    </p>
                  </div>
                </>
              )}
            </div>
            {/* <div className="d-flex ac-jb px-1 px-md-3">
              <div className=" d-flex ac-jc position-relative">
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    readOnly
                    onClick={() => setDropDown(8)}
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => setDropDown(8)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  {dropDown === 8 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 8 && "submenu_1 h-auto"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    {igst ? (
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          onClick={() => setDropDown(undefined)}
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          IGST ({igst}%)
                        </button>
                      </button>
                    ) : (
                      <>
                        <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                          <button
                            onClick={() => setDropDown(undefined)}
                            className="px-2 cust-btn text-start py-1 w-100 "
                          >
                            CGST ({cgst}%)
                          </button>
                        </button>
                        <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                          <button
                            onClick={() => setDropDown(undefined)}
                            className="px-2 cust-btn text-start py-1 w-100 "
                          >
                            SGST ({sgst}%)
                          </button>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <p className="">{tax_value ? "₹" + tax_value : "-"}</p>
            </div> */}
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Total</p>
              <p className="">
                {tax_sum_value ? "₹" + commaValue(tax_sum_value) : "-"}
              </p>
            </div>
          </>
          {/* )} */}

          {/* <div className="d-flex ac-jb px-1 px-md-3 py-3">
                        <div className="d-flex gap-2">
                            <input type="checkbox" />
                            <p className="">Hide Totals</p>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="mt-4 d-flex ac-je">
        <button
          class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            btns && "opacity-50"
          }`}
          onClick={() => estimateUpdate()}
          disabled={btns ? true : false}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default EstimateEditItemScreen;
