import { PersonSearchTwoTone, Search } from "@mui/icons-material";
import React, { useState } from "react";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import MarkUnreadChatAltRoundedIcon from "@mui/icons-material/MarkUnreadChatAltRounded";
import { useLocation } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSearchParams } from "react-router-dom";
import Steper from "../Popup/Steper";

const NavbarHead = ({ onLinkShow, type, projectData }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const path = location?.pathname;
  // console.log(path);

  const [dropDown, setDropDown] = useState(undefined);

  const CHECKOUT_STEPS = [
    {
      name: "Overview",
      Component: () => <div>Provide your contact details.</div>,
    },
    {
      name: "Collaborate",
      Component: () => <div>Enter your shipping address.</div>,
    },
    {
      name: "Presentation",
      Component: () => <div>Complete payment for your order.</div>,
    },
    {
      name: "Estimate",
      Component: () => <div> Your order has been delivered.</div>,
    },
    {
      name: "Production Artwork",
      Component: () => <div>Enter your shipping address.</div>,
    },
    {
      name: "Sales Order",
      Component: () => <div>Complete payment for your order.</div>,
    },
  ];

  return (
    <div className="d-flex position-fixed z-3">
      <div className="top_nav d-flex as-jb  overflow-scroll px-4">
        <div className="w-100">
          <div className="d-flex searchField my-3 p-2">
            <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
            <input
              className="cust-btn ms-4 w-100"
              placeholder="Enter Project Name, Client Name, PO# to search"
            />
            <img src={manImage} className="searchManImg" />
          </div>
          <p className=" mb-3 text-nowrap text-dark f3 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 w-100">
            {/* <span className="mb-3  primary1 ">5SK—</span> */}
            {path === "/estimate" && (
              <>
                Project {projectData?.project_code}
                {projectData?.estimation_code
                  ? " - Estimate " + projectData?.estimation_code
                  : ""}
                <Steper
                  stepsConfig={CHECKOUT_STEPS}
                  projectData={projectData}
                />
              </>
            )}
            {path === "/sales-order" && (
              <>
                Project #210224 Client Portal - New Project — Sales Order
                <Steper stepsConfig={CHECKOUT_STEPS} />
              </>
            )}
            {path === "/upload-artwork-inprocess" && (
              <>
                Project #210224 Client Portal - New Project — Sales Order
                <div
                  style={{ borderRadius: "50px", height: "10px" }}
                  className={`${"sales-order"} w-${68} mt-2`}
                ></div>
              </>
            )}
            {path === "/prodection-artwork" && (
              <>
                Project #210224 Client Portal - New Project — Sales Order
                <Steper stepsConfig={CHECKOUT_STEPS} />
              </>
            )}
            {path === "/overview" && (
              <>
                Project {projectData?.project_code} Task 1{" "}
                <Steper
                  stepsConfig={CHECKOUT_STEPS}
                  projectData={projectData}
                />
              </>
            )}
            {path === "/collaborate" && (
              <>
                Project #210224 Client Portal - New Project — Collaborate{" "}
                {/* <div
                  style={{ borderRadius: "50px", height: "10px" }}
                  className={`${"in-production"} w-${35} mt-2`}
                ></div> */}
                <Steper stepsConfig={CHECKOUT_STEPS} />
              </>
            )}
            {/* {path === "/presentation" &&
              `${projectData?.project_code} Task 1 
              — Presentation #${projectData?.project_code}
              `} */}
            {path === "/presentation" &&
              `${
                projectData?.project_code
                  ? "Project " + projectData?.project_code
                  : ""
              }${
                projectData?.presentation_code
                  ? " - Presentation " + projectData?.presentation_code
                  : ""
              } `}

            {path === "/presentation" && (
              <Steper stepsConfig={CHECKOUT_STEPS} projectData={projectData} />
            )}
            {type == "invoice" &&
              " Project #210234 Sankar cement — Invoice #220394"}
            {type == "invoice" && (
              <div
                style={{ borderRadius: "50px", height: "10px" }}
                className={`${"sales-order"} w-${45} mt-2`}
              ></div>
            )}
          </p>
        </div>
        <div className="my-3 d-flex w-50 ac-je">
          <div>
            <div className="d-flex">
              <button className="cust-btn btn_chat d-flex ac-jb">
                <MarkUnreadChatAltRoundedIcon className="primary f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                <p className="text-dark f2 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ms-2">
                  Chat
                </p>
              </button>
              <button className="cust-btn btn_chat d-flex ac-jb">
                <MarkUnreadChatAltRoundedIcon className="primary f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                <p className="text-dark f2 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ms-2">
                  Chat
                </p>
              </button>
              <button className="cust-btn btn_chat d-flex ac-jb">
                <MarkUnreadChatAltRoundedIcon className="primary f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                <p className="text-dark f2 fs-15 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ms-2">
                  Chat
                </p>
              </button>
            </div>
            <div className="d-flex ac-jc">
              {path != "/upload-artwork-inprocess" && (
                <button
                  onClick={onLinkShow}
                  class="position-relative  cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  Action
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarHead;
