import { useLocation, useNavigate } from "react-router-dom";
import { toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { Form, Pagination } from "react-bootstrap";

import { customerList } from "../../redux/api/DummyJson";
import { Opacity, Search } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { searchIcon } from "../../assets/img";
import {
  useAdminExportsMutation,
  useCustomerListMutation,
  useLazyCustomerListStatusQuery,
} from "../../redux/api/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const RegisteredCustomerList = ({ tabData }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [types, setTypes] = useState("");
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);

  // RTK QUERY
  const [customerListApi] = useCustomerListMutation();
  const [exportsApi] = useAdminExportsMutation();
  const [status] = useLazyCustomerListStatusQuery();

  // CUSTOMER LIST
  const getCustomerList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    customerListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setList(res?.lists);
        setLists(res?.lists);
        setPagination(res?.pagination_meta);
        console.log("ressss", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCustomerList();

    setTypes(location?.state?.type);
  }, []);

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 11);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // CHANGE STATUS
  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getCustomerList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getCustomerList();
  }, [page]);

  // const getView = () => {
  //   viewTestimonialApi(location?.state?.data?.id)
  //     .unwrap()
  //     .then((res) => {
  //       let data = res?.testimonial;
  //       setFullName(data?.name);
  //       setDeptSpecialist(data?.title);
  //       setDescription(data?.description);
  //       setLogo_Img(data?.icon_url);
  //       setClient_Img(data?.image_url);
  //       setPriority(data?.priority);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // useEffect(() => {
  //   if (location?.state?.data?.id) {
  //     getView();
  //   }
  // }, []);

  return (
    <>
      {/* <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Registered Customer List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div> */}
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getCustomerList(searchValue, e.target.value)}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-je flex-md-row flex-column w-100 ">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-3">
            Total Count : {pagination?.total}
          </p>
          <div
            className="pointerView d-flex mt-sm-3 mt-0 me-3"
            role={"button"}
            onClick={getAdminExports}
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search border">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getCustomerList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          {/* <button
            // onClick={() => toggleShowPopup("add")}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add E-Commerce List
          </button> */}
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-border" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  E-Commerce ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  First Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  Last Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  Email
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr className="border-bottom w-100">
                  <td>
                    <p
                      className="pointerView    f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.ecommerce_id}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.first_name}
                    </p>
                  </td>
                  <td>
                    <p
                      className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1"
                      onClick={() => tabData(item)}
                    >
                      {item?.last_name}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.email}
                    </p>
                  </td>
                  <td>
                    <div
                      className=" d-flex ac-jc"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 1 ? (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                      {/* <Form.Check
                type="switch"
                id="toggle-switch"
                label=""
                checked={item?.status === true ? true : false}
              /> */}
                    </div>
                  </td>
                  <td>
                    <div className=" d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          // navigate("/view-customer-details", { state: { data: item } })
                          tabData(item)
                        }
                        className="viewBoxDes pointerView p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      {/* <div className="viewBoxDes ms-2 p-2 bg-white rounded-2">
                <img src={editIcon} className="viewDes" />
              </div> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagination?.last_page}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default RegisteredCustomerList;
