import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useProjectStatusChangeMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment/moment";

const OverViewLostPopup = ({ toggleShowPopup, projectData }) => {
  const dispatch = useDispatch();
  // RTK QUERY
  const [statusChangeAPI] = useProjectStatusChangeMutation();

  const [reject_date, setreject_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [reject_dateErr, setreject_dateErr] = useState(false);
  const [reject_reason, setreject_reason] = useState("");
  const [reject_reasonErr, setreject_reasonErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const submitHandle = () => {
    if (reject_date?.length == 0 || reject_reason?.length == 0) {
      setreject_dateErr(true);
      setreject_reasonErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", projectData?.id);
      formdata.append("status", 0);
      formdata.append("reject_date", reject_date);
      formdata.append("reject_reason", reject_reason);

      dispatch(saveLoader(true));
      setBtn(true);
      statusChangeAPI(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            setreject_reason("");
            setreject_reasonErr(false);
            setreject_dateErr(false);
            setreject_date(moment(new Date()).format("YYYY-MM-DD"));
            toggleShowPopup();
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const onClose = () => {
    setreject_date(moment(new Date()).format("YYYY-MM-DD"));
    setreject_dateErr(false);
    setreject_reason("");
    setreject_reasonErr(false);
    toggleShowPopup();
  };

  return (
    <div className="add-category-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Lost Details
          <span>
            <button onClick={() => onClose()} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        <div className="d-flex flex-column ac-jc add-content">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Lost Date
            </p>
            <div className="w-100  w-xs-100 mt-xs-5 position-relative">
              <input
                placeholder=""
                className="w-80  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                name=""
                type={"date"}
                onChange={(e) => setreject_date(e.target.value)}
                value={reject_date}
              />
              {reject_date?.length == 0 && reject_dateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Lost Date</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Lost Reason
            </p>
            <div className="w-100  w-xs-100 mt-xs-5 position-relative">
              <textarea
                placeholder=""
                className="w-80  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                name=""
                rows={4}
                onChange={(e) => setreject_reason(e.target.value)}
                value={reject_reason}
              />
              {reject_reason?.length == 0 && reject_reasonErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Lost Reason</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex mt-3 justify-content-end m-2">
          <button
            onClick={() => submitHandle()}
            className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn ? "opacity-50" : ""
            }`}
            disabled={btn}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverViewLostPopup;
