import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import { MenuBookOutlined } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SideBarComp = ({
  setSaidList,
  sideList,
  roles,
  setRoles,
  ourPacks,
  setOurPacks,
  enableDown,
  setEnableDown,
  prodManagement,
  setProdManagement,
  customerDown,
  setCustomerDown,
  adminRequest,
  setAdminRequst,
  siteManagement,
  setSiteManagement,
  production,
  setProduction,
  projectAttribute,
  setProjectAttribute,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [helpDeskDown, setHelpDeskDown] = useState(false);
  const [enterprises, setEnterprises] = useState(false);
  const [attributes, setAttributes] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [manageOrder, setManageOrder] = useState(false);
  const [ecommerce, setEcommerce] = useState(false);
  const [projectManagement, setProjectManagement] = useState(false);

  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);
  return (
    <div className="nav_sc">
      <div
        onClick={() => {
          navigate("/home");
          setSaidList(true);
        }}
        className={`${
          splitLocation[1] === "home" ? "bg-soft-gray1" : ""
        } d-flex w-100 flex-md-row flex-column py-3 px-3 hover_eff cp`}
      >
        <DashboardIcon
          className={` ${
            splitLocation[1] === "home" ? "black" : "gray"
          } f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
        />
        {sideList && (
          <p
            className={`wordsDes text-nowrap w-100 ${
              splitLocation[1] === "home" ? "black" : "gray"
            } f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Dashboard
          </p>
        )}
      </div>
      <div
        onClick={() => {
          navigate("/profile");
          setSaidList(true);
        }}
        className={`d-flex px-3 flex-md-row flex-column py-3 w-100 hover_eff cp cp ${
          splitLocation[1] === "profile" ||
          splitLocation[1] === "add-profile" ||
          splitLocation[1] === "view-profile" ||
          splitLocation[1] === "edit-profile"
            ? "bg-soft-gray1"
            : ""
        }`}
      >
        <PersonIcon
          className={` ${
            splitLocation[1] === "profile" ||
            splitLocation[1] === "add-profile" ||
            splitLocation[1] === "view-profile" ||
            splitLocation[1] === "edit-profile"
              ? "black"
              : "gray"
          } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
        />
        {sideList && (
          <p
            className={`${
              splitLocation[1] === "profile" ||
              splitLocation[1] === "add-profile" ||
              splitLocation[1] === "view-profile" ||
              splitLocation[1] === "edit-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Profile
          </p>
        )}
      </div>
      <div
        onClick={() => {
          setRoles(!roles);
          setEnableDown(false);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setAdminRequst(false);
          setOurPacks(false);
          setSaidList(true);
        }}
        className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
          splitLocation[1] === "role-profile" ||
          splitLocation[1] === "add-role-profile" ||
          splitLocation[1] === "view-role-profile" ||
          splitLocation[1] === "edit-role-profile"
            ? "bg-soft-gray1"
            : ""
        }`}
      >
        <PermContactCalendarIcon
          className={`${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "black"
              : "gray"
          } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
        />
        {sideList && (
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Department/ Role
          </p>
        )}
      </div>
      {roles && (
        <div
          onClick={() => {
            navigate("/cateogry-roles");
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 invisible fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Category role
          </p>
        </div>
      )}
      {roles && (
        <div
          onClick={() => {
            navigate("/role-profile");
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 invisible fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Roles
          </p>
        </div>
      )}

      <div
        onClick={() => {
          // navigate("/preset-packs");
          // setNavState("navlink");
          setEnableDown(false);
          setOurPacks(!ourPacks);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setRoles(false);
          setAdminRequst(false);
          setSaidList(true);
        }}
        className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
          splitLocation[1] === "preset-packs" ||
          splitLocation[1] === "add-role-profile" ||
          splitLocation[1] === "view-role-profile" ||
          splitLocation[1] === "edit-role-profile"
            ? "bg-soft-gray1"
            : ""
        }`}
      >
        <LocalGroceryStoreIcon
          className={`${
            splitLocation[1] === "preset-packs" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "black"
              : "gray"
          } f1 fs-xs-18 fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
        />
        {sideList && (
          <p
            className={`${
              splitLocation[1] === "our-packs" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Our Packs
          </p>
        )}
      </div>
      {ourPacks && (
        <div
          onClick={() => {
            navigate("/event-category");
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 invisible fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Event Category
          </p>
        </div>
      )}
      {/* {ourPacks && (
        <div
          onClick={() => {
            navigate("/event-categories");
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 invisible fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Event Categories
          </p>
        </div>
      )} */}
      {ourPacks && (
        <div
          onClick={() => {
            navigate("/preset-packs");
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "role-profile" ||
            splitLocation[1] === "add-role-profile" ||
            splitLocation[1] === "view-role-profile" ||
            splitLocation[1] === "edit-role-profile"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <PermContactCalendarIcon
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } f1 fs-xs-18 invisible fs-sm-20 ac-jc fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          />
          <p
            className={`${
              splitLocation[1] === "role-profile" ||
              splitLocation[1] === "add-role-profile" ||
              splitLocation[1] === "view-role-profile" ||
              splitLocation[1] === "edit-role-profile"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 f2 ms-md-2 ms-lg-4 ms-xl-4 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-100`}
          >
            Preset Packs
          </p>
        </div>
      )}
      <div
        onClick={() => {
          setEnableDown(!enableDown);
          setHelpDeskDown(false);
          // navigate("/vendor-home");
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setOurPacks(false);
          setRoles(false);
          setAdminRequst(false);
          setSaidList(true);
        }}
        className="hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <SupervisorAccountIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Vendor Management
          </p>
        )}
      </div>
      {enableDown && (
        <div>
          <div
            onClick={() => {
              navigate("/vendor-home");
              setSaidList(true);
            }}
            className={`${
              splitLocation[1] === "vendor-home" ||
              splitLocation[1] === "add-vendor"
                ? "bg-soft-gray1"
                : ""
            } d-flex hover_eff cp flex-md-row px-3 flex-column py-3 w-100`}
          >
            <p
              className={`${
                splitLocation[1] === "vendor-home" ||
                splitLocation[1] === "add-vendor"
                  ? "black"
                  : "gray"
              } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Dashboard
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/vendor-management");
              setSaidList(true);
            }}
            className={`${
              splitLocation[1] === "vendor-management" ||
              splitLocation[1] === "vendor-details"
                ? "bg-soft-gray1"
                : ""
            } hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100`}
          >
            <p
              className={`
              ${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "black"
                  : "gray"
              } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Manage Vendor
            </p>
          </div>
          {/* <div
              onClick={() => navigate("/products")}
              className={`${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "bg-soft-gray1"
                  : ""
              } d-flex flex-md-row px-3 flex-column py-3 w-100`}
            >
              <p
                className={`
              ${
                splitLocation[1] === "vendor-management" ||
                splitLocation[1] === "vendor-details"
                  ? "black"
                  : "gray"
              } ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
              >
                Vendor Management
              </p>
            </div> */}
          <div
            onClick={() => {
              navigate("/vendor-settings");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "vendor-settings" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 ${
                splitLocation[1] === "vendor-settings" ? "black" : "gray"
              }`}
            >
              Vendor Settings
            </p>
          </div>
          {/* <div className="d-flex flex-md-row px-3 flex-column py-3 w-100">
              <p className=" ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Production Dashboard
              </p>
            </div> */}
        </div>
      )}
      <div
        onClick={() => {
          setCustomerDown(false);
          setProdManagement(!prodManagement);
          setEnableDown(false);
          setHelpDeskDown(false);
          setSaidList(true);
          // navigate("/product-feed");
        }}
        className="hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <WarehouseIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Product Management
          </p>
        )}
      </div>
      {prodManagement && (
        <div>
          <div
            onClick={() => {
              navigate("/product-feed");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "product-feed" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "product-feed" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Feed
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/product-library");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "product-library" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "product-library" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Product Library
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/price-book-library");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "/price-book-library" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "/price-book-library" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Pricebook Library
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/pre-production");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "pre-production" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "pre-production" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Pre Production
            </p>
          </div>
          <div
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "category-setting" ? "bg-soft-gray1" : ""
            }`}
            onClick={() => {
              navigate("/category-setting");
              setSaidList(true);
            }}
          >
            <p
              className={`${
                splitLocation[1] === "category-setting" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Category Setting
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/navigation");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "Navigation" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "Navigation" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Navigation
            </p>
          </div>
          <div
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "decorative-method" ? "bg-soft-gray1" : ""
            }`}
            onClick={() => {
              navigate("/decorative-method");
              setSaidList(true);
            }}
          >
            <p
              className={`${
                splitLocation[1] === "decorative-method" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Decoration Method
            </p>
          </div>
          <div
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "hsn-settings" ? "bg-soft-gray1" : ""
            }`}
            onClick={() => {
              navigate("/hsn-settings");
              setSaidList(true);
            }}
          >
            <p
              className={`${
                splitLocation[1] === "hsn-settings" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              HSN Settings
            </p>
          </div>
          <div
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "attributes" ? "bg-soft-gray1" : ""
            }`}
            onClick={() => {
              navigate("/attributes");
              setSaidList(true);
            }}
          >
            <p
              className={`${
                splitLocation[1] === "attributes" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Attributes
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/tags");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "Tags" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "Tags" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Tags
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/event-tags");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "Tags" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "Tags" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Event Tags
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/icon-tags");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "Tags" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "Tags" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Icon Tags
            </p>
          </div>
        </div>
      )}

      <div
        onClick={() => {
          setCustomerDown(false);
          setProjectAttribute(!projectAttribute);
          setProjectManagement(!projectManagement);
          setProdManagement(false);
          setEnableDown(false);
          setHelpDeskDown(false);
          setSaidList(true);
          // navigate("/product-feed");
        }}
        className="hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <WarehouseIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Project Management
          </p>
        )}
      </div>
      {projectAttribute && (
        <li
          className="nav_link trans trans"
          onClick={() => {
            setProdManagement(false);
            setAttributes(!attributes);
            setShipping(false);
            setEnableDown(false);
            setHelpDeskDown(false);
          }}
        >
          <p
            className={` ${"gray f1"} trans hover_eff cp px-3 py-3  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
          >
            <GroupsIcon className="icon invisible" />
            <span className="text text-nowrap f2  fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Project Attributes
              <ArrowDropDownIcon />
            </span>
          </p>
        </li>
      )}

      {attributes && (
        <>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/overview-attributes-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "overview-attributes-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Overview Attributes
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/presentation-attributes-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "presentation-attributes-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Presentation Attributes
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/estimate-attributes-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "estimate-attributes-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Estimate Attributes
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/sales-order-attributes-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "sales-order-attributes-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Sales Order Attributes
              </span>
            </p>
          </li>
        </>
      )}

      {projectAttribute && (
        <li
          className="nav_link trans trans"
          onClick={() => {
            setProdManagement(false);
            setShipping(!shipping);
            setAttributes(false);
            setEnableDown(false);
            setHelpDeskDown(false);
          }}
        >
          <p
            className={` ${"gray f1"} trans hover_eff cp px-3 py-3  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
          >
            <GroupsIcon className="icon invisible" />
            <span className="text text-nowrap f2  fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Shipping
              <ArrowDropDownIcon />
            </span>
          </p>
        </li>
      )}

      {shipping && (
        <>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/shipping-location-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "shipping-location-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Location
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/shipping-type-project");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "shipping-type-project"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Type
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/box");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "box"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Box
              </span>
            </p>
          </li>
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/charge-shipping");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "charge-shipping"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Charge
              </span>
            </p>
          </li>
        </>
      )}

      {projectManagement && (
        <div>
          <div
            onClick={() => {
              navigate("/tags-project");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "tags-project" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "tags-project" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Project Tags
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/project-partner");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "project-partner" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "project-partner" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Project Partner
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/project-lead-sources");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "project-lead-sources" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "project-lead-sources" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Project Lead Sources
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/project-type-of-order");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "project-type-of-order"
                ? "bg-soft-gray1"
                : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "project-type-of-order" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Project Type of Order
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/project-location-name");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100 ${
              splitLocation[1] === "project-location-name"
                ? "bg-soft-gray1"
                : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "project-location-name" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Project Location Name
            </p>
          </div>
        </div>
      )}

      {/* end */}
      <div
        onClick={() => {
          setCustomerDown(!customerDown);
          setProdManagement(false);
          setEnableDown(false);
          setEnterprises(false);
          // navigate("/customer-list");
          setHelpDeskDown(false);
          // navigate("/customer-list");
          setSiteManagement(false);
          setProduction(false);
          setOurPacks(false);
          setRoles(false);
          setAdminRequst(false);
          setSaidList(true);
          setEcommerce(false);
        }}
        className="hover_eff cp d-flex align-content-center flex-md-row px-3 flex-column py-3 w-100"
      >
        <GroupsIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p
            className={` ${
              customerDown ||
              splitLocation[1] === "customer-dashboard" ||
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "customer-request-list" ||
              splitLocation[1] === "projects" ||
              // splitLocation[1] === "enterprises-dashboard" ||
              // splitLocation[1] === "enterprises-list" ||
              // splitLocation[1] === "enterprises-request-list" ||
              splitLocation[1] === "customer-request-list"
                ? "gray f1"
                : "gray f1"
            } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
          >
            <span className="text text-nowrap  ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Sales
            </span>
          </p>
        )}
      </div>
      <div>
        {customerDown && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEcommerce(!ecommerce);
              setEnableDown(false);
              setHelpDeskDown(false);
            }}
          >
            <p
              className={` ${"gray f1"} trans hover_eff cp  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text text-nowrap f2  px-3 py-3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                E-Commerce
                <ArrowDropDownIcon />
              </span>
            </p>
          </li>
        )}
        {ecommerce && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEnterprises(false);
              setHelpDeskDown(false);
              navigate("/customer-dashboard");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "customer-dashboard"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              }  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                E-Commerce Dashboard
              </span>
            </p>
          </li>
        )}
        {ecommerce && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEnterprises(false);
              setHelpDeskDown(false);
              navigate("/customer-list");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "customer-list"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              }  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                E-Commerce / Customer List
              </span>
            </p>
          </li>
        )}
        {ecommerce && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEnterprises(false);
              setHelpDeskDown(false);
              navigate("/customer-request-list");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "customer-request-list"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                E-Commerce / Customer Request List
              </span>
            </p>
          </li>
        )}
      </div>
      <div>
        {customerDown && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnterprises(!enterprises);
              setEnableDown(false);
              setHelpDeskDown(false);
            }}
          >
            <p
              className={` ${"gray f1"} trans hover_eff cp px-3 py-3  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text text-nowrap f2  fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Enterprises
                <ArrowDropDownIcon />
              </span>
            </p>
          </li>
        )}
        {enterprises && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/enterprises-dashboard");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "enterprises-dashboard"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Enterprises Dashboard
              </span>
            </p>
          </li>
        )}
        {enterprises && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/enterprises-list");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "enterprises-list"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="text wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Enterprises List
              </span>
            </p>
          </li>
        )}
        {enterprises && (
          <li
            className="nav_link trans trans"
            onClick={() => {
              setProdManagement(false);
              setEnableDown(false);
              setEcommerce(false);
              setHelpDeskDown(false);
              navigate("/enterprises-request-list");
            }}
          >
            <p
              className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
                splitLocation[1] === "enterprises-request-list"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              <GroupsIcon className="icon invisible" />
              <span className="pb-3 wordsDes text-nowrap w-100  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                Enterprises Request List
              </span>
            </p>
          </li>
        )}
      </div>
      {customerDown && (
        <div>
          <div
            onClick={() => {
              navigate("/projects");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "projects" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "projects" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Projects
            </p>
          </div>

          <div
            onClick={() => {
              navigate("/projects-margin-request");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "projects-margin-request"
                ? "bg-soft-gray1"
                : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "projects-margin-request"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Projects Margin Request
            </p>
          </div>

          <div
            onClick={() => {
              navigate("/projects-tasks");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "projects-tasks" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "projects-tasks" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Projects Task
            </p>
          </div>

          <div
            onClick={() => {
              navigate("/collaborates");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "collaborates" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "collaborates" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Collaborate
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/product-transfer");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "product-transfer" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "product-transfer" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Product Transfer
            </p>
          </div>
          {/* <div
            onClick={() => {
              navigate("/order-list");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${splitLocation[1] === "order-list" ||
              splitLocation[1] === "view-order-details"
              ? "bg-soft-gray1"
              : ""
              }`}
          >
            <p
              className={`${splitLocation[1] === "order-list" ||
                splitLocation[1] === "view-order-details"
                ? "black"
                : "gray"
                } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Order List
            </p>
          </div> */}
        </div>
      )}
      <div
        onClick={() => {
          setCustomerDown(false);
          setProdManagement(false);
          setEnableDown(false);
          setHelpDeskDown(false);
          setManageOrder(!manageOrder);
          // navigate("/ecommerce-list");
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <ManageAccountsIcon className=" icon gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Manage Order
          </p>
        )}
      </div>
      {manageOrder && (
        <div>
          <div
            onClick={() => {
              navigate("/pending-order");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              splitLocation[1] === "pending-order" ? "bg-soft-gray1" : ""
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "pending-order" ? "black" : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Pending Order
            </p>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          setCustomerDown(false);
          setProdManagement(false);
          setEnableDown(false);
          setHelpDeskDown(false);
          setSiteManagement(!siteManagement);
          // navigate("/ecommerce-list");
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <AccountTreeIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Site Managememnt
          </p>
        )}
      </div>
      {siteManagement && (
        <div>
          <div
            onClick={() => {
              navigate("/testimonials");
              setSaidList(true);
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "testimonials" ||
                splitLocation[1] === "testimonials-detail" ||
                splitLocation[1] === "testimonials-edit") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "testimonials" ||
                splitLocation[1] === "testimonials-detail" ||
                splitLocation[1] === "testimonials-edit"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Testimonials
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/banner-management");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "banner-management" ||
                splitLocation[1] === "banner-management-edit" ||
                splitLocation[1] === "banner-management-detail") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "banner-management" ||
                splitLocation[1] === "banner-management-edit" ||
                splitLocation[1] === "banner-management-detail"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Banner Management
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/blog-management");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "blog-management" ||
                splitLocation[1] === "blog-management-edit" ||
                splitLocation[1] === "blog-management-detail") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "blog-management" ||
                splitLocation[1] === "blog-management-edit" ||
                splitLocation[1] === "blog-management-detail"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Blog Management
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/special-offers");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "special-offers" ||
                splitLocation[1] === "special-offers-edit" ||
                splitLocation[1] === "special-offers-detail") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "special-offers" ||
                splitLocation[1] === "special-offers-edit" ||
                splitLocation[1] === "special-offers-detail"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Special Offers
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/vendor-list");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "vendor-list" ||
                splitLocation[1] === "vendor-management-edit" ||
                splitLocation[1] === "vendor-management-detail") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "vendor-list" ||
                splitLocation[1] === "vendor-management-edit" ||
                splitLocation[1] === "vendor-management-detail"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Trusted By
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/our-deals");
            }}
            className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "our-deals" ||
                splitLocation[1] === "our-deals-view") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "our-deals" ||
                splitLocation[1] === "our-deals-view"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Our Deals
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/join-with-us");
            }}
            className={` hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
              (splitLocation[1] === "join-with-us" ||
                splitLocation[1] === "join-with-us-view") &&
              "bg-soft-gray1"
            }`}
          >
            <p
              className={`${
                splitLocation[1] === "join-with-us" ||
                splitLocation[1] === "join-with-us-view"
                  ? "black"
                  : "gray"
              } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
            >
              Join with us
            </p>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          setEnableDown(false);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setOurPacks(false);
          setRoles(false);
          setAdminRequst(false);
          setProduction(!production);
          // navigate("/ecommerce-list");
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <CategoryIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Production
          </p>
        )}
      </div>

      {production && (
        <div
          onClick={() => {
            navigate("/production-dashboard");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "customer-list" ||
            splitLocation[1] === "view-customer-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "view-customer-details"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            DashBoard
          </p>
        </div>
      )}
      {production && (
        <div
          onClick={() => {
            navigate("/production-dashboard");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "customer-list" ||
            splitLocation[1] === "view-customer-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "view-customer-details"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            Production
          </p>
        </div>
      )}

      <div
        onClick={() => {
          setProduction(false);
          setProdManagement(false);
          setEnableDown(false);
          setHelpDeskDown(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setAdminRequst(!adminRequest);
          // navigate("/ecommerce-list");
          // navigate("/production-dashboard");
          setOurPacks(false);
          setRoles(false);
          setManageOrder(false);
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <AccountTreeIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Admin Request
          </p>
        )}
      </div>

      {adminRequest && (
        <div
          onClick={() => {
            navigate("/po-all-request");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "customer-list" ||
            splitLocation[1] === "view-customer-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "view-customer-details"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            PO overall Request
          </p>
        </div>
      )}
      {adminRequest && (
        <div
          onClick={() => {
            navigate("/Overall-estimate-request");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "Overall-estimate-request"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "Overall-estimate-request" ? "black" : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            Overall Estimate Request
          </p>
        </div>
      )}
      {adminRequest && (
        <div
          onClick={() => {
            navigate("/overall-markup-request");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "customer-list" ||
            splitLocation[1] === "view-customer-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "view-customer-details"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            Overall Mockup Artwork
          </p>
        </div>
      )}
      {adminRequest && (
        <div
          onClick={() => {
            navigate("/overall-production-markup-request");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "customer-list" ||
            splitLocation[1] === "view-customer-details"
              ? "bg-soft-gray1"
              : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "customer-list" ||
              splitLocation[1] === "view-customer-details"
                ? "black"
                : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            Overall Production Request
          </p>
        </div>
      )}
      {adminRequest && (
        <div
          onClick={() => {
            navigate("/price-request");
            setSaidList(true);
          }}
          className={`hover_eff cp d-flex px-3 flex-md-row flex-column py-3 w-100 ${
            splitLocation[1] === "price-request" ? "bg-soft-gray1" : ""
          }`}
        >
          <p
            className={`${
              splitLocation[1] === "price-request" ? "black" : "gray"
            } wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
          >
            Price Request
          </p>
        </div>
      )}
      <div
        onClick={() => {
          navigate("/support");
          setEnableDown(false);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <SupportAgentIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Support
          </p>
        )}
      </div>
      <div
        onClick={() => {
          navigate("/");
          setEnableDown(false);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <LogoutIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Logout
          </p>
        )}
      </div>

      <div
        onClick={() => {
          navigate("/support");
          setEnableDown(false);
          setProdManagement(false);
          setCustomerDown(false);
          setSiteManagement(false);
          setProduction(false);
          setSaidList(true);
        }}
        className=" hover_eff cp d-flex flex-md-row px-3 flex-column py-3 w-100"
      >
        <LogoutIcon className="gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20" />
        {sideList && (
          <p className="wordsDes text-nowrap w-100 ms-md-2 ms-lg-4 ms-xl-4 gray f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            Logout
          </p>
        )}
      </div>
    </div>
  );
};

export default SideBarComp;
