import React, { useEffect, useState } from "react";
import {
  collaborateList,
  colorsAndSize,
  size_comp,
  static_attributes,
} from "../../../redux/api/DummyJson";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Close, Search, CircleOutlined, Circle } from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { danger_sm, product } from "../../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import VariationsPopUpCopy2 from "../../Popup/VariationsPopUpCopy2";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";

const EstimateEditTableComp = ({
  poppupAddressToggle,
  poppupImageToggle,
  location,
  setLocation,
  setProductData,
  productData,
  artWorkHandler,
  viewData,
  qtyUpdate,
  discount_value,
  setdiscount_value,
  discount_percent,
  setdiscount_percent,
  net_price,
  setnet_price,
  sub_total,
  setsub_total,
  total,
  settotal,
  tax_value,
  settax_value,
  tax_sum_value,
  settax_sum_value,
  igst,
  cgst,
  sgst,
  quantity,
  setquantity,
  margin_percent,
  setmargin_percent,
  estimateUpdate,
  margin_percents,
  btns,
  retail_price,
  setretail_price,
  AddCharges,
  setAddCharges,
  colorsName,
  setColorsName,
  colorsID,
  setColorsID,
  setColorAndSize,
  colorAndSize,
  productPriceData,
  product_skuVarient,
  type,
  price_per_pack,
  setprice_per_pack,
  reqBtn,
  setReqBtn,
  discountType,
}) => {
  const navigate = useNavigate();
  const locationNavi = useLocation();
  const projectData = locationNavi?.state?.list;
  const selector = useSelector((state) => state.colorSize);
  const [toggle, setToggle] = useState(1);
  const [dropDown, setDropDown] = useState(undefined);
  const [radioBtn, setRadioBtn] = useState(1);
  const [boxRadioBtn, setBoxRadioBtn] = useState(1);
  const [variationType, setVariationsType] = useState("project-colors");
  const [Variations, setVariations] = useState(false);
  const [colorsShow, setColorsShow] = useState(false);
  const [addBreakdown, setAddBreakdown] = useState([""]);
  const [AddRunCharge, setAddRunCharge] = useState([""]);
  const [international, setInternational] = useState([]);
  const [chargeIn, setChargeIn] = useState([]);
  const [AddFixedCharge, setAddFixedCharge] = useState([""]);
  const [inboundFreight, setInboundFreight] = useState([""]);
  const [brokerage, setBrokerage] = useState([""]);
  const [addFixedChargeDec, setAddFixedChargeDec] = useState([""]);
  const [addRunChargeDec, setAddRunChargeDec] = useState([""]);
  const [decoration, setDecoration] = useState([1, 2]);
  const [ticketStatus, setTicketStatus] = useState(1);
  const [addItemLoc, setAddItemLoc] = useState([""]);
  const [addColor, setAddColor] = useState([]);
  const [run, setRun] = useState({});
  const [fixed, setFixed] = useState({});
  const [decorationData, setDecorationData] = useState({
    0: {
      0: { text: "Imprint Cost", select: 1 },
      1: { text: "Setup Cost", select: 1 },
    },
  });
  const [addFixedChargeDec2, setAddFixedChargeDec2] = useState([""]);
  const [addRunChargeDec2, setAddRunChargeDec2] = useState([""]);
  const [addItemLoc2, setAddItemLoc2] = useState([""]);

  const [addLocation, setAddLocation] = useState([""]);

  const [addSize, setAddSize] = useState([]);
  const [newQty, setNewQty] = useState("");

  //   Brakedown
  const removebreackdown = (e, ind) => {
    let temp = [...addBreakdown];
    temp.splice(ind, 1);
    setAddBreakdown(temp);
  };
  const addBreakClick = () => {
    let temp = [...addBreakdown];
    temp.push({ warn: "" });
    setAddBreakdown(temp);
  };

  //AddRunCharge

  const removeRunChargeClick = (e, ind) => {
    let temp = [...AddRunCharge];
    temp.splice(ind, 1);
    setAddRunCharge(temp);
  };
  const addRunChargeClick = () => {
    let temp = [...AddRunCharge];
    temp.push({ warn: "" });
    setAddRunCharge(temp);
  };

  //AddFixedCharge

  const removeFixedChargeClick = (e, ind) => {
    let temp = [...AddFixedCharge];
    temp.splice(ind, 1);
    setAddFixedCharge(temp);
  };
  const addFixedChargeClick = () => {
    let temp = [...AddFixedCharge];
    temp.push({ warn: "" });
    setAddFixedCharge(temp);
  };

  //inboundFreight

  const removeInboundFreightClick = (e, ind) => {
    let temp = [...inboundFreight];
    temp.splice(ind, 1);
    setInboundFreight(temp);
  };
  const addinboundFreightClick = () => {
    let temp = [...inboundFreight];
    temp.push({ warn: "" });
    setInboundFreight(temp);
  };

  //Brokerage

  const removeBrokerageClick = (e, ind) => {
    let temp = [...brokerage];
    temp.splice(ind, 1);
    setBrokerage(temp);
  };
  const addBrokerageClick = () => {
    let temp = [...brokerage];
    temp.push({ warn: "" });
    setBrokerage(temp);
  };

  // Size
  const addSizeClick = () => {
    let temp = [...addSize];
    temp.push({ warn: "" });
    setAddSize(temp);
  };

  // Decorator

  // RunCharge
  const removeRunChargeDecClick = (e, ind) => {
    let temp = [...addRunChargeDec];
    temp.splice(ind, 1);
    setAddRunChargeDec(temp);
  };
  const addRunChargeDecClick = () => {
    let temp = [...addRunChargeDec];
    temp.push({ warn: "" });
    setAddRunChargeDec(temp);
  };

  //AddFixedCharge

  const removeFixedChargeDecClick = (e, ind) => {
    let temp = [...addFixedChargeDec];
    temp.splice(ind, 1);
    setAddFixedChargeDec(temp);
  };
  const addFixedChargeDecClick = () => {
    let temp = [...addFixedChargeDec];
    temp.push({ warn: "" });
    setAddFixedChargeDec(temp);
  };
  //Add Item

  const removeAddItemClick = (e, ind, ind2) => {
    let temp = [...addItemLoc];
    temp.splice(ind2[ind], 1);
    setAddItemLoc(temp);
  };
  const addAddItemClick = () => {
    let temp = [...addItemLoc];
    temp.push({ warn: "" });
    setAddItemLoc(temp);
  };

  // Location2

  // RunCharge
  const removeAddLocClick2 = (e, ind) => {
    let temp = [...addLocation];
    temp.splice(ind, 1);
    setAddLocation(temp);
  };
  const addAddLocClick2 = () => {
    let temp = [...addLocation];
    temp.push({ warn: "" });
    setAddLocation(temp);
  };

  const toggleShowPopup = (type) => {
    setVariations(!Variations);
  };

  const ColorSlect = (event) => {
    let temp = [...colorsName];
    let finder = temp?.find((i) => i?.id == event?.id);
    if (finder?.id) {
      let final = temp?.filter((i) => i?.id !== finder?.id);
      setColorsName(final);
    } else {
      temp.push(event);
      setColorsName(temp);
    }

    let tempx = [...colorsID];
    let inx = tempx?.indexOf(event?.id);
    if (tempx?.includes(event?.id)) {
      tempx.splice(inx, 1);
    } else {
      tempx.push(event?.id);
    }
    setColorsID(tempx);
  };

  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };

  // ADD CHARGES COMMON

  const addCharges = (num) => {
    let temp = [...AddCharges];

    temp.push({
      name: "",
      net_price: "",
      margin: "",
      final_amount: "",
      client_price: "",
    });
    setAddCharges(temp);
    setDropDown(undefined);
  };

  const removeCharge = (ind) => {
    let temp = [...AddCharges];

    temp.splice(ind, 1);
    calculationFun(net_price, discount_percent, temp);
    setAddCharges(temp);
  };

  const chargeRun = (event, ind) => {
    let value = event.target.value;
    setRun((val) => ({ ...val, [ind]: value }));
  };

  const chargeFixed = (event, ind) => {
    let value = event.target.value;
    setFixed((val) => ({ ...val, [ind]: value }));
  };

  const addInternaional = (num) => {
    let temp = [...international];

    temp.push(num);
    setInternational(temp);
    setDropDown(undefined);
  };

  const removeInternational = (ind) => {
    let temp = [...international];

    temp.splice(ind, 1);
    setInternational(temp);
  };

  const removeColors = (event, ind) => {
    let temp = [...colorsName];

    temp.splice(ind, 1);
    setColorsName(temp);
  };

  const addDecoration = (ind) => {
    let temp = [...decoration];

    temp.push(ind);
    setDecoration(temp);
    setDropDown(undefined);
  };

  const removeDecoration = (item, ind, ind2) => {
    let temp = [...decoration];

    let num = decoration[ind2];

    temp.splice(ind, 1);
    setDecoration(temp);

    if (Object.values(decorationData)?.length > 0) {
      let obj = decorationData[ind2];

      console.log("obj", obj);

      if (obj !== undefined) {
        delete obj[ind];

        let tem = Object.values(obj);

        if (tem?.length == 0) {
          delete decorationData[ind2];
        }

        setDecorationData((val) => ({ ...val }));
      }
    }
  };

  const locationAdd = (num, type) => {
    let temp = [...location];
    temp.push(num);

    for (let i = 0; i < temp?.length; i++) {
      setDecorationData((val) => ({
        ...val,
        [i + 1]: {
          0: { text: "Imprint Cost", select: 1 },
          1: { text: "Setup Cost", select: 1 },
        },
      }));
    }

    if (type == "copy") {
      // console.log("ss");
      let obj = { ...decorationData };
    }
    setLocation(temp);
  };

  const locationRemove = (num) => {
    let temp = [...location];
    temp.splice(num, 1);
    setLocation(temp);
  };

  const chargeDecoration = (event, type, ind, ind2) => {
    let value = event.target.value;
    if (decorationData[ind2]) {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: { ...tems, [type]: value },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    } else {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: {
          ...tems,
          [type]: value,
        },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    }
  };

  const handleDiscount = (value) => {
    calculationFun(net_price, value, AddCharges);
    setdiscount_percent(value);
  };

  const handleChangeRun = (event, ind, type) => {
    let temp = [...AddCharges];

    if (type == "net_price") {
      let margin = temp[ind].margin ? temp[ind].margin : 0;

      let sum = (+event * +margin) / 100;
      let total = +event + +sum;

      temp[ind] = {
        ...temp[ind],
        [type]: event,
        final_amount: total,
      };
      calculationFun(net_price, discount_percent, temp);
    } else if (type == "margin") {
      let net_prices = temp[ind].net_price ? temp[ind].net_price : 0;

      let sum = (+event * +net_prices) / 100;
      let total = +net_prices + +sum;

      temp[ind] = {
        ...temp[ind],
        [type]: event,
        final_amount: total,
      };
      calculationFun(net_price, discount_percent, temp);
    } else if (type == "client_price") {
      temp[ind] = {
        ...temp[ind],
        [type]: event,
      };

      calculationFun(net_price, discount_percent, temp);
    } else {
      temp[ind] = {
        ...temp[ind],
        [type]: event,
      };
    }

    // console.log("arr", arr);

    setAddCharges(temp);
  };

  const handleQty = (event) => {
    let finder = productData?.projectPresentationPrice?.find(
      (i) => i?.qty == +event
    );
    if (finder?.id) {
      setmargin_percent(finder?.margin);
      setprice_per_pack(finder?.total);
      let base_price = finder?.total * +event;
      calculationFun(base_price, discount_percent, AddCharges);
    }
    setquantity(event);
    setDropDown(null);
  };

  const addQty = () => {
    if (newQty?.length == 0) {
      toast.error("Please Enter QTY");
    } else {
      let obj = productPriceData?.productDistributorPrice;
      let margin_val = margin_percent;

      let value = obj?.for_25;
      let value_new = productPriceData?.for_25;

      // if (
      //   newQty * viewData?.no_of_packs >= 1 &&
      //   newQty * viewData?.no_of_packs <= 25
      // ) {
      //   value = obj?.for_25;
      //   value_new = productPriceData?.for_25;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 26 &&
      //   newQty * viewData?.no_of_packs <= 50
      // ) {
      //   value = obj?.for_50;
      //   value_new = productPriceData?.for_50;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 51 &&
      //   newQty * viewData?.no_of_packs <= 100
      // ) {
      //   value = obj?.for_100;
      //   value_new = productPriceData?.for_100;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 101 &&
      //   newQty * viewData?.no_of_packs <= 150
      // ) {
      //   value = obj?.for_150;
      //   value_new = productPriceData?.for_150;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 151 &&
      //   newQty * viewData?.no_of_packs <= 250
      // ) {
      //   value = obj?.for_250;
      //   value_new = productPriceData?.for_250;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 251 &&
      //   newQty * viewData?.no_of_packs <= 500
      // ) {
      //   value = obj?.for_500;
      //   value_new = productPriceData?.for_500;
      // } else if (
      //   newQty * viewData?.no_of_packs >= 501 &&
      //   newQty * viewData?.no_of_packs <= 1000
      // ) {
      //   value = obj?.for_1000;
      //   value_new = productPriceData?.for_1000;
      // }

      // let base_price_sum = (value * +margin_val) / 100 + value;
      // let base_price_qty = Math.round(base_price_sum) * +newQty;
      // let base_price = +base_price_qty * +viewData?.no_of_packs;

      if (newQty >= 1 && newQty <= 25) {
        value = obj?.for_25;
      } else if (newQty >= 26 && newQty <= 50) {
        value = obj?.for_50;
      } else if (newQty >= 51 && newQty <= 100) {
        value = obj?.for_100;
      } else if (newQty >= 101 && newQty <= 150) {
        value = obj?.for_150;
      } else if (newQty >= 151 && newQty <= 250) {
        value = obj?.for_250;
      } else if (newQty >= 251 && newQty <= 500) {
        value = obj?.for_500;
      } else if (newQty >= 501 && newQty <= 1000) {
        value = obj?.for_1000;
      } else {
        value = obj?.for_1000;
      }

      let base_price_sum = (value * +margin_val) / 100 + value;
      let base_price_qty = Math.round(base_price_sum) * +newQty;
      let base_price = +base_price_qty;

      setprice_per_pack(Math.round(base_price_sum));
      calculationFun(base_price, discount_percent, AddCharges);
      setquantity(newQty);
      setmargin_percent(margin_val);
      setNewQty("");
      setDropDown(null);
    }
  };

  const handleMarign = (event) => {
    let obj = productPriceData?.productDistributorPrice;
    let value = obj?.for_25;

    if (+quantity >= 1 && +quantity <= 25) {
      value = obj?.for_25;
    } else if (+quantity >= 26 && +quantity <= 50) {
      value = obj?.for_50;
    } else if (+quantity >= 51 && +quantity <= 100) {
      value = obj?.for_100;
    } else if (+quantity >= 101 && +quantity <= 150) {
      value = obj?.for_150;
    } else if (+quantity >= 151 && +quantity <= 250) {
      value = obj?.for_250;
    } else if (+quantity >= 251 && +quantity <= 500) {
      value = obj?.for_500;
    } else if (+quantity >= 501 && +quantity <= 1000) {
      value = obj?.for_1000;
    }

    if (event >= margin_percents) {
      setReqBtn(2);
    } else {
      setReqBtn(1);
    }

    let base_price_sum = (+event * +value) / 100 + +value;
    let base_price_qty = Math.round(base_price_sum) * +quantity;
    let base_price = +base_price_qty * +viewData?.no_of_packs;
    calculationFun(+base_price_qty, discount_percent, AddCharges);
    setprice_per_pack(Math.round(base_price_sum));
    setmargin_percent(event);
  };

  const calculationFun = (base_price, discount_percentage, runcharges) => {
    let return_values = {};

    return_values.net_price = base_price;

    return_values.retail_price = return_values.net_price;
    if (+discount_percentage > 0) {
      return_values.retail_price = Number(
        +return_values.net_price -
          (+discount_percentage * +return_values.net_price) / 100
      ).toFixed(2);

      setdiscount_value(
        Number((+discount_percentage * +return_values.net_price) / 100).toFixed(
          2
        )
      );
    }
    return_values.sub_total = +return_values.retail_price;
    return_values.total = +return_values.retail_price;

    runcharges.map((item, ind) => {
      if (item?.client_price == 1) {
        return_values.sub_total += +item?.final_amount;
        return_values.total += +item?.final_amount;
      } else if (item?.client_price == 2) {
        return_values.sub_total += +item?.final_amount;
      }
    });

    let tex_percen;

    if (igst) {
      tex_percen = igst;
    } else {
      tex_percen = +cgst + +sgst;
    }

    // if (tex_percen && projectData?.tax_type == 1) {
    if (tex_percen) {
      let tex = Number((+return_values?.sub_total * +tex_percen) / 100).toFixed(
        2
      );
      let final = Number(+tex + +return_values?.sub_total).toFixed(2);
      settax_sum_value(+final);
      settax_value(+tex);
    } else {
      settax_sum_value(+return_values?.sub_total);
    }

    setnet_price(+base_price);
    setsub_total(+return_values?.sub_total);
    setretail_price(+return_values?.retail_price);
    settotal(+return_values?.total);

    return return_values;
  };

  const handleSkuQty = (event, ind) => {
    let temp = [...colorsName];

    if (+event >= 0) {
      temp[ind] = {
        ...temp[ind],
        qty: +event,
      };
    }

    setColorsName(temp);
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="w-100">
      {Variations && (
        <VariationsPopUpCopy2
          toggleShowPopup={toggleShowPopup}
          variationType={variationType}
        />
      )}
      <div className="d-flex mt-3 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
          <button
            onClick={() =>
              navigate("/product-library-details", {
                state: { data: productData?.product, type: "edit" },
              })
            }
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            View Product Page
          </button>
          <button
            onClick={() =>
              navigate("/product-library-details", {
                state: { data: productData?.product, type: "edit", tab: 1 },
              })
            }
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Check Pricing
          </button>
        </div>
        <div className="mt-md-4 mt-3">
          <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              INR
            </span>
          </h5>
        </div>
      </div>
      <div className="w-100 mt-5">
        <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
          Pricing
        </p>
        <div
          className="w-100 overflow-scroll mt-5 edit_page_cust"
          style={colorsShow ? { height: "300px" } : { borderWidth: "1px" }}
        >
          <table className="w-100">
            <tr className="mt-4 border_cust ">
              <div className="bg-soft-gray1 py-3 bottom_cust_bord">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2" style={{ width: "20px" }}></div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Varients
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        SKU
                      </p>
                    </div>
                    {/* <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Pack
                      </p>
                    </div> */}
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        QTY
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Margin
                      </p>
                    </div>{" "}
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Price Per Pack
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Net Cost
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Discount Margin
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "200px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Retail
                      </p>
                      <LockOpenRoundedIcon />
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "150px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {/* Client Price */}
                        &nbsp;
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc gap-1"
                      style={{ width: "100px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Total
                      </p>
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <div className="py-3 hide_res">
                <td>
                  <div className="d-flex w-100">
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "20px" }}
                    >
                      {/* <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" /> */}
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc "
                      style={{ width: "200px" }}
                    >
                      <div className="position-relative">
                        {/* NEW DESIGN */}
                        <input
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          // value={colorsName}
                          placeholder="Select Varients"
                          onChange={onColors}
                        />
                        <button
                          className="drop_down cust-btn"
                          onClick={() => {
                            setColorsShow(!colorsShow);
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </button>
                        {colorsShow && (
                          <div
                            className="invisible-cont2"
                            onClick={() => setColorsShow(!colorsShow)}
                          />
                        )}
                        <div
                          className={`${
                            colorsShow && "drop_width z-3 h-auto py-2  px-2"
                          } submenu_cont_1 overflow-scroll z-3`}
                        >
                          {colorAndSize?.map((item, ind) => {
                            // console.log(ind)
                            return (
                              <button
                                className="d-flex ac-jb hover-cust cust-btn w-100 px-2"
                                onClick={() => {
                                  if (item?.type !== "child") {
                                    ColorSlect(item);
                                  }
                                }}
                                key={ind}
                                disabled={
                                  item?.project_detail_size_id ? true : false
                                }
                              >
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.variant_name}
                                </button>
                                {colorsID?.includes(item?.id) ? (
                                  <Circle style={{ color: "#07679c" }} />
                                ) : (
                                  <CircleOutlined className="primary" />
                                )}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={product_skuVarient}
                      />
                    </div>
                    {/* <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={
                          viewData?.no_of_packs ? viewData?.no_of_packs : "-"
                        }
                        type={"number"}
                      />
                    </div> */}
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "150px" }}
                    >
                      <div className=" d-flex ac-jc position-relative">
                        <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                            value={quantity}
                            onClick={() => setDropDown(8)}
                          />
                          <button
                            className="drop_down cust-btn"
                            onClick={() => setDropDown(8)}
                          >
                            <KeyboardArrowDownIcon className="primary" />
                          </button>
                          {dropDown === 8 && (
                            <div
                              className="invisible-cont2 z-0"
                              onClick={() => setDropDown(undefined)}
                            />
                          )}
                          <div
                            className={`${
                              dropDown === 8 && "submenu_1 h-auto"
                            } submenu_cont_1 overflow-scroll z-3`}
                            style={{ width: "170px" }}
                          >
                            <div className="d-flex gap-1">
                              <input
                                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                placeholder="Add New"
                                type={"number"}
                                onChange={(e) => setNewQty(e.target.value)}
                                value={newQty}
                              />
                              <button
                                className="border-0 bg-transparent"
                                onClick={() => {
                                  addQty();
                                }}
                              >
                                Add
                              </button>
                            </div>
                            {productData?.projectPresentationPrice?.map(
                              (item, ind) => {
                                return (
                                  <div
                                    className="d-flex as-jb hover-cust"
                                    key={ind}
                                  >
                                    <button
                                      className="px-2 cust-btn text-start py-1 w-100 "
                                      onClick={() => {
                                        handleQty(item?.qty);
                                      }}
                                    >
                                      {item?.qty}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        type={"number"}
                        value={margin_percent}
                        onChange={(e) => handleMarign(e.target.value)}
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={price_per_pack ? price_per_pack : "0"}
                        type={"number"}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={net_price ? net_price : "0"}
                        type={"number"}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Optional Field"
                        className={`editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 ${
                          discountType == 2 ? "opacity-50" : ""
                        }`}
                        type={"number"}
                        onChange={(e) => handleDiscount(e.target.value)}
                        value={discount_percent}
                        disabled={discountType == 2 ? true : false}
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        type={"number"}
                        value={retail_price ? retail_price : "0"}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "150px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        {/* ₹10.53 */}
                        &nbsp;
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        {total ? "₹" + commaValue(total) : "0"}
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "10px" }}
                    >
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            {reqBtn == 1 && (
              <tr>
                <div className=" hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                        style={{ width: "20px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc "
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      ></div>

                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          role={"button"}
                          onClick={() => estimateUpdate()}
                        >
                          Request
                        </p>
                        <button
                          className={`border-0 text-info f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                            btns ? "opacity-50" : ""
                          }`}
                          style={{ backgroundColor: "#f4f8ff" }}
                          onClick={() =>
                            navigate("/projects-margin-request", {
                              state: {
                                data: `${locationNavi?.state?.list?.project_code}_${locationNavi?.state?.list?.id}`,
                              },
                            })
                          }
                          disabled={btns ? true : false}
                        >
                          View
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>

                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>
                    </div>
                  </td>
                </div>
              </tr>
            )}

            {colorsName?.map((item, ind) => {
              return (
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "20px" }}
                        ></div>
                        {/* {item?.type == "child" ? (
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "20px" }}
                          >
                            
                          </div>
                        ) : 
                        (
                          <div
                            className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                            style={{ width: "20px" }}
                            onClick={() => removeColors(item, ind)}
                          >
                            <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                          </div>
                        )} */}
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <input
                            placeholder="Required Field"
                            value={item?.variant_name}
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                          />
                        </div>
                        {/* <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        > */}
                        {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button> */}
                        {/* </div> */}
                        <div
                          className="mx-2 d-flex flex-column ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          {item?.type == "child" ? (
                            <input
                              value={quantity}
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              readOnly
                            />
                          ) : (
                            <input
                              type={"number"}
                              value={item?.qty}
                              onChange={(e) =>
                                handleSkuQty(e.target.value, ind)
                              }
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                          )}
                          {item?.type !== "child" && item?.qty > quantity && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">
                                Qty is higher
                              </p>
                            </div>
                          )}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {item?.type !== "child" && (
                            <button
                              className={`drop_down4 cust-btn ${
                                item?.qty > quantity
                                  ? "opacity-50"
                                  : btns
                                  ? "opacity-50"
                                  : ""
                              }`}
                              onClick={() => qtyUpdate(item)}
                              disabled={
                                item?.qty > quantity
                                  ? true
                                  : btns
                                  ? true
                                  : false
                              }
                            >
                              <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                                Set
                              </p>
                            </button>
                          )}
                          {/* <input
                            placeholder="Size"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          /> */}
                          {/* <input
                          placeholder="Required Field"
                          disabled
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                        />
                        <button className="drop_down4 cust-btn">
                          <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                        </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* <input
                          placeholder="Required Field"
                          disabled
                          className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-50"
                        />
                        <button className="drop_down4 cust-btn">
                          <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                            %
                          </p>
                        </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                          />
                          <button className="drop_down4 cust-btn">
                            <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button> */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          {/* <select
                            placeholder="Required Field"
                            className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onChange={(e) => chargeRun(e, ind)}
                          >
                            <option value={1}>Include in price</option>
                            <option value={2}>Display to client</option>
                          </select>{" "} */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {/* {run[ind] == 2 && (
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              ₹1316.25
                            </p>
                          )} */}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "10px" }}
                        >
                          {/* <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" /> */}
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}

            {AddCharges?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "20px" }}
                          onClick={() => removeCharge(ind)}
                        >
                          <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={item?.name}
                            onChange={(e) =>
                              handleChangeRun(e.target.value, ind, "name")
                            }
                          />
                        </div>
                        {/* <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div> */}
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                            value={quantity}
                          />
                        </div>

                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            value={item?.margin}
                            onChange={(e) =>
                              handleChangeRun(e.target.value, ind, "margin")
                            }
                            type={"number"}
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button>
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            value={item?.net_price}
                            onChange={(e) =>
                              handleChangeRun(e.target.value, ind, "net_price")
                            }
                            type={"number"}
                          />
                          <button className="drop_down4 cust-btn">
                            <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button>
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            value={item?.final_amount}
                            onChange={(e) =>
                              handleChangeRun(
                                e.target.value,
                                ind,
                                "final_amount"
                              )
                            }
                            readOnly
                            type={"number"}
                          />
                          <button className="drop_down4 cust-btn">
                            <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button>
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <select
                            placeholder="Required Field"
                            className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onChange={(e) =>
                              handleChangeRun(
                                e.target.value,
                                ind,
                                "client_price"
                              )
                            }
                            value={item?.client_price}
                          >
                            <option value={""}>Select</option>
                            <option value={1}>Include in price</option>
                            <option value={2}>Display to client</option>
                          </select>
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {item?.client_price == 2 && (
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              ₹{commaValue(item?.final_amount)}
                            </p>
                          )}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "10px" }}
                        >
                          <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="d-flex ac-jb mt-3">
        {/* <div className="d-flex ac-js flex-column flex-md-row">
          <button
            // onClick={() => setDropDown(5)}
            onClick={toggleShowPopup}
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded d-flex ac-jc gap-2"
          >
            <FormatListBulletedIcon />
            Add sizes and colors
          </button>
          {dropDown === 5 && (
            <div
              onClick={() => setDropDown(undefined)}
              className="invisible-cont2 z-2 cp"
            />
          )}
        </div> */}
        <div className="d-flex flex-column flex-sm-row mt-3 mt-md-0">
          <div className="position-relative">
            {type != "sales" && (
              <button
                onClick={() => setDropDown(6)}
                className="pointerView cust-btn px-4 py-2 tranc cp"
              >
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  + Add Cost
                </p>
              </button>
            )}
            {dropDown === 6 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 6 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addRunChargeClick();
                    addCharges(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Run Charge
                </button>
              </button>
              {/* <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addFixedChargeClick();
                    addCharges(2);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Fixed Charge
                </button>
              </button> */}
            </div>
          </div>
          <div className="position-relative">
            {/* <button
              onClick={() => setDropDown(7)}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Add International Cost
              </p>
            </button> */}
            {dropDown === 7 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 7 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addinboundFreightClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Inbound Freight
                </button>
              </button>
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Brokerage
                </button>
              </button>
              {/* {!international?.includes(2) && ( */}
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Others
                </button>
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="mt-4 d-flex ac-jc">
          {type != "sales" && (
            <button
              class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btns && "opacity-50"
              }`}
              onClick={() => estimateUpdate()}
              disabled={btns ? true : false}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div className="d-flex ac-js mt-5 gap-3 flex-column flex-md-row">
        <div className="me-5">
          <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
            Decoration
          </p>
        </div>
        <div className="d-flex toggle_box_cont ac-jc rounded-5">
          <div
            onClick={() => setToggle(1)}
            className={`${
              toggle === 1 ? "toggle_box1" : "empty_box"
            }  d-flex ac-jc  rounded-5 w-100`}
          >
            <button className="cust-btn f3 text-light py-2 py-sm-3  px-1 px-sm-3 d-flex ac-jc text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Supplier Decorator
            </button>
          </div>
        </div>
      </div>
      {location?.map((item, ind) => {
        return (
          <div className="w-100 overflow-scroll mt-5 edit_page_cust">
            <table className="w-100">
              <div className="d-flex hide_res">
                <p className="primary f3 ms-5 pt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Location {ind + 1}
                </p>
              </div>
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "415px" }}
                      >
                        <div className="w-100">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={item?.product?.name}
                          />
                        </div>
                      </div>
                      <div className="mx-2 d-flex ac-jc position-relative">
                        <button
                          onClick={() =>
                            artWorkHandler(item, productData, 0, ind)
                          }
                          className=" bg-transparent doted_border d-flex ac-js "
                        >
                          <div className="">
                            <img
                              src={item?.image_url ? item?.image_url : product}
                              className="object-fit-contain img_style"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="w-100 d-flex ac-js gap-3">
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Decorative Method
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={item?.decorative_method_id?.name}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              {/* Imprint Type: */}
                              Proof Required
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={
                                  item?.proof_required == "email_proof"
                                    ? "Email Proof"
                                    : item?.proof_required == "digital_photo"
                                    ? "Digital Photo"
                                    : item?.proof_required == "pre_production"
                                    ? "Pre Production"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Name
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_name}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              UOM
                            </p>
                            <div className="w-100 ac-js d-flex gap-3 ms-3">
                              <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                <button className="d-flex gap-3  ac-js cust-btn">
                                  <div className="radio_btn d-flex ac-jc">
                                    <div
                                      className={`${
                                        +item?.uom == 1
                                          ? "sm_radio"
                                          : "sm_radio1"
                                      }`}
                                    />
                                  </div>
                                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                    Inches
                                  </p>
                                </button>
                              </div>
                              <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                <button className="d-flex gap-3  ac-js cust-btn">
                                  <div className="radio_btn d-flex ac-jc">
                                    <div
                                      className={`${
                                        +item?.uom == 2
                                          ? "sm_radio"
                                          : "sm_radio1"
                                      }`}
                                    />
                                  </div>
                                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                    Stitch
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </td>
                </div>
                <div className="py-3 hide_res">
                  <td>
                    <div className="w-100 d-flex ac-js  gap-3">
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Size
                            </p>
                            <div className="w-100 ms-3 d-flex gap-2">
                              <input
                                placeholder="Length"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_length}
                              />
                              <input
                                placeholder="Breadth"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_breadth}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Color
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_color}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Repeat Logo?
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={
                                  item?.repeat_logo == "yes"
                                    ? "Yes"
                                    : item?.repeat_logo == "no"
                                    ? "No"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Supplier Notes
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.supplier_note}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default EstimateEditTableComp;
