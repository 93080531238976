import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ViewStockPoppup = ({ viewStockToggle, stockList }) => {
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40">
        {" "}
        <button
          onClick={() => viewStockToggle()}
          className="cust-btn d-flex ac-je w-100"
        >
          <HighlightOffIcon />
        </button>
        <div className="d-flex as-jb gap-5 mx-3 " style={{ overflow: "auto" }}>
          {stockList?.map((item, ind) => {
            return (
              <div className="mt-3" key={ind}>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {item?.location}:
                </p>
                <div className="mt-3">
                  <div className="d-flex w-100 gap-3 my-2">
                    <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-nowrap">
                      SKU Code :
                    </p>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {item?.skuCode}
                    </p>
                  </div>
                  <div className="d-flex w-100 gap-3 my-2">
                    <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-nowrap">
                      Stock Qty :
                    </p>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {item?.qty}
                    </p>
                  </div>
                  <div className="d-flex w-100 gap-3 my-2">
                    <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-nowrap">
                      Committed Qty :
                    </p>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                      {item?.committedQty}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default ViewStockPoppup;
