import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import { salesForce, salesForceEcom } from "../../redux/api/DummyJson";
import AddAttributesPopup from "../Popup/AddAttributesPopup";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link } from "react-router-dom";
import { toggleOff, toggleOn, viewIcon } from "../../assets/img";
import ReasonPopup from "../Popup/ReasonPopup";

const Ecommerce = ({
  list,
  pagination,
  searchs,
  rows,
  orders,
  order_reps,
  client_reps,
  statuss,
  codes,
  production_reps,
  getProjectList,
  adminList,
  page,
  setPage,
}) => {
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonShow, setReasonShow] = useState(false);

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  const showReasonPopupHander = () => setReasonShow(!reasonShow);

  const getReason = (value) => {
    if (value) {
      setReason(value);
    }
    showReasonPopupHander();
  };

  // console.log("list", list);

  return (
    <div className="mt-4 ">
      {reasonShow && (
        <ReasonPopup
          showReasonPopupHander={showReasonPopupHander}
          reason={reason}
          setReason={setReason}
          type={"reason"}
        />
      )}
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <input
            type="text"
            className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
            placeholder="Search for project"
            onChange={(e) =>
              getProjectList(
                e.target.value,
                rows,
                orders,
                order_reps,
                client_reps,
                statuss,
                codes,
                production_reps
              )
            }
            value={searchs}
          />
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select
              className="bg-transparent border-0 py-3 primary1"
              onChange={(e) =>
                getProjectList(
                  searchs,
                  rows,
                  orders,
                  order_reps,
                  e.target.value,
                  statuss,
                  codes,
                  production_reps
                )
              }
              value={client_reps}
            >
              <option disabled selected value="">
                Client Rep
              </option>
              {adminList?.map((item, ind) => {
                return (
                  <option value={item?.id} key={ind}>
                    {item?.first_name}
                  </option>
                );
              })}
            </select>
          </button>
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select
              className="bg-transparent border-0 py-3 primary1"
              onChange={(e) =>
                getProjectList(
                  searchs,
                  rows,
                  orders,
                  e.target.value,
                  client_reps,
                  statuss,
                  codes,
                  production_reps
                )
              }
              value={order_reps}
            >
              <option disabled selected value="">
                Order Rep
              </option>
              {adminList?.map((item, ind) => {
                return (
                  <option value={item?.id} key={ind}>
                    {item?.first_name}
                  </option>
                );
              })}
            </select>
          </button>
          {/* <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Stage
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Open Projects</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Presentation">Presentation</option>
              <option value="Estimate">Estimate</option>
              <option value="Sales Order">Sales Order</option>
              <option value="Invoice">Invoice</option>
            </select>
          </button> */}
          <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select
              className="bg-transparent border-0 py-3 primary1"
              onChange={(e) =>
                getProjectList(
                  searchs,
                  rows,
                  orders,
                  order_reps,
                  client_reps,
                  e.target.value,
                  codes,
                  production_reps
                )
              }
              value={statuss}
            >
              <option disabled selected value="">
                Status
              </option>
              <option value={0}>Rejected</option>
              <option value={1}>Overview</option>
              <option value={2}>Collaborate</option>
              <option value={3}>Presentation</option>
              <option value={4}>Show to Customer</option>
              <option value={5}>Estimates</option>
              <option value={6}>Production Artwork</option>
              <option value={7}>Sales Order</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          {/* <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button> */}
          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            onClick={() => getProjectList("", 10, "", "", "", "", "", "")}
          >
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getProjectList(
                searchs,
                e.target.value,
                orders,
                order_reps,
                client_reps,
                statuss,
                codes,
                production_reps
              )
            }
            value={rows}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
          Total Count : {pagination?.total ? pagination?.total : 0}
        </p>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100 text-nowrap text-center">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project ID
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Request ID
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Created Time
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Order Rep
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Client Rep
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Value
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Status of Project
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Partner
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Lead Source
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Type of Order
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Project Location Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Organization Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Contact Name
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Lost Date
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14">
                  Lost Reason
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14" style={{ width: "250px" }}>
                  Tags
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              {/* <th>
                <p className="f2 fs-14">
                  Status
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr className="text-center">
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td className="text-center">
                    <Link
                      className="f3 primary1 text-center"
                      to={`/overview?project=${item?.project_code}_${item?.id}`}
                      target="_blank"
                    >
                      {item?.project_code}
                    </Link>
                  </td>
                  <td>{item?.project_request?.project_request_id}</td>
                  <td>{item?.start_date}</td>
                  <td>
                    {item?.project_request?.order_rep?.first_name
                      ? item?.project_request?.order_rep?.first_name
                      : "-"}
                  </td>
                  <td>
                    {item?.project_request?.client_rep?.first_name
                      ? item?.project_request?.client_rep?.first_name
                      : "-"}
                  </td>
                  <td>{item?.total_amount}</td>
                  <td>
                    {item?.status == 0
                      ? "Rejected"
                      : item?.status == 1
                      ? "Overview"
                      : item?.status == 2
                      ? "Collaborate"
                      : item?.status == 3
                      ? "Presentation"
                      : item?.status == 4
                      ? "Show to Customer"
                      : item?.status == 5
                      ? "Estimates"
                      : item?.status == 6
                      ? "Production Artwork"
                      : item?.status == 7
                      ? "Estimates Show to Customer"
                      : item?.status == 8
                      ? "Sales Order"
                      : "-"}
                  </td>

                  <td>{item?.partner?.name ? item?.partner?.name : "-"}</td>
                  <td>
                    {item?.lead_source?.name ? item?.lead_source?.name : "-"}
                  </td>
                  <td>
                    {item?.type_of_order?.name
                      ? item?.type_of_order?.name
                      : "-"}
                  </td>
                  <td>{item?.location ? item?.location?.name : "-"}</td>
                  <td>{item?.project_request?.user?.organisation}</td>
                  <td>{item?.project_request?.user?.first_name}</td>
                  <td className="text-nowrap">{item?.reject_date}</td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        role={"button"}
                        onClick={() => getReason(item?.reject_reason)}
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                  <td>{item?.tags}</td>
                  {/* <td>
                    <div className=" fs-14 text-center">
                      <Link
                        className="f3 primary1"
                        to={"/overview"}
                        target="_blank"
                      >
                        {item?.project?.id}
                      </Link>
                      <p
                        className="f1 fs-13 gray"
                        // onClick={() => setShowDetailPopup(item)}
                      >
                        {item?.project?.company}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      className=" fs-14 text-center"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      <p className="f3">{item?.stage?.order}</p>
                      <p className="f1 fs-13 gray">{item?.stage?.status}</p>
                    </div>
                  </td> */}
                  {/* <td>
                    <div
                      className="d-flex ac-js"
                      // onClick={() => change(item?.id)}
                    >
                      {item?.status == 1 ? (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
            <tr className="text-nowrap">
              <th colspan="6" style={{ textAlign: "right" }}>
                Total :
              </th>
              <td>₹ 1,021,618</td>
              <td>₹ 5,994</td>
              <td>₹ 0</td>
              <td>₹ 271,733</td>
            </tr>
          </tfoot> */}
        </table>
      </div>

      {/* {showDetailPopup && (
  <ProjectDetailPopup
    item={showDetailPopup}
    toggleShowPopup={closeDetailPopup}
    onClickAddTask={onClickAddTask}
  />
)} */}

      {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )}

      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagination?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Ecommerce;
