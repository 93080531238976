import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const ReasonPopup = ({ showReasonPopupHander, reason, setReason, type }) => {
  // console.log("positionNumber", positionNumber);

  return (
    <div className="modal-popup">
      <div
        onClick={() => {
          showReasonPopupHander();
          setReason("");
        }}
        className="back-close"
      />
      <div className="center-content-contact3 w-40 p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            {type == "reason" ? "Reject" : "Delete"} Reason
          </p>
          <CloseIcon
            onClick={() => {
              showReasonPopupHander();
              setReason("");
            }}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="mt-4 w-xl-100 w-lg-100 w-md-100 w-sm-100 w-xs-100">
          <p className="gray f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-17 fs-xxl-18">
            Reason
          </p>
          <textarea
            className="w-100 editBtnSelect mt-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
            rows={4}
            style={{ resize: "vertical" }}
            value={reason}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default ReasonPopup;
