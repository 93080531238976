import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import AddClientContactPopup from "./AddClientContactPopup";
import PresentaionList from "./PresentaionList";
import NavbarHead from "../Sub_Layout/NavbarHead";
import PresentaionListTable from "./PresentaionListTable";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PresentationCopyPopup from "./PresentationCopyPopup";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  useAddProTitleMutation,
  useCategoryProductViewNewMutation,
  useLazyDeleteProTitleQuery,
  useEditProTitleMutation,
  useGetStockMutation,
  useLazyArtworkDeleteQuery,
  useLazyCategoriesQuery,
  useLazyMasterNoPageListQuery,
  useLazyNewCategoryViewQuery,
  useLazyPresentationDeleteQuery,
  useLazyProjectViewQuery,
  usePresentationAddMutation,
  usePresentationProAddMutation,
  usePresentationStatusMutation,
  useProjectStatusChangeMutation,
} from "../../redux/api/api";
import moment from "moment/moment";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { saveAlertMessage } from "../../redux/slice/alertMessage";

const Presentation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [btn, setBtn] = useState(false);
  const [infoBtn, setInfoBtn] = useState(true);
  const [addClient, setAddClient] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [copyPresentation, SetCopyPresentation] = useState(false);
  const [otherText, setOtherText] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [projectDate, setProjectDate] = useState({});
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [showAlls, setShowAlls] = useState(1);

  const [showAll, setShowAll] = useState(0);

  const [catogory, setCatogory] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [category_id, setCategory_id] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [productName, setProductName] = useState([]);
  const [prods, setProds] = useState("");
  const [productID, setProductID] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [skuCodeList, setSkuCode] = useState([]);
  const [skuSearch, setSearch] = useState("");
  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState(false);
  const [productData, setProductData] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [catogoryName, setCatogoryName] = useState({});
  const [searchValuePro, setSearchValuePro] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [artList, setArtList] = useState(false);
  const [titleObj, setTitleObj] = useState({});

  // OTHER INFORMATION START
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [attributesList, setAttributesList] = useState([]);
  const [otherProInfo, setOtherProInfo] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [searchProInfo, setSearchProInfo] = useState("");
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  // OTHER INFORMATION END

  // RTK QUERY
  const [masterApi] = useLazyMasterNoPageListQuery();
  const [projectViewApi] = useLazyProjectViewQuery();
  const [presentationAddApi] = usePresentationAddMutation();
  const [presentationStatusApi] = usePresentationStatusMutation();
  const [presentationDeleteApi] = useLazyPresentationDeleteQuery();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [presentationProAddApi] = usePresentationProAddMutation();
  const [projectStatusChangeApi] = useProjectStatusChangeMutation();
  const [artworkDeleteApi] = useLazyArtworkDeleteQuery();
  const [addProTitleApi] = useAddProTitleMutation();
  const [editProTitleApi] = useEditProTitleMutation();
  const [deleteProTitleApi] = useLazyDeleteProTitleQuery();

  const [start_date, setstart_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [start_dateErr, setstart_dateErr] = useState(false);
  const [in_hand_date, setin_hand_date] = useState("");
  const [in_hand_dateErr, setin_hand_dateErr] = useState(false);
  const [introduction, setIntroduction] = useState("");
  const [introductionErr, setIntroductionErr] = useState(false);
  const [terms_and_conditions, setTerms_and_conditions] = useState("");
  const [terms_and_conditionsErr, setTerms_and_conditionsErr] = useState(false);
  const [closing_date, setclosing_date] = useState("");
  const [closing_dateErr, setclosing_dateErr] = useState(false);
  const [presentation_style, setpresentation_style] = useState(0);
  const [presentation_styleErr, setpresentation_styleErr] = useState(false);
  const [pricing_status, setpricing_status] = useState(false);
  const [pricing_statusErr, setpricing_statusErr] = useState(false);
  const [colSpan, setColspan] = useState(1);
  const [displayBtn, setDisplayBtn] = useState(false);
  const [selectAdd, setSelectAdd] = useState("");
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const toggleCopy = () => {
    SetCopyPresentation(!copyPresentation);
  };

  const copyPresent = () => {
    navigate(`/presentation?id=${Math.random().toString().substr(2, 6)}`);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const addOtherInfo = (text) => {
    if (+text !== 0) {
      let temp = [...otherText];
      temp.push(text);
      setOtherText(temp);
    }
  };

  const getMaster = () => {
    masterApi()
      .unwrap()
      .then((res) => {
        setListAttributes(res?.presentation_attributes);
        setAttributesList(res?.presentation_attributes);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // PROJECT VIEW API
  const getProjectView = () => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      dispatch(saveLoader(true));
      projectViewApi(split[1])
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            let data = res?.project;
            let artArr = [];
            if (data?.status >= 3) {
              let findDate = data?.projectDate?.find((i) => i?.type == 5);
              let detail_project = data?.projectDetail;
              setProjectData(res?.project);
              setProjectDate(findDate);

              let arr = [];
              detail_project?.map((item, ind) => {
                arr.push(item?.projectArtwork?.length);
                if (item?.artwork_status == 3) {
                  artArr.push(item);
                }

                if (item?.artwork_status == 2) {
                  artArr.push(item);
                }
              });

              if (detail_project?.length == artArr?.length) {
                setArtList(true);
              } else {
                setArtList(false);
              }

              let maxValue = Math.max(...arr);
              setColspan(maxValue);

              let project_detail = [];

              data?.projectPresentationOrder?.map((item, ind) => {
                let prject_img_url = "";

                if (item?.project_detail_id?.product_sku?.color_id) {
                  let finder =
                    item?.project_detail_id?.product?.colorImages?.find(
                      (i) =>
                        i?.color_id ==
                        item?.project_detail_id?.product_sku?.color_id
                    );
                  prject_img_url = finder?.image_url;
                } else {
                  prject_img_url =
                    item?.project_detail_id?.product?.defaultImage;
                }

                project_detail.push({
                  ...item,
                  prject_img_url: prject_img_url,
                });
              });

              project_detail.sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              });

              let filter = [];

              project_detail?.map((item, ind) => {
                if (item?.project_detail_id?.status == 1) {
                  filter.push(item);
                }

                if (item?.project_detail_id?.status == 2) {
                  filter.push(item);
                }

                if (item?.title && !item?.project_detail_id) {
                  filter.push(item);
                }
              });

              filter.sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              });

              setProjectDetail(filter);
              setShowAlls(1);
              setProjectDetails(project_detail);
              if (data?.in_hand_date) {
                setin_hand_date(data?.in_hand_date);
              }

              if (data?.closing_date) {
                setclosing_date(data?.closing_date);
              }

              if (data?.terms_condition) {
                setTerms_and_conditions(data?.terms_condition);
              }

              if (data?.introduction) {
                setIntroduction(data?.introduction);
              }

              if (data?.presentation_style) {
                setpresentation_style(data?.presentation_style);
              }

              if (data?.pricing_status == 1) {
                setpricing_status(true);
              } else {
                setpricing_status(false);
              }

              let productAttributesName = [];
              let productAttributesID = [];
              data?.PresentationProjectOtherInfo?.map((item, ind) => {
                productAttributesName.push(item?.master?.name);
                setOtherProInfo(item?.id);
                productAttributesID.push({
                  info_id: item?.id,
                  id: item?.master?.id,
                  title: item?.master?.name,
                  value: item?.value,
                });
              });
              setDynamicAttr(productAttributesName);
              setProductInformation(productAttributesID);
              setDisplayBtn(false);
            } else {
              dispatch(saveAlertMessage("Overview Not Completed"));
              setTimeout(() => {
                navigate(`/overview?project=${searchParams?.get("project")}`);
                saveAlertMessage(null);
              }, 2000);
            }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      getProjectView();
    }

    getMaster();
  }, []);

  // PRESENTATION API
  const submitHandle = () => {
    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      start_date?.length == 0 ||
      in_hand_date?.length == 0 ||
      closing_date?.length == 0 ||
      // introduction?.length == 0 ||
      // terms_and_conditions?.length == 0 ||
      presentation_style == 0 ||
      check == true
    ) {
      setProductInformationErr(true);
      setstart_dateErr(true);
      setin_hand_dateErr(true);
      setclosing_dateErr(true);
      // setIntroductionErr(true);
      // setTerms_and_conditionsErr(true);
      setpresentation_styleErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", projectData?.id);
      formdata.append("project_request_id", projectData?.project_request?.id);
      formdata.append("presentation_date", start_date);
      formdata.append("terms_condition", terms_and_conditions);
      formdata.append("introduction", introduction);
      formdata.append("in_hand_date", in_hand_date);
      formdata.append("presentation_style", presentation_style);
      formdata.append("pricing_status", pricing_status ? 1 : 0);
      formdata.append("closing_date", closing_date);

      if (productInformation?.length > 0) {
        productInformation?.map((item, ind) => {
          formdata.append(`informations[${ind}][master_id]`, item?.id);
          formdata.append(`informations[${ind}][value]`, item?.value);
        });
      }

      dispatch(saveLoader(true));
      setBtn(true);
      presentationAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          if (res?.status == "success") {
            toast.success(res?.message);
            getProjectView();
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // PRESENTATION STATUS API
  const presentationStatus = (id, status) => {
    let formdata = new FormData();
    formdata.append("project_detail_id", id);
    formdata.append("status", status);
    dispatch(saveLoader(true));
    presentationStatusApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // PROJECT STATUS API
  const projectStatus = (status, type) => {
    let count = 1;
    // if (type == "estimate" && projectData?.status <= 4) {
    //   count = 2;
    //   dispatch(saveAlertMessage("User Not Approved"));
    // }

    if (type == "estimate" && projectData?.status >= 5) {
      count = count + 1;
      navigate(`/estimate?project=${searchParams?.get("project")}`);
    }

    let formdata = new FormData();
    formdata.append("project_id", projectData?.id);
    formdata.append("status", status);
    if (count == 1) {
      dispatch(saveLoader(true));
      projectStatusChangeApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            if (type == "estimate") {
              navigate(`/estimate?project=${searchParams?.get("project")}`);
              setActionBtn(false);
            } else {
              getProjectView();
            }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }

    setActionBtn(false);
  };

  // PRESENTATION DELETE API
  const presentationDelete = (id) => {
    dispatch(saveLoader(true));
    presentationDeleteApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // ARTWORK DELETE API
  const artworkDelete = (id) => {
    dispatch(saveLoader(true));
    artworkDeleteApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // FOR ADD PRODUCT

  const submitHandleProduct = () => {
    if (productID?.length == 0 || sku?.length == 0 || categoryID?.length == 0) {
      setProductsErr(true);
      setSkuErr(true);
      setCategoryIDErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", projectData?.id);
      formdata.append("product_id", productID);
      formdata.append("product_sku_id", sku);
      formdata.append("no_of_colors", 1);
      formdata.append("qty", 1);
      formdata.append("final_amount", productData?.for_25);

      dispatch(saveLoader(true));
      setBtn(true);
      presentationProAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          closeAddPro();
          getProjectView();
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const closeAddPro = () => {
    setCatogory("");
    setShowPopup(false);
    setDropDown(null);
    setCategoryIDErr(false);
    setSubcatogory(false);
    setSubCategoryList([]);
    setCatogoryShow2(null);
    setProds("");
    setProductID("");
    setProductList([]);
    setProductLists([]);
    setProductsErr(false);
    setSkuList([]);
    setSku("");
    setSkuErr(false);
    setShowPopup(false);
  };

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          setLists(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };
  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCategoryID(item?.id);
    setCatogory(item?.name);
    setSubcatogory(true);
    getCategoryId(item?.id, "category");
    setList(lists);
    setCatogoryName({});
    setDropDown(null);
    setSkuCode(skuCodeList);
    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, indx, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      onSubCatSelect(id, indx, index, data);
    }
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }

    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
  };
  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };
  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, inx) => {
    setSearchSubValueCategory((val) => ({ ...val, [inx]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[inx]", temp[inx], subCategoryLists[inx]);
    temp[inx] = subCategoryLists[inx];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, inx, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));

    setProductName([]);

    if (inx == 0) {
      setSecondaryCategoryId(subItem?.id);
    }
    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, inx, index, type, data) => {
    setLoading(true);
    viewCategoryApi(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);

          let productsArr = [];

          finals?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          // console.log("finals", finals);
          setProductLists(productsArr);
          setProductList(productsArr);

          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);

          let productsArr = [];

          temps?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });

          setProductLists(productsArr);
          setProductList(productsArr);
          setLoading(false);
          getPro(id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  const searchPro = (event) => {
    let temp = [...productLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductList(search);
    } else {
      setProductList(productLists);
    }

    setSearchValuePro(event);
  };

  //PRODUCT
  const getProducts = (primary_category_id, secondary_category_id, id) => {
    let formdata = new FormData();
    formdata.append("primary_category_id", primary_category_id);
    formdata.append("secondary_category_id", secondary_category_id);
    viewCategory({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        setProductLists(res?.products);
        setProductList(res?.products);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getPro = (id) => {
    if (categoryID && secondaryCategoryId) {
      getProducts(categoryID, secondaryCategoryId, id);
    }
  };

  const onProSelect = (event, id, type, item) => {
    setProductID(id);
    setProds(event);
    setProductData(item);
    let name = [];
    item?.productSku?.map((skulist, ind) => {
      if (skulist?.status == 1) {
        name?.push(skulist);
      }
    });
    setSkuList(name);

    setDropDown(null);
  };

  const skuSelect = (event) => {
    setSku(event);
  };

  // OTHER INFORMATION START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  // OTHER INFORMATION END

  const showChangeHandler = (value) => {
    if (value == 0) {
      setProjectDetail(projectDetails);
    } else {
      let filter = projectDetails?.filter((i) => i?.artwork_status == +value);
      setProjectDetail(filter);
    }
    setShowAll(value);
  };

  const selectAddHandle = (event) => {
    if (event == 2) {
      navigate(
        `/product-library-select?project=${searchParams?.get("project")}`,
        {
          state: {
            projectData: projectData,
            back: "presentation",
          },
        }
      );
    }
    setSelectAdd(event);
  };

  const submitTitleHandle = () => {
    if (title?.length == 0) {
      setTitleErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("title", title);

      if (titleObj?.id) {
        formdata.append("project_id", titleObj?.id);
        formdata.append("order", titleObj?.order);
        dispatch(saveLoader(true));
        setBtn(true);
        editProTitleApi({ id: titleObj?.id, payload: formdata })
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            setTitleObj({});
            setTitle("");
            setSelectAdd("");
            setTitleErr(false);
            setPopupShow(false);
            getProjectView();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        formdata.append("project_id", projectData?.id);
        formdata.append("order", projectDetail?.length + 1);
        dispatch(saveLoader(true));
        setBtn(true);
        addProTitleApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            setTitleObj({});
            setTitle("");
            setSelectAdd("");
            setTitleErr(false);
            setPopupShow(false);
            getProjectView();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  const getTitleDelete = (id) => {
    dispatch(saveLoader(true));
    setBtn(true);
    deleteProTitleApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setBtn(false);
        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
  };

  const closeAddPopup = () => {
    setTitle("");
    setTitleErr(false);
    setPopupShow(false);
    setSelectAdd("");
    setTitleObj({});
  };

  const getTitle = (item) => {
    setTitle(item?.title);
    setTitleObj(item);
    setSelectAdd(1);
    setPopupShow(true);
  };

  return (
    <div className="dashRightView2 presentaion_pages  overflow-scroll ">
      {copyPresentation && (
        <PresentationCopyPopup
          toggleCopy={toggleCopy}
          copyPresent={copyPresent}
        />
      )}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4">
          <NavbarHead onLinkShow={onLinkShow} projectData={projectData} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                  toggleCopy();
                }}
              >
                Copy Presentation
              </button>
              {/* <button
                onClick={() => {
                  if (projectData?.presentation_style == 1) {
                    navigate("/presentation-list-a", {
                      state: { data: projectData },
                    });
                  } else if (projectData?.presentation_style == 2) {
                    navigate("/presentation-gallery-page-2a", {
                      state: { data: projectData },
                    });
                  }

                  setActionBtn(false);
                }}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Preview Presentation
              </button> */}
              <Link
                to={
                  projectData?.presentation_style === 1
                    ? `/presentation-list-a?project_id=${projectData?.id}`
                    : projectData?.presentation_style === 2
                    ? `/presentation-gallery-page-2a?project_id=${projectData?.id}`
                    : "#"
                }
                target={"_blank"}
                state={{ data: projectData }}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                onClick={() => setActionBtn(false)}
              >
                Preview Presentation
              </Link>

              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Email Presentation
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-danger bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Delete Presentation
              </button>
              <button
                onClick={() => projectStatus(5, "estimate")}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                {projectData?.status >= 5
                  ? "Preview Estimate"
                  : "Create Estimate"}
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-100 mt-5">
        <div className="w-100 d-flex ac-je">
          <button
            className="d-flex me-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onClick={() => setInfoBtn(!infoBtn)}
          >
            {infoBtn ? "- Collapse Info" : "-Expand Info"}
          </button>
        </div>
        <div className="d-flex flex-column flex-md-row gap-3">
          <div className="w-100">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Status
            </p>

            <select
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              onChange={(e) => showChangeHandler(e.target.value)}
              value={showAll}
            >
              <option value={0}>Over All</option>
              <option value={1}>In Process</option>
              <option value={2}>Presentation Ready</option>
              <option value={3}>Presentation Accepted</option>
              <option value={4}>Presentation Rejected</option>
            </select>
          </div>
          <div className="w-100">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Presentation Date
            </p>

            <input
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type="date"
              onChange={(e) => setstart_date(e.target.value)}
              value={start_date}
              disabled={
                projectData?.status >= 5 && projectDate?.type == 5
                  ? true
                  : false
              }
            />
            {start_date?.length == 0 && start_dateErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Presentation Date</p>
              </div>
            )}
          </div>
          <div className="w-100">
            {infoBtn && (
              <>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  In Hands Date
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="date"
                  onChange={(e) => setin_hand_date(e.target.value)}
                  value={in_hand_date}
                  disabled={
                    projectData?.status >= 5 && projectDate?.type == 5
                      ? true
                      : false
                  }
                />
                {in_hand_date?.length == 0 && in_hand_dateErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select In Hands Date</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {infoBtn && (
          <>
            <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
              {" "}
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Introduction
              </p>
              <textarea
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                placeholder=""
                rows={2}
                onChange={(e) => setIntroduction(e.target.value)}
                value={introduction}
                disabled={
                  projectData?.status >= 5 && projectDate?.type == 5
                    ? true
                    : false
                }
              />
              {introduction?.length == 0 && introductionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Introduction</p>
                </div>
              )}
            </div>
            <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
              {" "}
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Terms and Conditions
              </p>
              <textarea
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                placeholder=""
                rows={2}
                onChange={(e) => setTerms_and_conditions(e.target.value)}
                value={terms_and_conditions}
                disabled={
                  projectData?.status >= 5 && projectDate?.type == 5
                    ? true
                    : false
                }
              />
              {terms_and_conditions?.length == 0 && terms_and_conditionsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Enter Terms and Conditions
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {infoBtn && (
        <>
          <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
              style={{ marginLeft: "0px" }}
            >
              Other Information
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={
                  dynamicAttr?.length > 0
                    ? dynamicAttr
                    : "Select Other Information"
                }
                onClick={(e) => setDropDown(77)}
                readOnly
                disabled={
                  projectData?.status >= 5 && projectDate?.type == 5
                    ? true
                    : false
                }
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(77);
                }}
                disabled={
                  projectData?.status >= 5 && projectDate?.type == 5
                    ? true
                    : false
                }
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {dropDown == 77 && (
              <div
                className="invisible-cont2"
                onClick={() => setDropDown(null)}
              />
            )}
            <div
              className={`${
                dropDown == 77 && "submenu_1 "
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchProductInfoFun(e.target.value)}
                  value={searchProInfo}
                />
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    allSelectProductInfo();
                  }}
                >
                  All
                </button>
              </div>
              {listAttributes?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                        setDropDown(null);
                      }}
                    >
                      {item?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                      }}
                    >
                      {dynamicAttr?.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}

                      {dynamicAttr?.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {otherProInfo?.length == 0 && otherProInfoErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Select Other Product Information
                </p>
              </div>
            )}
          </div>
          <div className="d-flex gap-3 flex-wrap">
            {productInformation?.map((item, ind) => {
              return (
                <Col lg={5} md={5} sm={5} xs={12} key={ind}>
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    {item?.title}
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) =>
                      handleProductInformation(e.target.value, ind)
                    }
                    value={item?.value}
                    disabled={
                      projectData?.status >= 5 && projectDate?.type == 5
                        ? true
                        : false
                    }
                  />

                  {item?.value?.length == 0 && productInformationErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">fill Information</p>
                    </div>
                  )}
                </Col>
              );
            })}
          </div>
        </>
      )}
      {infoBtn && (
        <div className="d-flex gap-3 flex-column flex-xl-row">
          <div className="d-flex w-100 gap-3 flex-column flex-md-row">
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Client Contact
              </p>
              <div className="d-flex">
                {/* <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  <option>Cli</option>
                  <option>Cli-2</option>
                  <option>Cli-3</option>
                </select> */}
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={projectData?.project_request?.first_name}
                />
                {/* <input
                  type="button"
                  value={"+"}
                  placeholder="Required Field"
                  onClick={toggleShowPopup}
                  className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                /> */}
              </div>
              <div className="mt-3">
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {projectData?.project_request?.email}
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  W: {projectData?.project_request?.mobile}
                </p>
              </div>
            </div>
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2 mx-3"
                style={{ marginLeft: "0px" }}
              >
                Presentation Style
              </p>
              <div className="d-flex mt-2 mx-3">
                <div className="justify-content-center d-flex gap-3">
                  <div className="d-flex">
                    <input
                      type={"radio"}
                      className="mx-1"
                      checked={presentation_style == 1 ? true : false}
                      onClick={() => setpresentation_style(1)}
                      disabled={
                        projectData?.status >= 5 && projectDate?.type == 5
                          ? true
                          : false
                      }
                    />
                    <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      List
                    </span>
                  </div>
                  <div className="d-flex">
                    <input
                      type={"radio"}
                      className="mx-1"
                      checked={presentation_style == 2 ? true : false}
                      onClick={() => setpresentation_style(2)}
                      disabled={
                        projectData?.status >= 5 && projectDate?.type == 5
                          ? true
                          : false
                      }
                    />
                    <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                      Gallery
                    </span>
                  </div>
                </div>
                {/* {["Tiles", "List", "Gallery", "Smart Gallery"].map((item) => (
                <div className="d-flex flex-wrap">
                  <input type={"radio"} className="mx-1" />
                  <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                    {item}
                  </span>
                </div>
              ))} */}
              </div>
              {/* <div className="d-flex mt-3 mx-3"> */}
              {/* <input
                  type={"checkbox"}
                  className="mx-1 "
                  checked={pricing_status ? true : false}
                  onClick={() => setpricing_status(!pricing_status)}
                  disabled={
                    projectData?.status >= 5 && projectDate?.type == 5
                      ? true
                      : false
                  }
                />
                <p className="mx-2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  {!pricing_status ? "Hide" : "Show"} Pricing
                </p> */}
              <div className="d-flex mt-3 mx-3">
                <input
                  type={"radio"}
                  className="mx-1"
                  checked={!pricing_status}
                  onChange={() => setpricing_status(false)}
                  disabled={projectData?.status >= 5 && projectDate?.type === 5}
                />
                <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  Hide Pricing
                </span>
              </div>
              <div className="d-flex mt-3 mx-3">
                <input
                  type={"radio"}
                  className="mx-1"
                  checked={pricing_status}
                  onChange={() => setpricing_status(true)}
                  disabled={projectData?.status >= 5 && projectDate?.type === 5}
                />
                <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                  Show Pricing
                </span>
              </div>
            </div>
            {presentation_style == 0 && presentation_styleErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Presentation Style</p>
              </div>
            )}
          </div>
          {/* </div> */}
          <div className="d-flex w-100 gap-3 flex-column flex-md-row">
            <div className="w-100">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Expiry Date
              </p>
              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="date"
                onChange={(e) => setclosing_date(e.target.value)}
                value={closing_date}
                disabled={
                  projectData?.status >= 5 && projectDate?.type == 5
                    ? true
                    : false
                }
              />
              {closing_date?.length == 0 && closing_dateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Expiry Date</p>
                </div>
              )}
            </div>
            <div className="w-100">
              <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                Currency
              </p>
              <select
                disabled
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 opacity-50"
              >
                <option>INR</option>
                <option>Converted</option>
                <option>Closed</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-end">
        <button
          className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
            btn
              ? "opacity-50"
              : projectData?.status >= 5 && projectDate?.type == 5
              ? "opacity-50"
              : ""
          }`}
          onClick={() => {
            submitHandle();
          }}
          disabled={
            btn
              ? true
              : projectData?.status >= 5 && projectDate?.type == 5
              ? true
              : false
          }
        >
          Submit
        </button>
      </div>
      <div className="mt-3">
        <PresentaionList
          projectDetail={projectDetail}
          projectDetails={projectDetails}
          artList={artList}
          setProjectDetail={setProjectDetail}
          presentationStatus={presentationStatus}
          presentationDelete={presentationDelete}
          colSpan={colSpan}
          setColspan={setColspan}
          setShowPopup={setShowPopup}
          getProjectView={getProjectView}
          projectStatus={projectStatus}
          projectData={projectData}
          projectDate={projectDate}
          artworkDelete={artworkDelete}
          setShowAll={setShowAlls}
          showAll={showAlls}
          displayBtn={displayBtn}
          setDisplayBtn={setDisplayBtn}
          setPopupShow={setPopupShow}
          getTitle={getTitle}
          getTitleDelete={getTitleDelete}
        />
      </div>
      {addClient && <AddClientContactPopup toggleShowPopup={toggleShowPopup} />}

      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <div className="p-5">
          <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Add Product
            <span>
              <button onClick={() => closeAddPro()} className="cust-btn">
                <HighlightOffIcon />
              </button>
            </span>
          </p>

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Category
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Catogory"
                type="text"
                value={
                  catogory?.length > 0
                    ? catogory?.toString()
                    : "Select Catogory"
                }
                onClick={() => {
                  setDropDown(1);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={location?.state?.type == "view" ? true : false}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
                // disabled={location?.state?.type == "view" ? true : false}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 1 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCategory(e.target.value)}
                    value={searchValueCategory}
                  />
                </div>
                {list?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onCategorySelct(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {categoryID?.length == 0 && categoryIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Category</p>
                </div>
              )}
            </div>
          </div>
          {subcatogory && (
            <>
              {subCategoryList?.map((item, ind) => {
                return (
                  <div
                    className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center"
                    key={ind}
                  >
                    <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      {item?.categories
                        ? item?.categories?.name
                        : item?.category?.name}
                    </p>
                    <div className="w-md-50 w-100 position-relative">
                      <input
                        placeholder="Required Field"
                        className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={
                          catogoryName?.[
                            item?.categories
                              ? item?.categories?.id
                              : item?.category?.id
                          ]
                            ? catogoryName?.[
                                item?.categories
                                  ? item?.categories?.id
                                  : item?.category?.id
                              ]
                            : ""
                        }
                        // onChange={onCatogory2}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {catogoryShow2 == ind && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setCatogoryShow2(null)}
                        />
                      )}
                      <div
                        className={`${
                          catogoryShow2 == ind && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) =>
                              searchSubCatFun(e.target.value, ind)
                            }
                            value={searchValueSubCategory[ind]}
                          />
                        </div>
                        {item?.child_categories?.map((subItem, indx) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {subItem?.name}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Product Name
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Product Name"
                type="text"
                value={prods ? prods : "Select Product Name"}
                onClick={() => {
                  setDropDown(2);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                    setCatogoryShow2(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchPro(e.target.value)}
                    value={searchValuePro}
                  />
                </div>
                {productList?.map((item, inx) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={inx}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onProSelect(item?.name, item?.id, "", item);
                        }}
                      >
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {productID?.length == 0 && productsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Product</p>
                </div>
              )}
            </div>
          </div>

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              SKU Code:
            </p>
            <div className="w-md-50 w-100">
              <select
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => skuSelect(e.target.value)}
                value={sku}
              >
                <option value={""}>Select SKU</option>
                {skuList?.length > 0 &&
                  skuList?.map((skuCode, ind) => (
                    <option
                      className="px-2 cust-btn text-start py-1 w-100"
                      value={skuCode?.id}
                      key={ind}
                    >
                      {skuCode?.variant_sku}
                      {skuCode?.variant_name ? "-" + skuCode?.variant_name : ""}
                    </option>
                  ))}
              </select>
              {sku?.length == 0 && skuErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select SKU</p>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-end m-2">
            <button
              onClick={submitHandleProduct}
              disabled={btn}
              className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={popupShow}
        onHide={() => setPopupShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <div className="p-5">
          <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Add Product or Name
            <span>
              <button onClick={() => closeAddPopup()} className="cust-btn">
                <HighlightOffIcon />
              </button>
            </span>
          </p>
          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Select
            </p>
            <div className="w-md-50 w-100">
              <select
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => selectAddHandle(e.target.value)}
                value={selectAdd}
              >
                <option value={""}></option>
                <option value={1}>Title</option>
                <option value={2}>Project</option>
              </select>
            </div>
          </div>

          {selectAdd == 1 && (
            <div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Title
                </p>
                <div className="w-md-50 w-100">
                  <input
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                  {title?.length == 0 && titleErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Title</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex ac-jc mt-3">
                <button
                  disabled={btn}
                  className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                  onClick={() => submitTitleHandle()}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Presentation;
