import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import SaleRejPopup from "../POEstimateRequestComp/SaleRejPopup";
import moment from "moment/moment";
import { editIcon, viewIcon } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import Modal from "react-bootstrap/Modal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  useLazyProjectAddressDeleteQuery,
  useLazyProjectAddressViewQuery,
  useProjectAddressAddMutation,
  useProjectAddressEditMutation,
  useProjectAddressListMutation,
} from "../../redux/api/api";
import { countryList } from "../../redux/api/DummyJson";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const SalesAddress = ({
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
  getAddressList,
  getTax,
  getProjectView,
  projectData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropDown, setDropDown] = useState(undefined);
  const [tab, setTab] = useState(1);
  const [type_of_shipping, setType_of_shipping] = useState(1);
  const [show, setShow] = useState(false);
  const [billingArr, setBillingArr] = useState([]);
  const [shippingArr, setShippingArr] = useState([]);

  // RTK QUERY
  const [projectAddressAddApi] = useProjectAddressAddMutation();
  const [projectAddressUpdateApi] = useProjectAddressEditMutation();
  const [projectAddressListApi] = useProjectAddressListMutation();
  const [projectAddressViewApi] = useLazyProjectAddressViewQuery();
  const [projectAddressDeleteApi] = useLazyProjectAddressDeleteQuery();

  // input states

  const project_code = searchParams?.get("project");
  const [how_many_boxesi, setHow_many_boxesi] = useState("");
  const [which_boxi, setWhich_boxi] = useState("FLIP 1");
  const [how_longi, setHow_longi] = useState("");
  const [start_datei, setStart_datei] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [country, setcountry] = useState("");
  const [email_id, setemail_id] = useState("");
  const [land_mark, setland_mark] = useState("");
  const [phone_no, setphone_no] = useState("");
  const [gst, setgst] = useState("");
  const [alternative_phone_no, setalternative_phone_no] = useState("");
  const [type, settype] = useState("");
  const [first_nameErr, setfirst_nameErr] = useState(false);
  const [last_nameErr, setlast_nameErr] = useState(false);
  const [address1Err, setaddress1Err] = useState(false);
  const [address2Err, setaddress2Err] = useState(false);
  const [cityErr, setcityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [pincodeErr, setpincodeErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [email_idErr, setemail_idErr] = useState(false);
  const [land_markErr, setland_markErr] = useState(false);
  const [phone_noErr, setphone_noErr] = useState(false);
  const [gstErr, setgstErr] = useState(false);
  const [alternative_phone_noErr, setalternative_phone_noErr] = useState(false);
  const [listAddress, setListAddress] = useState([]);
  const [btn, setBtn] = useState(false);
  const [pagenation, setpagenation] = useState({});
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState({});

  const [listState, setListState] = useState([]);
  const [rejectpoppup, setRejectPoppup] = useState(false);
  const [rejectup, setRejectPop] = useState(false);
  const [list, setList] = useState([]);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  const rejectFun = () => {
    setRejectPop(!rejectup);
  };

  // ADDRESS LIST
  const getAddress = (event, order, rows, type) => {
    let params = `?page=${page}`;
    let formdata = new FormData();

    if (event) {
      formdata.append("search", event);
    }

    if (order) {
      formdata.append("order", order);
    }

    if (rows) {
      formdata.append("rows", rows);
    }

    if (type) {
      formdata.append("type", type);
      setTab(type);
    } else {
      formdata.append("type", 1);
      setTab(1);
    }

    let split = project_code.split("_");

    formdata.append("project_id", split[1]);
    formdata.append("user_id", projectData?.project_request?.user?.id);

    dispatch(saveLoader(true));
    projectAddressListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          setList(res?.lists);
          setpagenation(res?.pagination_meta);
          if (tab == 2) {
            setBillingArr(res?.project_address_ids);
          }

          if (tab == 1) {
            setShippingArr(res?.project_address_ids);
          }
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAddress();
  }, [page]);

  // STORE ADDRESS
  const submitAddress = () => {
    if (
      address1?.length == 0 ||
      address2?.length == 0 ||
      land_mark?.length == 0 ||
      pincode?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      country?.length == 0 ||
      phone_no?.length == 0 ||
      alternative_phone_no?.length == 0 ||
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      email_id?.length == 0
    ) {
      setaddress1Err(true);
      setaddress2Err(true);
      setland_markErr(true);
      setpincodeErr(true);
      setcityErr(true);
      setstateErr(true);
      setphone_noErr(true);
      setalternative_phone_noErr(true);
      setfirst_nameErr(true);
      setlast_nameErr(true);
      setemail_idErr(true);
      setcountryErr(true);
    } else {
      let formdata = new FormData();
      if (project_code) {
        let split = project_code.split("_");
        formdata.append("project_id", split[1]);
        formdata.append("first_name", first_name);
        formdata.append("last_name", last_name);
        formdata.append("address1", address1);
        formdata.append("address2", address2);
        formdata.append("city", city);
        formdata.append("state", state);
        formdata.append("pincode", pincode);
        formdata.append("country", country);
        formdata.append("email_id", email_id);
        formdata.append("landmark", land_mark);
        formdata.append("phone_no", phone_no);
        formdata.append("alter_phone_no", alternative_phone_no);
        formdata.append("type", tab);

        if (gst) {
          formdata.append("gst_no", gst);
        }

        formdata.append("user_id", projectData?.project_request?.user?.id);

        if (update?.id) {
          dispatch(saveLoader(true));
          setBtn(true);
          projectAddressUpdateApi({ payload: formdata, id: update?.id })
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              closePopup();
              toast.success(res?.message);
              getProjectView();
              getAddress("", "", "", tab);
              getAddressList();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        } else {
          dispatch(saveLoader(true));
          setBtn(true);
          projectAddressAddApi(formdata)
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              closePopup();
              toast.success(res?.message);
              getProjectView();
              getAddress("", "", "", tab);
              getAddressList();
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        }
      }
    }
  };

  // ADDRESS DELETE
  const deleteAddres = (id) => {
    projectAddressDeleteApi(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getAddress("", "", "", tab);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const closePopup = () => {
    setUpdate({});
    setaddress1Err(false);
    setaddress2Err(false);
    setland_markErr(false);
    setpincodeErr(false);
    setcityErr(false);
    setstateErr(false);
    setphone_noErr(false);
    setalternative_phone_noErr(false);
    setfirst_nameErr(false);
    setlast_nameErr(false);
    setemail_idErr(false);
    setcountryErr(false);
    setgstErr(false);
    settype("");
    setfirst_name("");
    setlast_name("");
    setaddress1("");
    setaddress2("");
    setcity("");
    setstate("");
    setpincode("");
    setcountry("");
    setemail_id("");
    setland_mark("");
    setphone_no("");
    setgst("");
    setalternative_phone_no("");
    setShow(false);
  };

  const getData = (item) => {
    if (item?.first_name) {
      setfirst_name(item?.first_name);
    }

    if (item?.last_name) {
      setlast_name(item?.last_name);
    }

    if (item?.email_id) {
      setemail_id(item?.email_id);
    }

    if (item?.address1) {
      setaddress1(item?.address1);
    }

    if (item?.address2) {
      setaddress2(item?.address2);
    }

    if (item?.landmark) {
      setland_mark(item?.landmark);
    }

    if (item?.country) {
      setcountry(item?.country);
      let finder = countryList?.find((i) => i?.name == item?.country);
      setListState(finder?.states);
    }

    if (item?.state) {
      setstate(item?.state);
    }

    if (item?.city) {
      setcity(item?.city);
    }

    if (item?.pincode) {
      setpincode(item?.pincode);
    }

    if (item?.phone_no) {
      setphone_no(item?.phone_no);
    }

    if (item?.alter_phone_no) {
      setalternative_phone_no(item?.alter_phone_no);
    }

    if (item?.gst_no) {
      setgst(item?.gst_no);
    }
    setUpdate(item);
    setShow(true);
  };

  const getState = (value) => {
    setcountry(value);
    let finder = countryList?.find((i) => i?.name == value);
    setListState(finder?.states);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-3 d-flex justify-content-between">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            {tab == 2 ? "Billing" : "Shipping"} Address
          </p>
          <div className="mt-3 mb-3 d-flex justify-content-end mx-3">
            <button
              class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
              onClick={() => closePopup()}
            >
              Cancel
            </button>
            {/* <button
              class={`cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded ${
                btn ? "opacity-50" : ""
              }`}
              onClick={() => submitAddress()}
            >
              Submit
            </button> */}
          </div>
        </div>
        <div className="mt-3 overflow-scroll h_cust_res">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100">
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  First Name :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setfirst_name(e.target.value)}
                    value={first_name}
                    readOnly
                  />
                  {first_name?.length == 0 && first_nameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter First Name</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Last Name :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setlast_name(e.target.value)}
                    value={last_name}
                    readOnly
                  />
                  {last_name?.length == 0 && last_nameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Last Name</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Email :
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setemail_id(e.target.value)}
                    value={email_id}
                    readOnly
                  />
                  {email_id?.length == 0 && email_idErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Email</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Address Line 1:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setaddress1(e.target.value)}
                    value={address1}
                    readOnly
                  />
                  {address1?.length == 0 && address1Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Address Line 1</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Address Line 2:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setaddress2(e.target.value)}
                    value={address2}
                    readOnly
                  />
                  {address2?.length == 0 && address2Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Address Line 2</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Land Mark:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setland_mark(e.target.value)}
                    value={land_mark}
                    readOnly
                  />
                  {land_mark?.length == 0 && land_markErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Land Mark 2</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Pincode:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setpincode(e.target.value)}
                    value={pincode}
                    readOnly
                    type={"number"}
                  />
                  {pincode?.length == 0 && pincodeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Pincode</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  City:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setcity(e.target.value)}
                    value={city}
                    readOnly
                  />
                  {city?.length == 0 && cityErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter City</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Country:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <select
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => getState(e.target.value)}
                    value={country}
                    disabled
                  >
                    <option>Select</option>
                    {countryList?.map((item, ind) => {
                      return (
                        <option value={item?.name} key={ind}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>

                  {country?.length == 0 && countryErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Country</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  State:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <select
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setstate(e.target.value)}
                    value={state}
                    disabled
                  >
                    <option>Select</option>
                    {listState?.map((item, ind) => {
                      return (
                        <option value={item?.name} key={ind}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>

                  {state?.length == 0 && stateErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter State</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Phone Number:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setphone_no(e.target.value)}
                    value={phone_no}
                    readOnly
                    type={"number"}
                  />
                  {phone_no?.length == 0 && phone_noErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Phone Number</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  Alternate Phone Number:
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setalternative_phone_no(e.target.value)}
                    value={alternative_phone_no}
                    readOnly
                    type={"number"}
                  />
                  {alternative_phone_no?.length == 0 &&
                    alternative_phone_noErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Enter Alternate Phone Number
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                  GST(Optional):
                </p>
                <div className="md_cust_w w-xl-50 w-lg-50 w-sm-50 md w-xs-100">
                  <input
                    placeholder="Optional Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => setgst(e.target.value)}
                    value={gst}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-100 mb-3">
        <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
          <div className="d-flex ac-js">
            <button
              onClick={() => getAddress("", "", "", 1)}
              className={`${
                tab == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Shipping
            </button>
            <button
              onClick={() => getAddress("", "", "", 2)}
              className={`${
                tab == 2 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Billing
            </button>
          </div>
        </div>

        <div>
          {/* <div className="d-flex justify-content-end">
            {tab == 2 && list?.length == 1 ? null : (
              <button
                class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
                onClick={() => setShow(true)}
              >
                + Add Address
              </button>
            )}
          </div> */}
          <div
            style={{ width: "100%", overflow: "auto" }}
            className="w-100 pt-3 w-100 mt-5"
          >
            <table className="table-design-new">
              <thead>
                <tr className="text-nowrap text-center">
                  <th>S.No</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email ID</th>
                  <th>Address Line 1</th>
                  <th>Address Line 2</th>
                  <th>Land Mark</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Pincode</th>
                  <th>Phone Number</th>
                  <th>Alternate Phone Number</th>
                  <th>GST</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, ind) => {
                  return (
                    <tr className="text-center" key={ind}>
                      <td>
                        {" "}
                        <div className="d-flex align-items-center gap-2">
                          <div>{ind + 1}</div>
                          <input
                            type="checkbox"
                            checked={
                              tab == 2
                                ? billingArr?.includes(item?.id)
                                : shippingArr?.includes(item?.id)
                            }
                          />
                        </div>
                      </td>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.email_id}</td>
                      <td>{item?.address1}</td>
                      <td>{item?.address2}</td>
                      <td>{item?.landmark}</td>
                      <td>{item?.state}</td>
                      <td>{item?.city}</td>
                      <td>{item?.pincode}</td>
                      <td>{item?.phone_no}</td>
                      <td>{item?.alter_phone_no}</td>
                      <td>{item?.gst_no ? item?.gst_no : "-"}</td>
                      <td>
                        <div className="d-flex ac-jb">
                          <div
                            className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                            role={"button"}
                            onClick={() => getData(item)}
                          >
                            <img src={viewIcon} className="viewDes" />
                          </div>
                          {/* {tab == 1 && (
                            <div
                              className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                              role={"button"}
                              onClick={() => deleteAddres(item?.id)}
                            >
                              <DeleteForeverIcon className="viewDes" />
                            </div>
                          )} */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == 1 && "opacity-50"
              }`}
              onClick={() => setPage(page - 1)}
              disabled={page == 1 ? true : false}
            >
              Previous
            </button>
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == pagenation?.last_page && "opacity-50"
              }`}
              onClick={() => setPage(page + 1)}
              disabled={page == pagenation?.last_page ? true : false}
            >
              Next
            </button>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default SalesAddress;
