import { Search } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";
import ProductLibraryComp from "../components/ProductLibrary/ProductLibraryComp";
import ProductListLibraryComp from "../components/ProductLibrary/ProductListLibraryComp";
import {
  searchIcon,
  uploadIcon,
  animatedImg,
  product,
  toggleOff,
  toggleOn,
} from "../assets/img";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../redux/slice/alertMessage";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneIcon from "@mui/icons-material/Done";
import {
  useDownloadAdminProfileMutation,
  useDownloadProductLibraryMutation,
  useDownloadSampleExcelsMutation,
  useLazyCategoriesQuery,
  useProductListNoPageMutation,
  usePresentationProAddMutation,
  useProdBulkStatusMutation,
  useProductListMutation,
  useActivityAddMutation,
  useProductListVendorPricedMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { Col, Modal, Row } from "react-bootstrap";

const ProductLibrarySelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(Path?.state);
  // console.log(location);
  const [importList, setImportList] = useState(false);
  const [product, setProduct] = useState(true);
  const [decorative, setDecorative] = useState(false);
  const [both, setBoth] = useState(false);
  const [productList, setParoductList] = useState("");

  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [pageRow, setPageRow] = useState(20);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [categoryID, setCategoryID] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [tagID, setTagID] = useState("");
  const [productName, setProductName] = useState("");
  const [netMin, setNetMin] = useState("");
  const [netMax, setNetMax] = useState("");
  const [description, setDescription] = useState("");

  const fileRef = useRef();
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [file, setFile] = useState("");
  const [btn, setBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [productListNoPage, setProductListNoPage] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  // RTK QUERY
  const [productListApi] = useProductListVendorPricedMutation();
  const [productBulkStatusApi] = useProdBulkStatusMutation();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadProductLibraryMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [presentationProAddApi] = usePresentationProAddMutation();
  const [productListNoPageApi] = useProductListNoPageMutation();
  const [postApi] = useActivityAddMutation();
  const type = location?.state?.type;
  const getProductList = () => {
    productListNoPageApi()
      .unwrap()
      .then((res) => {
        let finer = res?.lists?.filter((i) => i?.status == 1);
        setProductListNoPage(finer);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProductList();
  }, []);

  const handleCheckboxClick = (id) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((item) => item !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  const getProducts = (
    event,
    status,
    category,
    row,
    product_name,
    vendor_id,
    tag_id,
    net_min,
    net_max
  ) => {
    let params = `?page=${page}`;
    let formdata = new FormData();

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    // if (status) {
    formdata.append("status", 1);
    // }

    if (category) {
      formdata.append("category_id", category);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (product_name) {
      formdata.append("productName", product_name);
    }

    if (vendor_id) {
      formdata.append("vendor_id", vendor_id);
    }

    if (tag_id) {
      formdata.append("tag_id", tag_id);
    }

    if (net_min) {
      formdata.append("min_price", net_min);
    }

    if (net_max) {
      formdata.append("max_price", net_max);
    }

    setSearchValue(event);
    setStatusValue(status);
    setCategoryID(category);
    setPageRow(row);
    setProductName(product_name);
    setVendorID(vendor_id);
    setTagID(tag_id);
    setNetMin(net_min);
    setNetMax(net_max);

    dispatch(saveLoader(true));
    productListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setList(res?.lists);
        setPagination(res?.pagination_meta);
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [listCat, setListCat] = useState([]);

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setListCat(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const rolefunction = (id) => {
    let roleId = [...permissions];
    let ids = roleId.indexOf(id);

    if (roleId.includes(id)) {
      roleId.splice(ids, 1);
    } else {
      roleId.push(id);
    }

    if (roleId.length == list?.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }

    setPermissions(roleId);
  };

  // console.log("location", location);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermissions(list?.map((li) => li?.id));
    if (isCheckAll) {
      setPermissions([]);
    }
  };
  const userdetails = localStorage.getItem("userDetails");
  let userStr = JSON.parse(userdetails);
  const submitBulkStatus = () => {
    let projectData = location?.state?.projectData;
    let formdata = new FormData();
    let form = new FormData();
    let temp = [];
    let no_of_packs = projectData?.no_of_packs;
    let for_val = 25;
    productListNoPage?.map((item, ind) => {
      if (permissions?.includes(item?.id)) {
        if (no_of_packs >= 1 && no_of_packs <= 25) {
          for_val = 25;
        } else if (no_of_packs >= 26 && no_of_packs <= 50) {
          for_val = 50;
        } else if (no_of_packs >= 51 && no_of_packs <= 100) {
          for_val = 100;
        } else if (no_of_packs >= 101 && no_of_packs <= 150) {
          for_val = 150;
        } else if (no_of_packs >= 151 && no_of_packs <= 250) {
          for_val = 250;
        } else if (no_of_packs >= 251 && no_of_packs <= 500) {
          for_val = 500;
        } else if (no_of_packs >= 501 && no_of_packs <= 1000) {
          for_val = 1000;
        } else {
          for_val = 1000;
        }

        temp.push({
          product_id: item?.id,
          product_sku_id: item?.productSku?.[0]?.id,
          no_of_colors: 1,
          qty: 1,
          final_amount: item?.[`for_${for_val}`],
          margin_percent: item?.scp_percentage,
        });
      }
    });

    let project_code = searchParams?.get("project");
    let split = project_code.split("_");

    formdata.append("project_id", split[1]);

    temp?.map((item, ind) => {
      formdata.append(`products[${ind}][product_id]`, item?.product_id);
      // formdata.append(`products[${ind}][product_sku_id]`, item?.product_sku_id);
      formdata.append(`products[${ind}][no_of_colors]`, item?.no_of_colors);
      formdata.append(`products[${ind}][qty]`, item?.qty);
      formdata.append(`products[${ind}][final_amount]`, item?.final_amount);
      formdata.append(`products[${ind}][margin_percent]`, item?.margin_percent);
      formdata.append(
        `products[${ind}][order]`,
        projectData?.projectPresentationOrder?.length + 1
      );
    });
    form.append("project_id", split[1]);
    form.append("commented_by_id", userStr.id);
    form.append("description", description);

    selectedItems.forEach((id, ind) => {
      form.append(`product_ids[${ind}]`, id);
    });
    const formObject = {};
    form.forEach((value, key) => {
      formObject[key] = value;
    });
    dispatch(saveLoader(true));
    setBtn(true);
    dispatch(saveLoader(false));
    if (type === "edit") {
      postApi(form)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(true));
          setBtn(false);
          navigate(`/overview?project=${searchParams?.get("project")}`, {
            state: { formData: formObject },
          });
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    } else {
      presentationProAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);

          if (location?.state?.back == "presentation") {
            navigate(`/presentation?project=${searchParams?.get("project")}`);
          } else if (location?.state?.back == "estimate") {
            navigate(`/estimate?project=${searchParams?.get("project")}`);
          } else {
            navigate(`/overview?project=${searchParams?.get("project")}`, {
              state: { formData: formObject },
            });
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getProducts(
      searchValue,
      statusValue,
      categoryID,
      20,
      productName,
      vendorID,
      tagID,
      netMin,
      netMax
    );
  }, [page]);

  // useEffect(() => {
  //   product && setParoductList("product");
  //   decorative && setParoductList("decorative");
  //   both && setParoductList("both");
  // }, []);
  // console.log("locationtwo", locationtwo);

  const showPopupHander = () => {
    setImportList(false);
    setImage("");
    setImageErr(false);
  };

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 10);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // UPLOAD EXCEL
  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    } else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.message == "Product added with some failures") {
            let json = JSON.parse(res?.failed_rows);
            console.log("json", json);
            setModalData(json);
            setModal(true);
            setImportList(false);
            setShowPopup(false);
            toast.error(res?.message);
            setBtn(false);
            dispatch(saveLoader(false));
          } else {
            toast.success("Uploaded Successfully");
            setImage("");
            setImageErr(false);
            setImportList(false);
            setShowPopup(false);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          setImageErr(false);
          console.log("err", err);
        });
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr(false);
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Product
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                  cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Product Library
      </p>
      <div className="d-flex ac-je">
        <button
          className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => {
            if (location?.state?.back == "presentation") {
              navigate(`/presentation?project=${searchParams?.get("project")}`);
            } else {
              navigate(`/overview?project=${searchParams?.get("project")}`);
            }
          }}
        >
          Back
        </button>
      </div>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              setBoth(false);
              setParoductList("product");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              product
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                product ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Library List
            </p>
          </button>
        </div>
        <div className="d-flex gap-2">
          {/* <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div> */}
          <div className="d-flex mt-sm-3 border-search mx-1">
            <img src={searchIcon} alt="search" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) =>
                getProducts(
                  e.target.value,
                  statusValue,
                  categoryID,
                  20,
                  productName,
                  vendorID,
                  tagID,
                  netMin,
                  netMax
                )
              }
              value={searchValue}
            />
          </div>

          {/* <button
            onClick={() => navigate("/products/add-new-product")}
            className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Library
          </button> */}
        </div>
      </div>
      <ProductLibraryComp
        method={"product-library"}
        getProducts={getProducts}
        statusValue={statusValue}
        categoryID={categoryID}
        vendorID={vendorID}
        tagID={tagID}
        productName={productName}
        netMin={netMin}
        netMax={netMax}
        pageRow={pageRow}
        searchValue={searchValue}
      />
      <>
        <div className="d-flex ac-jb flex-column flex-md-row w-100 mt-5">
          <div className="d-flex gap-3">
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getProducts(
                  searchValue,
                  statusValue,
                  e.target.value,
                  20,
                  productName,
                  vendorID,
                  tagID,
                  netMin,
                  netMax
                )
              }
              value={categoryID}
            >
              <option value={""}>Select Categories</option>
              {listCat?.map((item, ind) => {
                return (
                  <option key={ind} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            {/* <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getProducts(
                  searchValue,
                  e.target.value,
                  categoryID,
                  20,
                  productName,
                  vendorID,
                  tagID,
                  netMin,
                  netMax
                )
              }
              value={statusValue}
            >
              <option value={""}>Select Status</option>
              <option value={1}>Active</option>
              <option value={0}>Deactive</option>
            </select> */}
          </div>
        </div>
        <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select
              className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
              onChange={(e) =>
                getProducts(
                  searchValue,
                  statusValue,
                  categoryID,
                  e.target.value,
                  productName,
                  vendorID,
                  tagID,
                  netMin,
                  netMax
                )
              }
              value={pageRow}
            >
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
              Total Count : {pagenation?.total}
            </p>
          </div>
        </div>
        <div className="w-95 d-flex flex-md-row flex-column mt-4 gap-2">
          <div
            className="d-flex gap-1 mt-4"
            onClick={() => handleSelectAll()}
            role={"button"}
          >
            <DoneIcon
              className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 mt-3 ${
                isCheckAll ? "checkIcon" : "checkIcon1"
              }`}
            />
            <p
              className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-3 ${
                isCheckAll ? "black" : "primary1"
              }`}
            >
              Select All
            </p>
          </div>
          {permissions?.length > 0 && (
            <div className="d-flex gap-2 mt-3">
              <button
                className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn ? "opacity-50" : ""
                }`}
                onClick={() => submitBulkStatus()}
                disabled={btn}
              >
                Submit
              </button>
              {type === "edit" && (
                <textarea
                  className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  placeholder="Write comment"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              )}
            </div>
          )}
        </div>
        <div className="w-100 d-flex ac-js mt-5 flex-wrap">
          {list?.map((item, ind) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative"
                key={ind}
              >
                <div className="prod-list-box">
                  <div
                  // onClick={() =>
                  //   navigate("/product-library-details", {
                  //     state: { data: item, type: "edit" },
                  //   })
                  // }
                  >
                    <div className="cust-btn d-flex ac-jc rounded-3">
                      <img
                        src={item?.defaultImage ? item?.defaultImage : product}
                        alt=""
                        className="custom-w-h rounded-3"
                      />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      {item?.name}
                    </p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                      {item?.primaryCategory?.name}
                    </p>
                    <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                      {item?.sku_code}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      Visible Users
                    </p>{" "}
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      <img
                        src={item?.status == 1 ? toggleOn : toggleOff}
                        className="toggleOnDes"
                        alt="icon"
                      />
                    </p>
                  </div>
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                    {item?.is_ecommerce == 1 && "E-commerce"}{" "}
                    {item?.is_enterprises == 1 && "Enterprises"}{" "}
                    {item?.is_reseller == 1 && "Reseller"}{" "}
                    {item?.show_to_vendor == 1 && "Vendor"}
                  </p>
                </div>
                <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
                  <DoneIcon
                    onClick={() => {
                      rolefunction(item?.id);
                      handleCheckboxClick(item?.id);
                    }}
                    className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                      permissions?.includes(item?.id)
                        ? "checkIcon"
                        : "checkIcon1"
                    }`}
                  />
                  <p
                    className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                      permissions?.includes(item?.id) ? "black" : "primary1"
                    }`}
                  ></p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == pagenation?.last_page && "opacity-50"
            }`}
            onClick={() => setPage(page + 1)}
            disabled={page == pagenation?.last_page ? true : false}
          >
            Next
          </button>
        </div>
      </>

      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
            Errors
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div>
              {modalData?.map((item, index) => {
                return (
                  <Row key={index} className="d-flex">
                    <Row>
                      <Col className="d-flex justify-content-between">
                        <h5 style={{ textDecoration: "underline" }}>
                          Failure Reason: {item?.failure_reason}
                        </h5>
                        <h6>S.No. {index + 1}</h6>
                      </Col>
                    </Row>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Primary Category:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.primary_category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Secondary Category:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.secondary_category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      category:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Name:
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.name}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      MRP:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.mrp}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Decoration Method:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.decoration_methods}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Country:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.country_origin}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Gender:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.product_for}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Description:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.description}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {" "}
                      HSN Code:
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.hsn_code}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Tags:{" "}
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.tags}
                    </Col>
                    {/* <Col>Event Tags: {item?.attributes}</Col> */}
                    {/* <Col>Icon Tags: {item?.tags}</Col> */}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Packaging Size:{" "}
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.package_size}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {" "}
                      Attributes:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.attributes}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Colors:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.colors}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Size:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.size}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Materials:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.materials}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      styles:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.styles}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Images:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.product_images}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Mockup Images:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.mockup_images}
                    </Col>
                    <Row>
                      <div
                        className="mt-2 mb-2"
                        style={{ borderBottom: "1px solid black" }}
                      ></div>
                    </Row>
                  </Row>
                );
              })}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductLibrarySelect;
